import styled from "styled-components";

export const Container = styled.div`
  padding: 0 2.5rem;
  height: 20rem;
  background: url("${({ bg }) => bg}");
  background-size: cover;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
  max-width: 120rem;
  margin: auto;
`;

export const Heading = styled.div`
  text-transform: capitalize;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.2;
`;

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const previousCurrentCommonStyles = `
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.875;
    text-transform: uppercase;
    color: #fff;
`;

export const BreadCrumbPreviousItem = styled.div`
  ${previousCurrentCommonStyles}
  transition: all 0.3s linear;
  color: rgba(255, 255, 255, 0.6);
  &:hover {
    color: #fff;
  }
`;

export const BreadCrumbCurrentItem = styled.div`
  ${previousCurrentCommonStyles}
  color: #fff;
`;
