import React, { useEffect } from "react";
import ContactDetails from "./ContactDetails/ContactDetails";
import ContactForm from "./ContactForm/ContactForm";
import { Container } from "./styles";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us • Eazytaxes";
  }, []);
  return (
    <Container>
      <ContactDetails />
      <ContactForm />
    </Container>
  );
};

export default ContactUs;
