import styled from "styled-components";

export const Container = styled.div`
  padding: 4rem 0;
  @media screen and (max-width: 1000px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Heading = styled.div`
  margin-bottom: 4rem;
  font-size: 3.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #1b1d21;
  text-align: center;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;

export const Companies = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  overflow: hidden;
  justify-content: center;
  margin-left: 5rem;
`;

export const Company = styled.img`
  width: 15rem;
  @media screen and (max-width: 500px) {
    width: 10rem;
  }
`;

export const SuccessStories = styled.div`
  text-align: center;
  margin: 0 2rem;
  margin-top: 3rem;
  font-size: 2.5rem;
  color: #55bb53;
  font-weight: 600;
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
