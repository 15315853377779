import Joi from "joi";

export default Joi.string()
  .email({
    minDomainSegments: 2,
    tlds: false,
  })
  .lowercase()
  .required()
  .messages({
    "string.empty": `Email is a required field.`,
    "string.email": "Enter a valid email address.",
  });
