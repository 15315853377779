import styled from "styled-components";
import { useState } from "react";

export const HeaderContent = styled.div`
  z-index: 100;
  display: flex;
  font-size: 2rem;
  font-weight: 500;
  font-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 6rem;
  width: 100%;
  font-family: "Poppins", sans-serif;
  & span {
    font-size: 2rem;
    font-weight: 700;
  }
  background-color: #ffff;
  @media screen and (max-width: 1200px) {
    font-size: 1.1rem;
    font-weight: 700;
    & span {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 360px) {
    font-size: 0.8rem;
    font-weight: 700;
    & span {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
`;

const HideSpan = styled.span`
  margin-left: 30px;
  &:hover {
    cursor: pointer;
  }
`;

const StagingBanner = () => {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <HeaderContent>
          <span style={{ color: "red" }}>
            THIS IS A TEST DEPLOYMENT !!! (FOR TEST DATA ONLY) !!!
          </span>
          <HideSpan
            onClick={() => {
              setShow(false);
            }}
          >
            HIDE
          </HideSpan>
        </HeaderContent>
      )}
    </>
  );
};

export default StagingBanner;
