// API URLs
export const API_URLS = {
  USER: {
    LIST: "/api/users/",
    LOGIN: "/api/login",
    UPDATE: (userId) => `/api/users/${userId}/`,
    DELETE: (userId) => `/api/users/${userId}/`,
    GET: (userId) => `/api/users/${userId}`,
  },
  BLOGS: {
    LIST: "/api/blog/",
    CREATE: "/api/blog/create/",
    UPDATE: (blogId) => `/api/blog/${blogId}/`,
    DELETE: (blogId) => `/api/blog/${blogId}/`,
    GET: (blogId) => `/api/blog/${blogId}/get/`,
  },
  CPA: {
    LIST: "/api/cpas/",
    CREATE: (userId) =>`/api/cpa/${userId}/`,
    UPDATE: (cpaId) => `/api/cpa/${cpaId}/`,
    DELETE: (userId) => `/api/cpa/${userId}/delete`,
    GET: (cpaId) => `/api/cpa/${cpaId}/`,
  },
  TAX_RETURNS: {
    LIST_ALL: "/api/tax_returns/all",
    LIST_CPA: "/api/tax_returns/cpa",
    LIST: "/api/tax_returns/",
    CREATE: "/api/tax_returns/create/",
    GET: (taxReturnId) => `/api/tax_returns/${taxReturnId}/`,
    UPDATE: (taxReturnId) => `/api/tax_returns/${taxReturnId}/update`,
    DELETE: (taxReturnId) => `/api/tax_returns/${taxReturnId}/`,
  },
  PAYMENTS: {
    LIST: "/api/payments/",
    CREATE: "/api/payments/create",
    UPDATE: (paymentId) => `/api/payments/${paymentId}/`,
    DELETE: (paymentId) => `/api/payments/${paymentId}/`,
  },
  FORMS: {
    LIST: "/api/forms/",
    CREATE: "/api/forms/create/",
    GET: (formId) => `/api/forms/${formId}/`,
    GET_BY_TAX_RETURN: (taxReturnId) => `/api/forms/tax-return/${taxReturnId}/`,
    UPDATE: (formId) => `/api/forms/${formId}/`,
    DELETE: (formId) => `/api/forms/${formId}/`,
  },
  TASKS: {
    LIST: "/api/tasks/",
    CREATE: "/api/tasks/create/",
    GET: (taskId) => `/api/tasks/${taskId}/`,
    UPDATE: (taskId) => `/api/tasks/${taskId}/`,
    DELETE: (taskId) => `/api/tasks/${taskId}/`,
  },
  ATTACHMENTS: {
    LIST: "/api/attachments/",
    CREATE: "/api/attachments/create/",
    GET_BY_IDS: '/api/attachments/ids/',
    GET: (attachmentId) => `/api/attachments/${attachmentId}/`,
    UPDATE: (attachmentId) => `/api/attachments/${attachmentId}/`,
    DELETE: (attachmentId) => `/api/attachments/${attachmentId}/`,
  },
};

export const FRONTEGG_BASE_URL = "";
