import { StrictMode } from "react";
import { AppProviders } from "Context";
import { Auth0ProviderWithNavigate } from "./Context/Auth0Provider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
if (!container) {
  throw new Error("Failed to find the root element");
}
const root = createRoot(container);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AppProviders>
          <App />
        </AppProviders>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </StrictMode>,
);

reportWebVitals();
