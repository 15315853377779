import React from "react";
import { Container, Content, Desc, Heading, HeadingDesc, Image } from "./styles";
import "@lottiefiles/lottie-player";

const DataSecurity = () => {
  return (
    <Container>
      <Content>
        <HeadingDesc>
          <Heading>You And Your Data Is Safe With Us!</Heading>
          <Desc>
            <p>
              At eazytaxes.com, Your Information is Protected by Multiple Layers of
              Authentication and Advanced Security Measures and data is stored in secured
              servers by AWS in the USA, compliant with the IRS standards.
            </p>
            <p>
              Our web-based and desktop applications utilize a variety of security
              technologies, including firewalls, intrusion detection systems, web
              application firewalls, network isolation, auditing systems, data
              classifications, and data obfuscation. We also use SSL encryption
              facilitated by an Extended Validation SSL Certificate from a trusted
              certificate authority.
            </p>
            <p>
              We perform regular internal audits throughout the year to maintain the
              highest levels of security.
            </p>
          </Desc>
        </HeadingDesc>
        <Image>
          <lottie-player
            autoplay
            loop
            mode="normal"
            src="https://assets2.lottiefiles.com/packages/lf20_jzpjbmvd.json"
            style={{ width: "80%", margin: "auto" }}
          ></lottie-player>
        </Image>
      </Content>
    </Container>
  );
};

export default DataSecurity;
