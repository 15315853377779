import styled from "styled-components";

export const Container = styled.div`
  margin-top: 2rem;
  padding: 0 2.5rem;
`;

export const Heading = styled.div`
  max-width: 60rem;
  margin: auto;
  margin-bottom: 4rem;
  font-size: 3.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #1b1d21;
  text-align: center;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding: 0 3rem;
`;
