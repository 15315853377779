import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const Icon = styled.div`
  width: 6rem;
  height: 6rem;
  background: #55bb531a;
  border-radius: 50%;
  font-size: 2.8rem;
  display: flex;
  justify-content: center;
  color: #55bb53;
  align-items: center;
  transition: box-shadow 0.3s linear;
  ${({ containerHovered }) =>
    containerHovered ? "box-shadow: 6px 6px 13px 0 rgb(0 0 0 / 15%);" : ""};
`;

export const HeadingDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const Heading = styled.div`
  line-height: 1.2;
  color: #1b1d21;
  font-weight: 600;
`;

export const Desc = styled.div`
  color: #5f5f5f;
  line-height: 1.875;
`;
