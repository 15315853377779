import React, { useEffect, useState } from "react";
import { Container, Logo, Desc, Heading } from "./styles";
import {
  MDBBtn,
  MDBContainer,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";

const Pricing = () => {
  useEffect(() => {
    document.title = "Pricing • Eazytaxes";
  }, []);

  const data = [
    {
      title: "Upfront Transparent Pricing",
      icon1: "success",
      icon2: "danger",
      icon3: "success",
    },
    {
      title: "No Hidden Fees",
      icon1: "success",
      icon2: "danger",
      icon3: "success",
    },
    {
      title: "Hastle Free tax Filing",
      icon1: "success",
      icon2: "danger",
      icon3: "success",
    },
    {
      title: "No Manual Effort",
      icon1: "success",
      icon2: "danger",
      icon3: "success",
    },
    {
      title: "Low Risk of IRS audit",
      icon1: "success",
      icon2: "danger",
      icon3: "success",
    },
    {
      title: "Cost Effective",
      icon1: "success",
      icon2: "success",
      icon3: "danger",
    },
  ];

  const success = {
    color: "success",
    size: "3x",
    icon: "check",
  };

  const danger = {
    color: "danger",
    size: "3x",
    icon: "times",
  };

  return (
    <React.Fragment>
      <Heading>
        {" "}
        <span>Our Pricing</span>{" "}
      </Heading>
      <Desc>
        Welcome to our pricing page for online tax preparation services! We offer a range
        of services tailored to meet the unique needs of different types of taxpayers.
        <br />
        <br />
        For salaried professionals, we offer a basic tax preparation package starting at
        just <span>$74.99</span>. This includes the preparation and e-filing of your
        federal and state tax returns, as well as a review of your tax situation to ensure
        you are getting the maximum deductions and credits available. If you are
        self-employed or have a small business, our self-employed package starts at{" "}
        <span>$199</span>. This includes everything in the basic package, plus additional
        support for business-specific tax deductions and credits.
        <br />
        <br />
        For online sellers, we have a special package designed specifically for your
        needs. Starting at <span>$299</span>, this package includes everything in the
        self-employed package, plus extra support for calculating and reporting online
        sales and other e-commerce specific tax considerations. No matter which package
        you choose, you can be assured that our team of experienced tax professionals will
        handle your tax preparation with care and expertise, giving you peace of mind and
        the confidence that your taxes are done right. Thank you for choosing our online
        tax preparation services!
      </Desc>
      <Container>
        <MDBContainer className="py-5 text-center rounded-10">
          <MDBTable responsive className="rounded">
            <MDBTableHead className="border-white display-6">
              <tr>
                <th scope="col"></th>
                <th scope="col" className="rounded">
                  <strong>
                    <Logo
                      alt="eazytaxes.com"
                      src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
                    />
                  </strong>
                </th>
                <th scope="col">
                  <span>DIY Softwares</span>
                </th>
                <th scope="col">
                  <span>CPA Firms</span>
                </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data.map((item, index) => (
                <tr key={index}>
                  <th className="h3" scope="row">
                    {item.title}
                  </th>
                  <td>
                    {item.icon1 === "success" ? (
                      <MDBIcon fas {...success} />
                    ) : (
                      <MDBIcon fas {...danger} />
                    )}
                  </td>
                  <td>
                    {item.icon2 === "success" ? (
                      <MDBIcon fas {...success} />
                    ) : (
                      <MDBIcon fas {...danger} />
                    )}
                  </td>
                  <td>
                    {item.icon3 === "success" ? (
                      <MDBIcon fas {...success} />
                    ) : (
                      <MDBIcon fas {...danger} />
                    )}
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </MDBContainer>
      </Container>
    </React.Fragment>
  );
};

export default Pricing;
