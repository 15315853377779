import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border: 0.1rem solid #afc1ce;
  border-radius: 1rem;
  cursor: pointer;
  transition: border 0.3s;
  ${({ isSelected }) => (isSelected ? "border-color: #55bb53" : "")}
`;

export const IconCheck = styled.div`
  display: flex;
  align-items: center;
  color: #55bb53;
  font-size: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
`;

export const Icon = styled.img`
  width: 3rem;
`;
