import React, { useEffect, useState } from "react";
import ImageContent from "Components/AboutUsServices/ImageContent";
import { Container, Heading, HeadHeading, HeadContainer } from "./styles";
import { Desc, Buttons } from "./styles";
import Reviews from "Pages/Home/Reviews/Reviews";
import { Button } from "Components";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us • Eazytaxes";
  }, []);

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const onSchedule = () => {
    window.location.href =
      "https://ops-nyassociates.zohobookings.in/#/customer/eazytaxescom";
  };

  return (
    <Container>
      <HeadContainer>
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://assets7.lottiefiles.com/packages/lf20_wthp1bbd.json"
        ></lottie-player>
        <HeadHeading>
          File your taxes <span>without any hassles</span> from the comfort of your own
          home
        </HeadHeading>
      </HeadContainer>{" "}
      <Desc>
        Filing your taxes can be a daunting and time-consuming task, especially if you
        have a complex tax situation or are running a small business. That's where
        eazytaxes.com comes in. Our innovative SaaS (Software as a Service) platform is
        designed to make tax filing quick, easy, and accurate, regardless of your
        situation.
        <br /> <br />
        At eazytaxes.com, we understand that your time is valuable. That's why we offer a
        streamlined process that allows you to file your taxes from the comfort of your
        own home. Simply create an account, upload your documents, and let our team of
        experienced professionals handle the rest. With our user-friendly interface and
        step-by-step guidance, you can file your taxes with confidence, knowing that we
        have you covered.
        <br /> <br />
        But accuracy is just as important as convenience, which is why we offer a 100%
        accuracy guarantee. Our team of professionals reviews every return before it is
        submitted to ensure that it is done correctly and accurately. We are also equipped
        to handle a wide range of tax situations, from simple to complex, so you can trust
        that we have the expertise to help you get the maximum return.
        <br /> <br />
        But we don't just stop at tax filing. At eazytaxes.com, we are committed to
        providing comprehensive support to help you through every step of the process.
        Whether you have questions about your return, need help understanding your tax
        situation, or just want some advice, our team is here to help.
        <br /> <br />
        Data security is also a top priority at eazytaxes.com. We use advanced security
        measures, including SSL encryption and multiple layers of authentication, to
        protect your personal and financial information. You can have peace of mind
        knowing that your information is in good hands with us.
        <br /> <br />
        So why waste your time and energy on tax filing when you can use eazytaxes.com and
        get it done quickly and accurately? Sign up today and experience the power of easy
        and reliable tax filing.
      </Desc>
      <Buttons>
        <Button
          to="/get-my-taxes-filed"
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
        >
          Begin my tax filing process
        </Button>
        <Button
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
          bg="transparent"
          color="#55bb53"
          hoverBg="#55bb53"
          hoverColor="#fff"
          onClick={() => onSchedule()}
        >
          get free consultation
        </Button>
      </Buttons>
      {/* <Heading>
        Our <span>Story</span>
      </Heading> */}
      <ImageContent
        imgAtRight
        img="https://assets7.lottiefiles.com/packages/lf20_v1yudlrx.json"
        desc={[
          "eazytaxes.com is an online tax filing platform for salaried individuals and small businesses in the USA. We help taxpayers save time and money by providing a simple and easy to use platform for filing their taxes.",
          "eazytaxes.com was founded in 2020 by a team of tax experts who saw the need for a better way to file taxes online. We are headquartered in Seattle, but our users are spread across the country.",
          "We're on a mission to make tax filing easy and painless for everyone, and we're proud to say that we're one of the fastest growing platforms in the space.",
        ]}
        heading="Our Story"
        style={{ marginTop: "5rem" }}
        lottiePlayerStyles={{
          width: "120%",
        }}
        imageStyles={{ width: "60%" }}
        paraStyles={{ fontSize: "2rem" }}
      />
      <Reviews />
    </Container>
  );
};

export default AboutUs;
