export const questions = {
  companyRegistration: {
    form: [
      {
        type: "radio",
        label: "Select the state for which you will register the company.",
        name: "stateCompany",
        options: [
          { label: "Delaware", value: "delaware" },
          { label: "Wyoming", value: "wyoming" },
        ],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "radio",
        label: "Choose an entity type of your company.",
        name: "entityType",
        options: [
          { label: "LLC", value: "llc" },
          { label: "Corporation", value: "corporation" },
        ],
        rules: [{ required: true, message: "Please select your resident state" }],
      },
      {
        type: "tableInput",
        label: "Enter Company Name",
        name: "companyName",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "select",
            label: "Company Suffix",
            name: "suffix",
            options: [
              { label: "Company", value: "company" },
              { label: "Corporation", value: "corporation" },
              { label: "Co.", value: "co." },
              { label: "Corp", value: "corp" },
              { label: "Incorporated", value: "incorporated" },
              { label: "Inc.", value: "inc." },
              { label: "Limited", value: "limited" },
              { label: "Ltd", value: "ltd" },
              { label: "Limited Liability Company", value: "limitedLiabilityCompany" },
              { label: "LLC", value: "llc" },
              { label: "L.L.C.", value: "l.l.c." },
            ],
            rules: [{ required: true, message: "Please enter your first name" }],
          },
          {
            type: "input",
            label: "Company Name, First Preference",
            name: "companyNameOne",
            rules: [{ required: true, message: "Please enter your first preference" }],
          },
          {
            type: "input",
            label: "Company Name, Second Preference",
            name: "companyNameTwo",
            rules: [{ required: true, message: "Please enter your second preference" }],
          },
          {
            type: "input",
            label: "Company Name, Third Preference",
            name: "companyNameThree",
            rules: [{ required: true, message: "Please enter your third preference" }],
          },
        ],
        rules: [{ message: "Please enter the company names" }],
      },
      {
        type: "tableInput",
        label: "Enter Registered Agent Name",
        name: "registeredAgentName",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "input",
            label: "First Name",
            name: "firstName",
            rules: [{ required: true, message: "Please enter your first name" }],
          },
          {
            type: "input",
            label: "Last Name",
            name: "lastName",
            rules: [{ required: true, message: "Please enter your second name" }],
          },
        ],
        autoFill: {
          info: "Protect your privacy, hire us to file with you.",
          values: {
            "registeredAgentName-answer-firstName": "John",
            "registeredAgentName-answer-lastName": "Malcolm",
          },
        },
        rules: [{ message: "Please enter the registered name" }],
      },
      {
        type: "tableInput",
        label: "Enter Registered Agent Mailing Address",
        name: "registeredAgentMailingAddress",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "input",
            label: "Line 1",
            name: "lineOne",
            rules: [{ required: true, message: "Please enter the first line." }],
          },
          {
            type: "input",
            label: "Line 2",
            name: "lineTwo",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "City",
            name: "city",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "Zip / Postal Code",
            name: "zipPostalCode",
            rules: [{ required: true, message: "Please enter the zip or postal code." }],
          },
        ],
        autoFill: {
          info: "Protect your privacy, hire us to file with you.",
          values: {
            "registeredAgentMailingAddress-answer-lineOne": "19, K G Marg",
            "registeredAgentMailingAddress-answer-lineTwo": "Connaught Place",
            "registeredAgentMailingAddress-answer-city": "Delhi",
            "registeredAgentMailingAddress-answer-zipPostalCode": "110001",
          },
        },
        rules: [{ message: "Please enter the registered agent's address" }],
      },
      {
        type: "attachment",
        label: "Please upload your passport",
        name: "passportAttachment",
        rules: [
          { required: true, message: "Please upload your passport", warningOnly: true },
        ],
      },
      {
        type: "tableInput",
        label: "Enter Company Mailing Address",
        name: "companyMailingAddress",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "input",
            label: "Line 1",
            name: "lineOne",
            rules: [{ required: true, message: "Please enter the first line." }],
          },
          {
            type: "input",
            label: "Line 2",
            name: "lineTwo",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "City",
            name: "city",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "Zip / Postal Code",
            name: "zipPostalCode",
            rules: [{ required: true, message: "Please enter the zip or postal code." }],
          },
        ],
        autoFill: {
          info: "Protect your privacy, hire us to file with you.",
          values: {
            "companyMailingAddress-answer-lineOne": "19, K G Marg",
            "companyMailingAddress-answer-lineTwo": "Connaught Place",
            "companyMailingAddress-answer-city": "Delhi",
            "companyMailingAddress-answer-zipPostalCode": "110001",
          },
        },
        rules: [{ message: "Please enter the company mailing address" }],
      },
      {
        type: "tableInput",
        label: "Enter Company Principal Office Address",
        name: "companyPrincipalOfficeAddress",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "input",
            label: "Line 1",
            name: "lineOne",
            rules: [{ required: true, message: "Please enter the first line." }],
          },
          {
            type: "input",
            label: "Line 2",
            name: "lineTwo",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "City",
            name: "city",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "Zip / Postal Code",
            name: "zipPostalCode",
            rules: [{ required: true, message: "Please enter the zip or postal code." }],
          },
        ],
        autoFill: {
          info: "Protect your privacy, hire us to file with you.",
          values: {
            "companyPrincipalOfficeAddress-answer-lineOne": "19, K G Marg",
            "companyPrincipalOfficeAddress-answer-lineTwo": "Connaught Place",
            "companyPrincipalOfficeAddress-answer-city": "Delhi",
            "companyPrincipalOfficeAddress-answer-zipPostalCode": "110001",
          },
        },
        copyFields: {
          "companyMailingAddress-answer-lineOne": "companyPrincipalOfficeAddress-answer-lineOne",
          "companyMailingAddress-answer-lineTwo": "companyPrincipalOfficeAddress-answer-lineTwo",
          "companyMailingAddress-answer-city": "companyPrincipalOfficeAddress-answer-city",
          "companyMailingAddress-answer-zipPostalCode": "companyPrincipalOfficeAddress-answer-zipPostalCode",
        },
        rules: [{ message: "Please enter the company principal office address" }],
      },
      {
        type: "input",
        inputType: "text",
        label: "Company purpose",
        name: "companyPurpose",
        rules: [{ message: "Please enter the companies' purpose", required: true }],
      },
      {
        type: "input",
        inputType: "number",
        label: "Total Amount of Authorised Shares",
        name: "totalShares",
        rules: [{ message: "Please enter the total amount of shares", required: true }],
      },
      {
        type: "input",
        inputType: "number",
        label: "Value per share",
        name: "valueOfShare",
        rules: [{ message: "Please enter the value per share", required: true }],
      },
      {
        type: "radio",
        label: "Select the share class",
        name: "shareClass",
        options: [
          { label: "Preferred", value: "preferred" },
          { label: "Common", value: "common" },
        ],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "tableInput",
        label: "Incorporator Details",
        name: "incorporatorDetails",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "input",
            label: "First Name",
            name: "firstName",
            rules: [{ required: true, message: "Please enter your first name" }],
          },
          {
            type: "input",
            label: "Last Name",
            name: "lastName",
            rules: [{ required: true, message: "Please enter your second name" }],
          },
          {
            type: "input",
            label: "Line 1",
            name: "lineOne",
            rules: [{ required: true, message: "Please enter the first line." }],
          },
          {
            type: "input",
            label: "Line 2",
            name: "lineTwo",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "City",
            name: "city",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "Zip / Postal Code",
            name: "zipPostalCode",
            rules: [{ required: true, message: "Please enter the zip or postal code." }],
          },
        ],
        rules: [{ message: "Please enter the incorporator details." }],
      },
      {
        type: "radio",
        label: "Select the management type.",
        name: "managementType",
        options: [
          { label: "Single Member LLC", value: "single-member-llc" },
          { label: "Member Managed", value: "member-managed" },
          { label: "Manager Managed", value: "manager-managed" },
        ],
        rules: [{ required: true, message: "Please select an option" }],
        info: (
          <div>
            <p>
              Single member LLC: A single-member LLC has one member, who is also
              considered the manager.
            </p>
            <p>
              Member-Managed LLCs: These are LLCs where the owners are actively involved
              in managing the daily activities of the business.
            </p>
            <p>
              Manager-Managed LLCs: In this setup, the owners appoint a specific
              individual, who can be a member or an external person, to handle the
              business operations.
            </p>
          </div>
        ),
      },
      {
        type: "tableInput",
        label: "Enter the Member or Manager Name",
        name: "memberManagerName",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "input",
            label: "First Name",
            name: "firstName",
            rules: [{ required: true, message: "Please enter your first name" }],
          },
          {
            type: "input",
            label: "Last Name",
            name: "lastName",
            rules: [{ required: true, message: "Please enter your second name" }],
          },
          {
            type: "input",
            label: "Line 1",
            name: "lineOne",
            rules: [{ required: true, message: "Please enter the first line." }],
          },
          {
            type: "input",
            label: "Line 2",
            name: "lineTwo",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "City",
            name: "city",
            rules: [{ required: true, message: "Please enter the second line." }],
          },
          {
            type: "input",
            label: "Zip / Postal Code",
            name: "zipPostalCode",
            rules: [{ required: true, message: "Please enter the zip or postal code." }],
          },
        ],
        autoFill: {
          info: "Protect your privacy, hire us to file with you.",
          values: {
            "memberManagerName-answer-firstName": "19, K G Marg",
            "memberManagerName-answer-lastName": "Connaught Place",
            "memberManagerName-answer-lineOne": "19, K G Marg",
            "memberManagerName-answer-lineTwo": "Connaught Place",
            "memberManagerName-answer-city": "Delhi",
            "memberManagerName-answer-zipPostalCode": "110001",
          },
        },
        rules: [{ message: "Please enter the member or manager name" }],
      },
      {
        type: "radio",
        label: "Is the company foriegn owned ?",
        name: "foriegnOwned",
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "tableInput",
        label: "Enter the Board Members",
        name: "boardMembers",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "input",
            label: "President Name",
            name: "presidentName",
            rules: [{ required: true, message: "Please enter your president name" }],
          },
          {
            type: "input",
            label: "Secretary Name",
            name: "secretaryName",
            rules: [{ required: true, message: "Please enter your secretary name" }],
          },
          {
            type: "input",
            label: "Tresurer Name",
            name: "tresurerName",
            rules: [{ required: true, message: "Please enter your tresurer name." }],
          },
          {
            type: "input",
            label: "Director Name",
            name: "directorName",
            rules: [{ required: true, message: "Please enter your director name." }],
          },
        ],
        rules: [{ message: "Please enter the board member's names" }],
      },
    ],
  },
};
