import styled from "styled-components";

export const Container = styled.div`
  max-width: 120rem;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: 4rem;
  padding: 0 2.5rem;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    margin-top: 2rem;
  }
`;

const imageContentStyles = `
    padding: 1.5rem;
    width: 50%;
    @media screen and (max-width: 900px) {
        width: 70%;
    }
    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const Image = styled.div`
  ${imageContentStyles}
  @media screen and (max-width: 1000px) {
    order: 0 !important;
  }
`;

export const Content = styled.div`
  ${imageContentStyles}
`;

export const Title = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: #95a2ac;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 26px;
`;

export const TitleLine = styled.div`
  width: 4.5rem;
  height: 0.1rem;
  background: #95a2ac;
`;

export const Heading = styled.div`
  margin-bottom: 2.4rem;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
  color: #1b1d21;
  text-align: center;
  @media screen and (max-width: 1000px) {
    font-size: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  @media screen and (max-width: 700px) {
    font-size: 2.5rem;
    line-height: 1.4;
  }
`;

export const Desc = styled.div`
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.875;
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    line-height: 1.6;
  }
`;

export const Link = styled.div`
  margin-top: 4rem;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #1b1d21;
`;
