// import {
//     CarouselContext,
//     CarouselProvider,
//     Slide,
//     Slider,
// } from "pure-react-carousel";
// import "pure-react-carousel/dist/react-carousel.es.css";
import React, { useLayoutEffect, useState } from "react"; //  useContext, useEffect, useState
import Marquee from "react-fast-marquee";
import Review from "../Review/Review";
import {
  Container,
  Content,
  Heading,
  // Dot, Dots
} from "./styles";

// const ReviewsDots = () => {
//     const carouselContext = useContext(CarouselContext);
//     const [currentSlide, setCurrentSlide] = useState(
//         carouselContext.state.currentSlide / carouselContext.state.step
//     );
//     useEffect(() => {
//         function onChange() {
//             setCurrentSlide(
//                 carouselContext.state.currentSlide / carouselContext.state.step
//             );
//         }
//         carouselContext.subscribe(onChange);
//     }, [carouselContext]);
//     return (
//         <Dots>
//             {[...Array(carouselContext.state.step)].map((slide, index) => (
//                 <Dot key={index} isActive={currentSlide === index} />
//             ))}
//         </Dots>
//     );
// };

const reviews = [
  {
    name: "Prithvi Poddar",
    profilePic: "prithvi-poddar",
    content:
      "I have used eazytaxes to file my taxes and found it to be cheaper and easier than any in-person service we had ever used. I was so happy with how easy the website was to use, how well the customer service responded to my questions, and also how affordable it was! All my co-workers are using this program now too.",
  },
  {
    name: "Deval Vora",
    profilePic: "deval-vora",
    content:
      "Eazytaxes.com really simplifies tax filing to a great extent and minimize the effort from my side. The team was super helpful; all I had to do is send the required documents from my end and rest all of things including e-filing was taken care by them and I could get the maximum tax return without having to pay single attention for tax-filing deadlines or any other documentation.",
  },
  {
    name: "Jenniffer Baker",
    profilePic: "jenniffer-baker",
    content:
      "This site was great, I am not the best with computers and the idea of doing my own taxes was daunting but these guys made it so easy. All I had to do was upload the documents they asked me to and then just sign a few things, I didn't even have to meet with anyone in person. They were very polite and helpful during the whole process.",
  },
  {
    name: "Victoria Picott",
    profilePic: "victoria-picott",
    content:
      "I've been filing with eazytaxes.com for the past four years, and I couldn't be happier with the service they provide. I always get my maximum return, and they're so quick to let me know how business is going. This year was a tough year, but they were able to help me work through it so that I could ultimately come out on top. You'll never go wrong with these guys!",
  },
  {
    name: "Shubham Pampattiwar",
    profilePic: "shubham-pampattiwar",
    content:
      "I always dreaded April deadline for taxes but this year I was able to handle it with the help of a tax firm. Their professional specialists helped me with everything from following up on my employer and insurance companies, getting all my financial files in order, dealing with my deductions, and even nice preparation. It was so easy and so seamless this year. I feel confident that I will be able to use their service next year as well.",
  },
  //   {
  //     name: "Purvak Lapsiya",
  //     profilePic: "vita-jaskolski",
  //     content:
  //       "Considering I had changed my job during the year, I was really worried about filing my taxes this year, but I found eazytaxes.com and I was really happy with the results. I was just asked to submit some basic documents and they did the hassle to find every deduction for me and maximise the refund. They were able to make things easy for me so that I understood what was going on and they kept me in the loop every step of the way.",
  //   },
  {
    name: "Abeer Dubey",
    profilePic: "kylee-goldner",
    content:
      "eazytaxes.com is the best company I've ever dealt with because of their simplicity, prices and genuine customer service. If you're looking for a place to prepare your taxes, use eazytaxes.com.",
  },
  {
    name: "Nirmal Kumar S",
    profilePic: "nirmal-kumar-s",
    content:
      "As a small business owner I have had to spend many hours on complicated and complex tax software in order to file my taxes. eazytaxes was so much easier, faster and better for my small business. They provide top of the line customer service which is hard to find these days.",
  },
];
const reviewsLength = reviews.length;
const firstRowIndexes = {
  start: 0,
  end: reviewsLength % 2 === 0 ? reviewsLength / 2 : reviewsLength / 2 + 1,
};
const secondRowIndexes = {
  start: reviewsLength % 2 === 0 ? reviewsLength / 2 : reviewsLength / 2 + 1,
  end: reviewsLength,
};

const Reviews = () => {
  const [showTwoRows, setShowTwoRows] = useState();
  useLayoutEffect(() => {
    const resizeHandler = () => {
      const { innerWidth: windowWidth } = window;
      if (windowWidth > 700) setShowTwoRows(false);
      else setShowTwoRows(false);
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);
  return (
    <Container>
      <Heading>
        What our <span>customers</span> are saying
      </Heading>
      <Marquee pauseOnHover={true} pauseOnClick={true} speed={40} gradient={false}>
        <Content>
          {(showTwoRows
            ? reviews.slice(firstRowIndexes.start, firstRowIndexes.end)
            : reviews
          ).map((review, index) => (
            <Review key={index} review={review} />
          ))}
        </Content>
      </Marquee>
      {showTwoRows && (
        <Marquee speed={40} gradient={false} direction="right">
          <Content>
            {reviews
              .slice(secondRowIndexes.start, secondRowIndexes.end)
              .map((review, index) => (
                <Review key={index} review={review} />
              ))}
          </Content>
        </Marquee>
      )}
      {/* <ReviewsDots /> */}
      {/* </CarouselProvider> */}
    </Container>
  );
};

export default Reviews;
