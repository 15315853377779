import styled from "styled-components";

export const Container = styled.div`
  padding: 0 2.5rem;
`;

export const Content = styled.div`
  max-width: 120rem;
  margin: auto;
  display: flex;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  width: 50%;
  padding: 1.5rem;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const TagLineHeading = styled.div`
  margin-bottom: 2.2rem;
`;

export const TagLine = styled.div`
  font-size: 1.4rem;
  color: #55bb53;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const Heading = styled.div`
  font-weight: 700;
  line-height: 1.2;
  color: #1b1d21;
  font-size: 3.6rem;
  text-transform: capitalize;
`;

export const Desc = styled.div`
  margin-bottom: 3rem;
  line-height: 1.875;
  color: #5f5f5f;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const DetailIcon = styled.div`
  width: 6rem;
  height: 6rem;
  background: #55bb531a;
  border-radius: 50%;
  font-size: 2.8rem;
  display: flex;
  justify-content: center;
  color: #55bb53;
  align-items: center;
`;

export const Right = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 700px) {
    & lottie-player {
      width: 100% !important;
    }
  }
`;
