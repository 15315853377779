import { useTaxReturnsQuery, useCPATaxReturnsQuery } from "Hooks/apiHooks";
import { Container, TopRow, TaxReturnContent } from "./styles";
import { useEffect } from "react";
import { Button } from "Components";
import Loader from "Components/uiComponents/LoadingSpinner";
import TaxReturnCard from "./Components/TaxReturnCard";
import ROUTES from "constants/routes";
import { Heading } from "./styles";
import { useNavigate } from "react-router-dom";

const TaxReturnList = ({ isCPA }) => {
  const { data, isLoading, error } = Boolean(isCPA)
    ? useCPATaxReturnsQuery()
    : useTaxReturnsQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      throw new Error(error.message);
    }
  }, [error]);

  return (
    <Container>
      <TopRow>
        <Heading>Tax Return List</Heading>
        <Button
          onClick={() => {
            navigate(ROUTES.DASHBOARD.TAX_RETURN.CREATE);
          }}
        >
          Create Tax Return
        </Button>
      </TopRow>
      {!isLoading ? (
        <TaxReturnContent>
          {data
            ?.sort((a, b) => {
              return Number(a.year.split("-")[0]) - Number(b.year.split("-")[0]);
            })
            .map((taxReturn, index) => {
              return (
                <TaxReturnCard
                  key={index}
                  id={taxReturn.tax_return_id}
                  type={taxReturn.type}
                  year={taxReturn.year}
                  status={taxReturn.status}
                />
              );
            }) ?? []}
        </TaxReturnContent>
      ) : (
        <Loader message={"Loading Tax Returns"} />
      )}
    </Container>
  );
};

export default TaxReturnList;
