import styled from "styled-components";
import DynamicFormItem from "./DynamicFormItem";
import { Table, Switch, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

const DisableMask = styled.div`
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  background-color: grey;
`;

const TableInput = ({
  name,
  rows,
  autoFill,
  columns,
  conditionalColumns,
  multiplierColumns,
  form,
  isDisabled,
  copyFields,
}) => {
  return (
    <DisableMask isDisabled={isDisabled}>
      <Table
        dataSource={rows}
        pagination={false}
        style={{ border: "none" }}
        className="table-no-padding"
        bordered={false}
      >
        <Table.Column
          title="Question"
          dataIndex="label"
          key="label"
          style={{ paddingLeft: "2px" }}
        />
        {columns.map((column, index) => (
          <Table.Column
            title={column.title}
            dataIndex="name"
            key={index}
            style={{ border: "none" }}
            render={(_text, record) => (
              <DynamicFormItem
                type={record.type}
                name={`${name}-${column.name}-${record.name}`}
                label={record.label}
                rules={record.rules}
                options={record.options}
                inputType={record.inputType}
                isDisabled={isDisabled}
              />
            )}
          />
        ))}
        {conditionalColumns
          ?.filter((column) => {
            return (
              form.getFieldValue(column.dependency) &&
              column.values.includes(form.getFieldValue(column.dependency))
            );
          })
          .map((column, index) => (
            <Table.Column
              title={column.title}
              dataIndex="name"
              key={index}
              style={{ border: "none" }}
              render={(text, record) => (
                <DynamicFormItem
                  type={record.type}
                  name={`${name}-${column.name}-${record.name}`}
                  label={record.label}
                  rules={record.rules}
                  options={record.options}
                  inputType={record.inputType}
                  isDisabled={isDisabled}
                />
              )}
            />
          )) ?? []}
        {multiplierColumns?.map((column, index) => {
          return Array.from(
            { length: form.getFieldValue(column.dependency) },
            (_, index) => (
              <Table.Column
                title={`${column.title} ${index + 1}`}
                dataIndex="name"
                key={index}
                style={{
                  border: "none",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                render={(text, record) => (
                  <DynamicFormItem
                    type={record.type}
                    name={`${name}-${column.name}-${index + 1}-${record.name}`}
                    label={record.label}
                    rules={record.rules}
                    options={record.options}
                    inputType={record.inputType}
                    style={{
                      border: "none",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                    isDisabled={isDisabled}
                  />
                )}
              />
            )
          );
        }) ?? []}
      </Table>
      {autoFill && (
        <div style={{ backgroundColor: "white", paddingTop: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
            <Switch
              onChange={(event) => {
                if (event === true) {
                  Object.entries(autoFill.values).forEach(([key, value]) =>
                    form.setFieldValue(key, value)
                  );
                } else {
                  Object.keys(autoFill.values).forEach((key) =>
                    form.setFieldValue(key, undefined)
                  );
                }
              }}
            />
            <Popover placement="bottom" title={"INFO"} content={autoFill.info}>
              <span style={{ fontSize: "15px" }}>
                <InfoCircleOutlined />
              </span>
            </Popover>
          </div>
        </div>
      )}
      {copyFields && (
        <div style={{ backgroundColor: "white", paddingTop: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", width: "30%" }}>
            <div style={{ whiteSpace: "nowrap", marginRight: "10px" }}>
              Copy From Previous
            </div>
            <Switch
              onChange={(event) => {
                if (event === true) {
                  Object.entries(copyFields).forEach(([copyFrom, copyTo]) => {
                    form.setFieldValue(copyTo, form.getFieldValue(copyFrom));
                  });
                } else {
                  Object.entries(copyFields).forEach(([copyFrom, copyTo]) => {
                    form.setFieldValue(copyTo, undefined);
                  });
                }
              }}
            />
            {/* <Popover placement="bottom" title={"INFO"} content={autoFill.info}>
              <span style={{ fontSize: "15px" }}>
                <InfoCircleOutlined />
              </span>
            </Popover> */}
          </div>
        </div>
      )}
    </DisableMask>
  );
};

export default TableInput;
