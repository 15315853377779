import styled from "styled-components";

export const Container = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0rem 0 4rem 0;
  /* max-width: 135rem; */
  /* margin: auto; */
  /* padding: 1.5rem; */
  /* border-radius: 0.5rem; */
  /* background-image: url(http://wpdemo.archiwp.com/maxbizz-corporate/wp-content/uploads/sites/10/2020/11/bg1-home4.jpg); */
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
`;

export const Content = styled.div`
  /* padding: 7rem 6rem 10rem; */
  display: flex;
  gap: 2rem;
  margin-left: 2rem;
`;

export const Dots = styled.div`
  display: flex;
  justify-content: center;
`;

export const Dot = styled.div`
  width: 3rem;
  height: 3rem;
  border: 1px solid
    ${({ isActive }) => (isActive ? "rgba(157,158,162,.5)" : "transparent")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: ${({ isActive }) => (isActive ? "#55bb53" : "#7693a9")};
  }
`;

export const Heading = styled.div`
  color: #0b2a41;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;
