import DynamicFormItem from "./Components/DynamicFormItem"; 
import { Form } from "antd";
import TableInput from "./Components/TableInput";

const TaxReturnFormItem = ({ question, attachementUrls, index, form }) => {
  const {
    type,
    label,
    name,
    options,
    dependency,
    values,
    rules,
    inputType,
    inputProps,
    condType,
    rows,
    columns,
    conditionalColumns,
    multiplierColumns,
    autoFill,
    copyFields
  } = question;
  switch (type) {
    case "conditional":
      const showField =
        form.getFieldValue(dependency) && values.includes(form.getFieldValue(dependency));
      if (condType === "tableInput") {
        return (
          <Form.Item key={index} label={label} name={name} rules={rules}>
            <TableInput
              name={name}
              rows={rows}
              columns={columns}
              conditionalColumns={conditionalColumns}
              multiplierColumns={multiplierColumns}
              form={form}
              isDisabled={!showField}
            />
          </Form.Item>
        );
      } else {
        return (
          <DynamicFormItem
            key={index}
            type={condType}
            name={name}
            label={label}
            rules={rules}
            options={options}
            inputType={inputType}
            inputProps={inputProps}
            isDisabled={!showField}
            url={attachementUrls?.[name]?.url}
          />
        );
      }
    case "tableInput":
      return (
        <Form.Item key={index} label={label} name={name} rules={rules}>
          <TableInput
            name={name}
            rows={rows}
            columns={columns}
            conditionalColumns={conditionalColumns}
            multiplierColumns={multiplierColumns}
            form={form}
            autoFill={autoFill}
            copyFields={copyFields}
          />
        </Form.Item>
      );
    default:
      return (
        <DynamicFormItem
          key={index}
          type={type}
          name={name}
          label={label}
          rules={rules}
          options={options}
          inputProps={inputProps}
          inputType={inputType}
          url={attachementUrls?.[name]?.url}
        />
      );
  }
};

export default TaxReturnFormItem;
