const UploadAsterix = () => {
  return (
    <div
      style={{
        marginInlineEnd: "4px",
        color: "#ff4d4f",
        fontSize: "14px",
        fontFamily: "SimSun,sans-serif",
      }}
    >
      *
    </div>
  );
};

export default UploadAsterix;
