import { useDeleteBlogMutation } from "Hooks/apiHooks";
import { message } from "antd";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  box-shadow: 8px 8px 30px 0 rgb(0 0 0 / 10%);
  border-radius: 0.5rem;
  width: 80rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 40px;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Meta = styled.div`
  margin-top: 10px;
  font-weight: 600;
`;

const Description = styled.div`
  margin-top: 10px;
`;

const BlogCard = ({ id, title, published, desc, author, refetchBlogs }) => {
  const navigate = useNavigate();
  const mutation = useDeleteBlogMutation();
  return (
    <Container>
      <div style={{ marginRight: "20px" }}>
        <Title>{title}</Title>
        <Meta>
          By {author} on {published}
        </Meta>
        <Description>{desc}</Description>
      </div>
      <div>
        <div
          onClick={() => {
            navigate(`/dashboard/blog/${id}`);
          }}
        >
          <AiFillEdit />
        </div>
        <div>
          <AiFillDelete
            onClick={async () => {
              await mutation.mutateAsync(id);
              message.success('Blog Deleted');
              await refetchBlogs()
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default BlogCard;
