import styled from "styled-components";
import React from "react";

const ServiceContentContainer = styled.div``;

const ServiceImg = styled.img`
  max-width: 100%;
  overflow: hidden;
`;

const Heading = styled.h4`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.25px;
  margin-bottom: 15px;
  margin-top: 30px;
`;

const Para = styled.p`
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 0px;
`;

const ServiceContent = ({ imgLink, heading, content }) => {
  return (
    <ServiceContentContainer>
      <ServiceImg src={imgLink} alt="Content image not found." />
      <Heading>{heading}</Heading>
      <Para>
        {content.map((c, index) => (
          <React.Fragment key={index}>
            {c}
            <br></br>
            <br></br>
          </React.Fragment>
        ))}
      </Para>
    </ServiceContentContainer>
  );
};

export default ServiceContent;
