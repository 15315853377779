import { RightOutlined } from "@ant-design/icons";
import styled from "styled-components";

const CardContainer = styled.div`
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
  padding: 30px 30px;
  display: inline-block;
  background-color: #a4c639;
  font-size: 20px;
  color: ${(props) => props.isActive ? '#fff': '#121212'};
  letter-spacing: 0.5px;
  font-weight: 700;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
  }
`;

const ServiceCard = ({ text, link, onClick, isActive }) => {
  return (
    <CardContainer isActive={isActive} onClick={onClick}>
      <a href={link}>
        {text}
        <RightOutlined style={{ float: "right", marginTop: "5px" }} />
      </a>
    </CardContainer>
  );
};

export default ServiceCard;
