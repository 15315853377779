export const CACHE_KEYS = {
  LOGIN: "login",
  USERS: "users",
  CPAS: "cpas",
  TAX_RETURNS: "taxReturns",
  TAX_RETURNS_ALL: "taxReturns-all",
  TAX_RETURNS_CPA: "taxReturns-cpa",
  PAYMENTS: "payments",
  FORMS: "forms",
  TASKS: "tasks",
  BLOGS: "blogs",
  ATTACHMENTS: "attachments",
  USER: 'userById',
  CPA: 'cpaById',
  TAX_RETURN: `taxReturnById`,
  PAYMENT: 'paymentById',
  FORM: 'formById',
  FORM_BY_TAX_RETURN: 'formByTaxReturnId',
  TASK: 'taskById',
  ATTACHMENT: 'attachmentById',
  BLOG: 'blogById',
};
