import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Layout, Space, Avatar, Skeleton } from "antd";
import { useLocation, Link } from "react-router-dom";
import { DelayedRender, Head } from "Components";
import ROUTES from "constants/routes";
import { useToggle, useWindowSize } from "Hooks";

const { Sider } = Layout;
const { SubMenu } = Menu;

const AntSidebar = () => {
  const location = useLocation();
  const { width } = useWindowSize();
  const { isAuthenticated, user } = useAuth0();
  const [collapsed, toggleCollapsed] = useToggle(true);

  const [_, activeTab, activeSubTab] = location.pathname.split("/");
  const activeColor = "#FFF";

  return (
    <>
      <Head>
        <title>ML Ninja - {activeTab}</title>
      </Head>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onMouseEnter={toggleCollapsed}
        onMouseLeave={toggleCollapsed}
        collapsedWidth={width > 1600 ? 102 : 80}
        width={width > 1600 ? 306 : 280}
      >
        <Menu
          mode="inline"
          theme="light"
          defaultSelectedKeys={[activeTab]}
          selectedKeys={[activeTab]}
        >
          <div>
            <Space>
              {/* <Logo /> */}
              {!collapsed && <DelayedRender>{/* <LogoText /> */}</DelayedRender>}
            </Space>
            {!collapsed && (
              <DelayedRender>
                <span className={styles.version}>BETA</span>
              </DelayedRender>
            )}
          </div>
          <SubMenu
            title="Dashboard"
            key="dashboard"
            icon={<span className="anticon">{/* <DashboardIcon /> */}</span>}
          >
            <Menu.Item>
              <Link
                to={ROUTES.DASHBOARD.MAIN}
              >
                Overview
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link
                to={ROUTES.DASHBOARD.MAIN}
              >
                Business Models
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="monitor"
            icon={
              collapsed ? (
                <span>
                </span>
              ) : null
            }
          >
            <Link
              to={ROUTES.DASHBOARD.MAIN}
            >
              Monitor {activeTab !== "monitor"}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="profile"
            icon={
              collapsed ? (
                <span>
                </span>
              ) : null
            }
          >
            <Link
              to={ROUTES}
              // className={cx(styles.link, { [styles.active]: activeTab === "profile" })}
            >
              {/* <ProfileIcon color={activeTab === "profile" ? activeColor : undefined} />{" "} */}
              User Profile {activeTab !== "profile"}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="settings"
            icon={
              collapsed ? (
                <span>
                  {/* <SettingsIcon
                    color={activeTab === "settings" ? activeColor : undefined}
                  /> */}
                </span>
              ) : null
            }
            // className={styles.menuItem}
          >
            <Link
              to={ROUTES.SETTINGS}
              // className={cx(styles.link, { [styles.active]: activeTab === "settings" })}
            >
              {/* <SettingsIcon color={activeTab === "settings" ? activeColor : undefined} />{" "} */}
              Settings {activeTab !== "settings"}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="help"
            icon={
              collapsed ? (
                <span>
                  {/* <HelpIcon color={activeTab === "help" ? activeColor : undefined} /> */}
                </span>
              ) : null
            }
            // className={styles.menuItem}
          >
            <Link
              to={ROUTES.HELP}
              // className={cx(styles.link, { [styles.active]: activeTab === "help" })}
            >
              {/* <HelpIcon color={activeTab === "help" ? activeColor : undefined} /> Help{" "} */}
              {activeTab !== "help"}
            </Link>
          </Menu.Item>
        </Menu>
        <div>
          <div>
            <div>
              {false ? (
                <Skeleton.Avatar shape="circle" />
              ) : (
                <Avatar size={42} src={user?.profilePictureUrl} />
              )}
            </div>
            {!collapsed && (
              <DelayedRender>
                <div>
                  <div>
                    {/* <h3>{data?.username?.split(" ")?.[0]}</h3> */}
                    <span>{user?.roles[0].name}</span>
                  </div>
                  {/* <ChevronDownIcon /> */}
                </div>
              </DelayedRender>
            )}
          </div>
        </div>
      </Sider>
    </>
  );
};

export default AntSidebar;
