import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 4;
  @media screen (max-width: 700px) {
    width: 50%;
  }
  @media screen and (max-width: 1200px) and (min-width: 700px) {
    width: 30%;
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }
  display: ${props => props.$isHamMenuVisible ? "block" : "none"};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  background: #fff;
  padding: 2.5rem;
`;

export const HamMenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  . ant-menu-item-group {
    background-color: green;
  }
  .ant-menu-inline .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 1rem !important;
    background-color: transparent;
  }
  .ant-menu-light
    .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ) {
    background-color: transparent;
    padding: 0 !important;
  }
  .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):(.ant-menu-submenu-selected) {
    background-color: transparent;
    padding: 0 !important;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding-left: 0rem !important;
    margin: 0;
  }
  .ant-menu-submenu-title {
    padding: 1rem;
    background-color: transparent;
  }
  .li.ant-menu-item ant-menu-item-only-child {
    padding: 0 !important;
    margin: 0;
  }
  .ant-menu-light.ant-menu-inline .ant-menu-item {
    padding: 1.5rem !important;
  }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
  }
  .ant-menu-inline.ant-menu-root {
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    color: #4f4f4f;
  }
`;

export const HamMenuLink = styled.div`
  gap: 1.5rem;
`;

export const CloseHamMenu = styled.div`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

export const HamMenuLinksSeparator = styled.hr`
  height: 0.1rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
`;

export const LoginSignup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
  @media screen and (min-width: 670px) {
    display: none;
  }
`;
