import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 3rem;
  margin-top: 10rem;
  @media screen and(max-width: 1000px) {
    margin: 0;
  }
`;

export const Container = styled.div`
  max-width: 110rem;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 9.6rem;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    gap: 6rem;
  }
  @media screen and (max-width: 1040px) {
    flex-direction: column;
  }
  @media screen and (max-width: 900px) {
    gap: 4rem;
  }
`;

export const HeadingDesc = styled.div`
  width: 42%;
  @media screen and (max-width: 1200px) {
    width: auto;
    flex: 1;
  }
  @media screen and (max-width: 1040px) {
    text-align: center;
  }
`;

export const Heading = styled.div`
  color: #0b2a41;
  font-size: 5.6rem;
  font-weight: 700;
  @media screen and (max-width: 1200px) {
    font-size: 4.6rem;
  }
  @media screen and (max-width: 1040px) {
    & br {
      display: none;
    }
  }
  @media screen and (max-width: 900px) {
    font-size: 4.5rem;
  }
  @media screen and (max-width: 660px) {
    font-size: 3.8rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 3.5rem;
  }
`;

export const Desc = styled.div`
  margin-top: 1.5rem;
  color: #60626d;
  font-size: 2rem;
  font-weight: 500;
  line-height: 30px;
  @media screen and (max-width: 1200px) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6rem;
    line-height: 25px;
  }
  @media screen and (max-width: 400px) {
    font-size: 1.4rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  /* @media screen and (max-width: 660px) {
        flex-direction: column;
        gap: 1.2rem;
        width: 100%;
    } */
`;

export const NumberCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  @media screen and (max-width: 660px) {
    width: 100%;
  }
`;

export const NumberCard = styled.div`
  padding: 2rem;
  width: 28rem;
  height: 20rem;
  box-shadow: 0 0 34px rgb(70 74 85 / 10%);
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 660px) {
    width: 100%;
    height: 15rem;
  }
`;

export const NumberCardNumberTitle = styled.div``;

export const NumberCardNumber = styled.div`
  font-size: 3.2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: #0b2a41;
  & div:last-child {
    font-size: 3rem;
    color: #54ba53;
  }
  @media screen and (max-width: 660px) {
    font-size: 2.5rem;
  }
`;

export const NumberCardTitle = styled.div`
  font-weight: 500;
  color: #9092a3;
  font-size: 2rem;
  text-transform: capitalize;
  margin-top: 0.2rem;
  @media screen and (max-width: 660px) {
    font-size: 1.6rem;
  }
`;

export const NumberCardIcon = styled.img`
  width: 6rem;
  height: 6rem;
  margin-left: auto;
`;
