import React from "react";
import { HiChevronRight } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import "@lottiefiles/lottie-player";
import {
  Container,
  Content,
  Desc,
  Heading,
  Image,
  Link,
  Title,
  TitleLine,
} from "./styles";

const ImageContent = ({
  imgAtRight,
  img,
  title,
  heading,
  desc,
  link,
  style = {},
  imageStyles = {},
  lottiePlayerStyles = {},
  contentStyles = {},
  paraStyles = {},
}) => {
  return (
    <Container style={style}>
      <Image style={{ order: imgAtRight ? 1 : 0, ...imageStyles }}>
        <lottie-player
          autoplay
          loop
          mode="normal"
          src={img}
          style={{
            width: "70%",
            margin: "auto",
            ...lottiePlayerStyles,
          }}
        ></lottie-player>
      </Image>
      <Content style={contentStyles}>
        {title && (
          <Title>
            <TitleLine />
            {title}
          </Title>
        )}
        {heading && <Heading>{heading}</Heading>}
        <Desc>
          {desc.map((p, index) => (
            <p
              style={{
                marginBottom: index !== desc.length - 1 ? "2rem" : "0",
                ...paraStyles,
              }}
              key={index}
            >
              {p}
            </p>
          ))}
        </Desc>
        {link && (
          <NavLink to={link.url}>
            <Link>
              {link.text}
              <HiChevronRight />
            </Link>
          </NavLink>
        )}
      </Content>
    </Container>
  );
};

export default ImageContent;
