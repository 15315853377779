import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 5rem;
  padding: 0 2.5rem;
`;

export const Container = styled.div`
  max-width: 120rem;
  margin: auto;
`;

export const DescHeading = styled.div`
  margin-bottom: 4.5rem;
  text-align: center;
  @media screen and (max-width: 700px) {
    margin-bottom: 3rem;
  }
`;

export const Desc = styled.div`
  color: #95a2ac;
  font-size: 1.4rem;
  line-height: 1.875;
  font-weight: 600;
  text-transform: uppercase;
`;

export const Heading = styled.div`
  color: #0b2a41;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FAQ = styled.div``;

export const Question = styled.div`
  font-weight: 600;
  line-height: 30px;
  padding: 1rem 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: all 0.3s linear;
  background: #f6f6f6;
  border: 0.1rem solid rgba(0, 0, 0, 0.15);
  color: #1b1d21;
  ${({ isExpanded }) =>
    isExpanded
      ? `
                background: #55bb53;
                color: #fff;
                border-color: #55bb53;
              `
      : ""}
`;

export const QuestionExpandCollapseArrow = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  transition: all 0.3s linear;
  ${({ isExpanded }) =>
    isExpanded
      ? `
                transform: rotate(-90deg);
              `
      : ""}
`;

export const Answer = styled.div`
  padding: 2rem 2rem 1.2rem;
  line-height: 30px;
  color: #5f5f5f;
  font-weight: 400;
`;
