import { useQuery, useMutation, useQueryClient } from "react-query";
import { API_URLS } from "constants/apiUrls";
import { CACHE_KEYS } from "constants/enums";
import { useAxios } from "Context/AxiosProvider";

//Login API
export const useLoginQuery = (isEnabled = true) => {
  const { axios, isTokenLoaded } = useAxios(); // Add this change to every hook

  const request = async () => {
    const { data } = await axios.post(API_URLS.USER.LOGIN);
    return data;
  };
  return useQuery(CACHE_KEYS.LOGIN, request, { enabled: isTokenLoaded && isEnabled });
};

// Users API
export const useUsersQuery = (userType) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.USER.LIST, {
      params: userType ? { type: userType } : {},
    });
    return data;
  };

  return useQuery([CACHE_KEYS.USERS, userType ?? "ALL"], request);
};

// Users - Get Single User
export const useUserQuery = (userId) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.USER.GET(userId));
    return data;
  };

  return useQuery([CACHE_KEYS.USER, userId], request);
};

// Users - Update User
export const useUpdateUserMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updateUser = async (userId, userData) => {
    const { data } = await axios.put(API_URLS.USER.UPDATE(userId), userData);
    return data;
  };

  return useMutation(updateUser, {
    // Invalidate the users list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.USERS);
    },
  });
};

// Users - Delete User
export const useDeleteUserMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteUser = async (userId) => {
    await axios.delete(API_URLS.USER.DELETE(userId));
  };

  return useMutation(deleteUser, {
    // Invalidate the users list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.USERS);
    },
  });
};

// // CPA API
// export const useCpasQuery = () => {
//   const { axios } = useAxios();

//   const request = async () => {
//     const { data } = await axios.get(API_URLS.CPA.LIST);
//     return data;
//   };

//   return useQuery(CACHE_KEYS.CPAS, request);
// };

// // CPA - Get Single CPA
// export const useCpaQuery = (cpaId) => {
//   const { axios } = useAxios();

//   const request = async () => {
//     const { data } = await axios.get(API_URLS.CPA.GET(cpaId));
//     return data;
//   };

//   return useQuery([CACHE_KEYS.CPA, cpaId], request);
// };

// CPA - Create CPA
export const useCreateCpaMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const createCPA = async (userId) => {
    const { data } = await axios.post(API_URLS.CPA.CREATE(userId));
    return data;
  };

  return useMutation(createCPA, {
    // Invalidate the CPAs list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.USERS);
    },
  });
};

// CPA - Update CPA
// export const useUpdateCpaMutation = () => {
//   const { axios } = useAxios();
//   const queryClient = useQueryClient();

//   const updateCpa = async (cpaId, cpaData) => {
//     const { data } = await axios.put(API_URLS.CPA.UPDATE(cpaId), cpaData);
//     return data;
//   };

//   return useMutation(updateCpa, {
//     // Invalidate the CPAs list cache when the mutation is successful
//     onSuccess: () => {
//       queryClient.invalidateQueries(CACHE_KEYS.CPAS);
//     },
//   });
// };

// CPA - Delete CPA
export const useDeleteCpaMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteCpa = async (userId) => {
    await axios.delete(API_URLS.CPA.DELETE(userId));
  };

  return useMutation(deleteCpa, {
    // Invalidate the CPAs list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.USERS);
    },
  });
};

const removeUndefined = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value !== undefined)
  );
};

// TAX_RETURNS API
export const useTaxReturnsQuery = () => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.TAX_RETURNS.LIST);
    return data;
  };

  return useQuery(CACHE_KEYS.TAX_RETURNS, request);
};

// TAX_RETURNS API
export const useAllTaxReturnsQuery = (params = {}, isEnabled = true) => {
  const { axios } = useAxios();

  const { year, userId } = params;

  const request = async () => {
    const { data } = await axios.get(API_URLS.TAX_RETURNS.LIST_ALL, {
      params: removeUndefined({ year, userId }),
    });
    return data;
  };

  return useQuery([CACHE_KEYS.TAX_RETURNS_ALL, JSON.stringify(params)], request, {
    enabled: isEnabled,
  });
};

// TAX_RETURNS API
export const useCPATaxReturnsQuery = () => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.TAX_RETURNS.LIST_CPA);
    return data;
  };

  return useQuery(CACHE_KEYS.TAX_RETURNS_CPA, request);
};

// TAX_RETURNS - Get Single TAX_RETURN
export const useTaxReturnQuery = (taxReturnId) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.TAX_RETURNS.GET(taxReturnId));
    return data;
  };

  return useQuery([CACHE_KEYS.TAX_RETURN, taxReturnId], request);
};

// TAX_RETURNS - Create TAX_RETURN
export const useCreateTaxReturnMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const createTaxReturn = async (taxReturnData) => {
    const { data } = await axios.post(API_URLS.TAX_RETURNS.CREATE, taxReturnData);
    return data;
  };

  return useMutation(createTaxReturn, {
    // Invalidate the TAX_RETURNS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.TAX_RETURNS);
    },
  });
};

// TAX_RETURNS - Update TAX_RETURN
export const useUpdateTaxReturnMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updateTaxReturn = async ({ taxReturnId, taxReturnData }) => {
    const { data } = await axios.put(
      API_URLS.TAX_RETURNS.UPDATE(taxReturnId),
      taxReturnData
    );
    return data;
  };

  return useMutation(updateTaxReturn, {
    // Invalidate the TAX_RETURNS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.TAX_RETURNS);
    },
  });
};

// TAX_RETURNS - Delete TAX_RETURN
export const useDeleteTaxReturnMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteTaxReturn = async (taxReturnId) => {
    await axios.delete(API_URLS.TAX_RETURNS.DELETE(taxReturnId));
  };

  return useMutation(deleteTaxReturn, {
    // Invalidate the TAX_RETURNS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.TAX_RETURNS);
    },
  });
};

// PAYMENTS API
export const usePaymentsQuery = () => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.PAYMENTS.LIST);
    return data;
  };

  return useQuery(CACHE_KEYS.PAYMENTS, request);
};

// PAYMENTS - Get Single PAYMENT
export const usePaymentQuery = (paymentId) => {
  const { axios } = useAxios();
  const request = async () => {
    const { data } = await axios.get(API_URLS.PAYMENTS.GET(paymentId));
    return data;
  };

  return useQuery([CACHE_KEYS.PAYMENT, paymentId], request);
};

// PAYMENTS - Update PAYMENT
export const useUpdatePaymentMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updatePayment = async (paymentId, paymentData) => {
    const { data } = await axios.put(API_URLS.PAYMENTS.UPDATE(paymentId), paymentData);
    return data;
  };

  return useMutation(updatePayment, {
    // Invalidate the PAYMENTS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.PAYMENTS);
    },
  });
};

// PAYMENTS - Delete PAYMENT
export const useDeletePaymentMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deletePayment = async (paymentId) => {
    await axios.delete(API_URLS.PAYMENTS.DELETE(paymentId));
  };

  return useMutation(deletePayment, {
    // Invalidate the PAYMENTS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.PAYMENTS);
    },
  });
};

// FORMS API
export const useFormsQuery = () => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.FORMS.LIST);
    return data;
  };

  return useQuery(CACHE_KEYS.FORMS, request);
};

// FORMS - Get Single FORM
export const useFormQuery = (formId) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.FORMS.GET(formId));
    return data;
  };

  return useQuery([CACHE_KEYS.FORM(formId), formId], request);
};

// FORMS - Get Forms by Tax Return id
export const useFormByTaxReturnQuery = (taxReturnId) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.FORMS.GET_BY_TAX_RETURN(taxReturnId));
    return data;
  };

  return useQuery([CACHE_KEYS.FORM_BY_TAX_RETURN, taxReturnId], request);
};

// FORMS - CREATE FORM
export const useCreateFormMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const createForm = async (formData) => {
    const { data } = await axios.post(API_URLS.FORMS.CREATE, formData);
    return data;
  };

  return useMutation(createForm, {
    // Invalidate the FORMS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.FORMS);
    },
  });
};

// FORMS - Update FORM
export const useUpdateFormMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updateForm = async ({ formId, formData }) => {
    const { data } = await axios.put(API_URLS.FORMS.UPDATE(formId), formData);
    return data;
  };

  return useMutation(updateForm, {
    // Invalidate the FORMS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.FORMS);
    },
  });
};

// FORMS - Delete FORM
export const useDeleteFormMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteForm = async (formId) => {
    await axios.delete(API_URLS.FORMS.DELETE(formId));
  };

  return useMutation(deleteForm, {
    // Invalidate the FORMS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.FORMS);
    },
  });
};

// TASKS API
export const useTasksQuery = () => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.TASKS.LIST);
    return data;
  };

  return useQuery(CACHE_KEYS.TASKS, request);
};

// TASKS - Get Single TASK
export const useTaskQuery = (taskId) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.TASKS.GET(taskId));
    return data;
  };

  return useQuery([CACHE_KEYS.TASK, taskId], request);
};

// TASKS - Update TASK
export const useUpdateTaskMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updateTask = async (taskId, taskData) => {
    const { data } = await axios.put(API_URLS.TASKS.UPDATE(taskId), taskData);
    return data;
  };

  return useMutation(updateTask, {
    // Invalidate the TASKS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.TASKS);
    },
  });
};

// TASKS - Delete TASK
export const useDeleteTaskMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteTask = async (taskId) => {
    await axios.delete(API_URLS.TASKS.DELETE(taskId));
  };

  return useMutation(deleteTask, {
    // Invalidate the TASKS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.TASKS);
    },
  });
};

// ATTACHMENTS API
export const useAttachmentsQuery = () => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.ATTACHMENTS.LIST);
    return data;
  };

  return useQuery(CACHE_KEYS.ATTACHMENTS, request);
};

// ATTACHMENTS - Get Single ATTACHMENT
export const useAttachmentQuery = (attachmentId) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.ATTACHMENTS.GET(attachmentId));
    return data;
  };

  return useQuery([CACHE_KEYS.ATTACHMENT, attachmentId], request);
};

export const useAttachmentsByIdQuery = (attachementIds, isEnabled) => {
  const { axios } = useAxios();
  const request = async () => {
    const { data } = await axios.get(API_URLS.ATTACHMENTS.GET_BY_IDS, {
      params: { attachementIds },
    });
    return data;
  };

  return useQuery([CACHE_KEYS.ATTACHMENT, ...attachementIds], request, {
    enabled: isEnabled,
  });
};

// ATTACHMENTS - Create ATTACHMENT
export const useCreateAttachmentMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updateAttachment = async (attachmentData) => {
    const { data } = await axios.post(API_URLS.ATTACHMENTS.CREATE, attachmentData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  };

  return useMutation(updateAttachment, {
    // Invalidate the ATTACHMENTS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.ATTACHMENTS);
    },
  });
};

// ATTACHMENTS - Update ATTACHMENT
export const useUpdateAttachmentMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updateAttachment = async (attachmentId, attachmentData) => {
    const { data } = await axios.put(
      API_URLS.ATTACHMENTS.UPDATE(attachmentId),
      attachmentData
    );
    return data;
  };

  return useMutation(updateAttachment, {
    // Invalidate the ATTACHMENTS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.ATTACHMENTS);
    },
  });
};

// ATTACHMENTS - Delete ATTACHMENT
export const useDeleteAttachmentMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteAttachment = async (attachmentId) => {
    await axios.delete(API_URLS.ATTACHMENTS.DELETE(attachmentId));
  };

  return useMutation(deleteAttachment, {
    // Invalidate the ATTACHMENTS list cache when the mutation is successful
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.ATTACHMENTS);
    },
  });
};

// BLOGS API
export const useBlogsQuery = () => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.BLOGS.LIST);
    return data;
  };

  return useQuery(CACHE_KEYS.BLOGS, request);
};

// Blogs - Get Single Blog
export const useBlogQuery = (blogId, hookOptions) => {
  const { axios } = useAxios();

  const request = async () => {
    const { data } = await axios.get(API_URLS.BLOGS.GET(blogId));
    return data;
  };

  return useQuery([CACHE_KEYS.BLOG, blogId], request, hookOptions);
};

// Blogs - Update Blog
export const useCreateBlogMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const createBlog = async (blogData) => {
    const { data } = await axios.post(API_URLS.BLOGS.CREATE, blogData);
    return data;
  };

  return useMutation(createBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.BLOGS);
    },
  });
};

// Blogs - Update Blog
export const useUpdateBlogMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const updateBlog = async ({ blogId, blogData }) => {
    const { data } = await axios.patch(API_URLS.BLOGS.UPDATE(blogId), blogData);
    return data;
  };

  return useMutation(updateBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.BLOGS);
    },
  });
};

// Blog - Delete Blog
export const useDeleteBlogMutation = () => {
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteBlog = async (blogId) => {
    await axios.delete(API_URLS.BLOGS.DELETE(blogId));
  };

  return useMutation(deleteBlog, {
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.BLOGS);
    },
  });
};
