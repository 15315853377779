import React, { useEffect } from "react";

const PendingTasks = () => {
  useEffect(() => {
    document.title = "Services • Eazytaxes";
  }, []);

  return <div>{"Pending Tasks"}</div>;
};

export default PendingTasks;
