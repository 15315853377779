export const getSecondPath = (path) => {
  return path.split("/").slice(2).join("/");
};

const ROUTES = {
  ROOT: "/",
  ABOUT_US: "about-us",
  AUTH0_CALLBACK: "auth-callback",
  SERVICES: {
    MAIN: "services",
    SALARIED: "/services/salaried-professional",
    EBAY: "/services/ebay-shopify-amazon-sellers",
    SMALL_BUISNESS: "/services/small-business-owners",
    STUDENTS: "/services/students",
    TEMPLATE: "/services/template"
  },
  BLOGS: { MAIN: "/blogs", DETAIL: "/blog/:blogId" },
  PRICING: "/pricing",
  CONTACT_US: "/contact-us",
  TESTIMONIAL: "/testimonials",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-and-conditions",
  GET_MY_TAXES_FILED: "/get-my-taxes-filed",
  DASHBOARD: {
    MAIN: "/dashboard",
    HOME: "/dashboard/cognito",
    CREATE_BLOG: "/dashboard/blog/create",
    EDIT_BLOG: "/dashboard/blog/:blogId",
    BLOGS: "/dashboard/blogs",
    TAX_RETURN: {
      MAIN: "/dashboard/tax-return",
      CREATE: "/dashboard/tax-return/create",
      DETAIL: "/dashboard/tax-return/:taxReturnId",
      EDIT: "/dashboard/tax-return/:taxReturnId/edit",
    },
    CONNECT_CPA: "/dashboard/connect-cpa",
    TASKS: "/dashboard/tasks",
    DOCUMENTS: "/dashboard/documents",
    PAYMENTS: "/dashboard/payments",
    CREATE_CPA: "/dashboard/create-cpa",
    ASSIGN_CPA: "/dashboard/assign-cpa",
    TAX_VIEW_CPA: "/dashboard/tax-view-cpa",
    TAX_FORM_CPA: "/dashboard/tax-form-cpa/:taxReturnId",
    COGNITO: '/dashboard/cognito'
  },
  SETTINGS: "/settings",
  HELP: "/help",
  PROFILE: "/profile",
  ACCOUNT: {
    PROFILE: "/account/profile",
  },
};

export default ROUTES;
