import { Form, Input, Radio, Select, Button, Upload, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import UploadAsterix from "Components/uiComponents/UploadAsterix";

const DynamicFormItem = ({
  type,
  name,
  label,
  rules,
  options,
  inputType,
  inputProps,
  isDisabled,
  url,
}) => {
  switch (type) {
    case "radio":
      return (
        <Form.Item label={label} name={name} rul es={rules}>
          <Radio.Group disabled={Boolean(isDisabled)}>
            {options.map((option, index) => (
              <Radio key={index} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    case "check":
      return (
        <Form.Item label={label} name={name} rules={rules} valuePropName="checked">
          <Checkbox disabled={Boolean(isDisabled)}></Checkbox>
        </Form.Item>
      );
    case "select":
      return (
        <Form.Item label={label} name={name} rules={rules}>
          <Select disabled={Boolean(isDisabled)}>
            {options.map((option, index) => (
              <Select.Option key={index} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "input":
      return (
        <Form.Item label={label} name={name} rules={rules}>
          <Input
            type={inputType || "text"}
            disabled={Boolean(isDisabled)}
            {...inputProps}
          />
        </Form.Item>
      );
    case "attachment":
      return (
        <div style={{ display: "flex" }}>
          {!isDisabled && rules.some((rule) => rule.required) ? <UploadAsterix /> : null}
          <Form.Item label={label} name={name} valuePropName="file">
            <Upload
              beforeUpload={() => false}
              listType="text"
              disabled={Boolean(isDisabled)}
            >
              <Button disabled={Boolean(isDisabled)} icon={<UploadOutlined />}>
                Click to upload
              </Button>
            </Upload>
          </Form.Item>
          {url && (
            <a
              href={url}
              target="_blank"
              style={{
                marginLeft: "5px",
                color: "darkblue",
                marginTop: "3.4rem",
                fontWeight: 500,
              }}
            >
              Preview Uploaded File
            </a>
          )}
        </div>
      );
    case "blank":
      return <></>;
    default:
      throw new Error(`Form Item type - ${type} does not exist` );
  }
};

export default DynamicFormItem;
