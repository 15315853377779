import styled from "styled-components";

export const Container = styled.div`
  margin: 10rem 0;
  padding: 0 2.5rem;
`;

export const BlogsList = styled.div`
  max-width: 140rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: auto;
  gap: 5rem;
  justify-content: center;
`;
