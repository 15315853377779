import styled from "styled-components";

export const Container = styled.div`
  max-width: 122.5rem;
  margin: 10rem auto;
  padding: 0 2.5rem;
`;

export const Heading = styled.div`
  text-align: center;
  font-size: 4.8rem;
  font-weight: bold;
  margin-bottom: 4rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const ContentHeading = styled.div`
  font-size: 3rem;
  font-weight: bold;
`;

export const ContentParagraph = styled.div``;
