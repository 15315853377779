import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 200px;
`;

const TextContainer = styled.div`
  text-align: center;
  font-size: 35px;
  margin: 20px;
`;

const Container = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  padding: 20px;
  background-color: white;
`;

const Cognito = () => {
  useEffect(() => {
    document.title = "Enter Form • Eazytaxes";
  }, []);

  const [formType, setFormType] = useState(undefined);

  return (
    <div>
      {!formType && (
        <>
          <TextContainer>Select the type of tax return</TextContainer>
          <Container>
            <div>
              <ButtonContainer>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "50px",
                    }}
                  >
                    <img
                      src="/assets/dashboard/corporate.webp"
                      style={{ height: "75px", width: "75px" }}
                    />
                  </div>
                  <Button
                    style={{
                      height: "75px",
                      width: "150px",
                      fontSize: "22px",
                      backgroundColor: "rgb(85, 187, 83)",
                      fontFamily: "Poppins, sans-serif",
                    }}
                    type="primary"
                    size="large"
                    onClick={() => setFormType("buisness")}
                  >
                    Buisness{" "}
                  </Button>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "50px",
                    }}
                  >
                    <img
                      src="/assets/dashboard/individual.webp"
                      style={{ height: "75px", width: "75px" }}
                    />
                  </div>
                  <Button
                    type="primary"
                    size="large"
                    style={{
                      height: "75px",
                      width: "150px",
                      fontSize: "22px",
                      backgroundColor: "rgb(85, 187, 83)",
                      fontFamily: "Poppins, sans-serif",
                    }}
                    onClick={() => setFormType("individual")}
                  >
                    Individual{" "}
                  </Button>
                </div>
              </ButtonContainer>
            </div>
          </Container>
        </>
      )}
      {formType === "buisness" && (
        <FormContainer>
          <iframe
            src="https://www.cognitoforms.com/f/fOEBWyHKaE27etZu-pFlbw/3"
            style={{ border: "0", width: "100%" }}
            height="3333"
          ></iframe>
          <script src="https://www.cognitoforms.com/f/iframe.js"></script>
        </FormContainer>
      )}
      {formType === "individual" && (
        <FormContainer>
          <iframe
            src="https://www.cognitoforms.com/f/fOEBWyHKaE27etZu-pFlbw/2"
            style={{ border: "0", width: "100%" }}
            height="1339"
          ></iframe>
          <script src="https://www.cognitoforms.com/f/iframe.js"></script>
        </FormContainer>
      )}
    </div>
  );
};

export default Cognito;
