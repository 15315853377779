import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { AxiosProvider } from "./AxiosProvider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      retry(failureCount, error) {
        if (error instanceof Error) {
          if (error.status === 404) return false;
          if (failureCount < 2) return true;
          return false;
        }
        return false;
      },
    },
  },
});

const AppProviders = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <AxiosProvider>{children}</AxiosProvider>
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </QueryClientProvider>
);

export { AppProviders };
