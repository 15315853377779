import React, { useState, useCallback } from "react";
import { Button } from "Components";
import { Form, Select, message } from "antd";
import TaxReturnForm from "./TaxReturnForm";
import { useCreateTaxReturnMutation } from "Hooks/apiHooks";
import { Head } from "Components";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/routes";

const FormCard = styled.div`
  padding: 0.4rem;
  margin: 0.4rem;
  box-shadow: 8px 8px 30px 0 rgb(0 0 0 / 10%);
  border-radius: 0.5rem;
  width: fit-content;
`;

const Heading = styled.div`
  margin: 0.4rem;
  font-size: 1.8rem;
  font-weight: 600;
`;

const TaxReturns = () => {
  const [form] = Form.useForm();
  const taxReturnMutation = useCreateTaxReturnMutation();
  const [taxReturnId, setTaxReturnId] = useState();

  const navigate = useNavigate();

  const handleReset = useCallback(() => {
    form.resetFields();
    setTaxReturnId(undefined);
    navigate(ROUTES.DASHBOARD.TAX_RETURN.MAIN);
  }, [form]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const { tax_return_id: id } = await taxReturnMutation.mutateAsync(values);
        setTaxReturnId(id);
        message.success("Tax return created.");
      } catch (error) {
        message.error(error.message);
      }
    },
    [setTaxReturnId, taxReturnMutation]
  );

  return (
    <div>
      <Head>
        <title>Services • Eazytaxes</title>
        <meta name="description" content="Filling of tax returns"></meta>
      </Head>
      {!taxReturnId && (
        <>
          <Heading>Create Tax Return</Heading>
          <FormCard>
            <div style={{ margin: "10px", display: "flex" }}>
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                requiredMark={false}
                onFinish={handleSubmit}
              >
                <Form.Item
                  label="Financial Year"
                  name="year"
                  rules={[{ required: true, message: "Field mandatory" }]}
                >
                  <Select size="large" placeholder="Select the financial year">
                    <Select.Option value={"2021-2022"}>FY 2021-2022</Select.Option>
                    <Select.Option value={"2022-2023"}>FY 2022-2023</Select.Option>
                    <Select.Option value={"2023-2024"}>FY 2023-2024</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Tax Return Type"
                  name="type"
                  rules={[{ required: true, message: "Field mandatory" }]}
                >
                  <Select size="large" placeholder="Select the tax type">
                    <Select.Option value={"companyRegistration"}>US Company Creation</Select.Option>
                  </Select>
                </Form.Item>
                <div>
                  <Button
                    size="large"
                    onClick={handleReset}
                    className="btn-cancel"
                    style={{ margin: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="btn-save"
                    style={{ margin: "10px" }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </FormCard>
        </>
      )}
      {taxReturnId && (
        <div style={{ margin: "10px" }}>
          <TaxReturnForm taxType={form.getFieldValue("type")} taxReturnId={taxReturnId} />
        </div>
      )}
    </div>
  );
};

export default TaxReturns;
