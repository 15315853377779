import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import {
  Answer,
  Container,
  Content,
  DescHeading,
  FAQ,
  Desc,
  Heading,
  Question,
  QuestionExpandCollapseArrow,
  Wrapper,
} from "./styles";
import Expand from "react-expand-animated";

const FAQs = () => {
  const [expandedQuestion, setExpandedQuestion] = useState();
  const questions = [
    {
      question: "What is eazytaxes.com and how does it work?",
      answer:
        "eazytaxes.com has pledged to bring you the power of a CPA firm in the price tag of a SaaS to accurately file your taxes online. To use our service, simply create an account, upload your documents, and let our team of experienced professionals handle the rest. We offer step-by-step guidance and a 100% accuracy guarantee to ensure that your return is done correctly.",
    },
    {
      question: "Can I use eazytaxes.com for my small business taxes?",
      answer:
        "Yes, eazytaxes.com is equipped to handle small business tax filing. Our platform can handle multiple sources of income and allows you to easily track and report business expenses.",
    },
    {
      question: "Is eazytaxes.com available for all states?",
      answer:
        "Yes, eazytaxes.com is available for all 50 states and the District of Columbia.",
    },
    {
      question: "Can I file my taxes for previous years using eazytaxes.com?",
      answer:
        "Yes, eazytaxes.com allows you to file your taxes for the current year as well as up to three previous tax years.",
    },
    {
      question:
        "How do I know that my taxes have been filed correctly with eazytaxes.com?",
      answer:
        "eazytaxes.com is committed to providing accurate and reliable tax filing services. Our team of experienced professionals reviews every return before it is submitted to ensure 100% accuracy.",
    },
    {
      question: "What happens if I am audited after using eazytaxes.com?",
      answer:
        "In the unlikely event that you are audited after using eazytaxes.com, our team is available to provide support and guidance to help you through the process. We will work with you to gather any necessary documentation and assist in preparing for the audit.",
    },
    {
      question: "I am a Salaried Individual. Can I use eazytaxes.com to file my taxes?",
      answer:
        "Absolutely! eazytaxes.com is the perfect platform for salaried professionals and small businesses who want to save money and time on their taxes. We offer a simple and easy-to-use online tax filing system that will make your life easier.",
    },
    {
      question: "How do I ensure that my data is safe with eazytaxes.com?",
      answer:
        "eazytaxes.com takes data security and privacy very seriously. We use a variety of physical, technical, and administrative safeguards to protect your personal information from unauthorized access, use, or disclosure. For example, we encrypt transmitted data using SSL technology, restrict access to information on a need-to-know basis, and conduct regular security audits. You can find more details about our security measures in our Privacy Policy.",
    },
    {
      question:
        "What if I am not satisfied with the tax filing process of eazytaxes.com?",
      answer:
        "Our customer satisfaction team will work with you to make things right. We want to make sure that you're happy with our service, so feel free to reach out to us if you have any questions or concerns. We offer a full refund if you are not satisfied with our online tax filing process.",
    },
  ];
  return (
    <Wrapper>
      <Container>
        <DescHeading>
          <Desc>FAQS</Desc>
          <Heading>Most Popular Questions</Heading>
        </DescHeading>
        <Content>
          {questions.map(({ question, answer }, index) => (
            <FAQ key={index}>
              <Question
                onClick={() =>
                  setExpandedQuestion((prev) => (prev === index ? null : index))
                }
                isExpanded={expandedQuestion === index}
              >
                {question}
                <QuestionExpandCollapseArrow isExpanded={expandedQuestion === index}>
                  <BsArrowRight />
                </QuestionExpandCollapseArrow>
              </Question>
              <Expand open={expandedQuestion === index}>
                <Answer>{answer}</Answer>
              </Expand>
            </FAQ>
          ))}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default FAQs;
