import { useCPATaxReturnsQuery } from "Hooks/apiHooks";
import { Heading } from "./styles";
import { useEffect } from "react";
import Loader from "Components/uiComponents/LoadingSpinner";
import styled from "styled-components";
import { Table, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { formatStatus, formatType } from "helpers";

const TableContainer = styled.div`
  width: 80%;
  @media screen and (max-width: 1300px) {
    width: 90%;
  }
  margin-top: 1rem;
`;

const CPATaxView = () => {
  const { data, error, isLoading } = useCPATaxReturnsQuery();

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <Loader message={"Loading Tax Returns"} />
      ) : (
        <>
          <Heading>Assigned Tax Returns</Heading>
          <TableContainer>
            <Table dataSource={data} pagination={false}>
              <Table.Column
                title={"Tax Type"}
                key='type'
                render={(value) => {
                  return formatType(value.type);
                }}
              />
              <Table.Column
                title={"Status"}
                key='status'
                render={(value) => {
                  return formatStatus(value.status);
                }}
              />
              <Table.Column title={"Year"} dataIndex="year" />
              <Table.Column title={"User"} dataIndex="user_email" />
              <Table.Column
                title={"Assigned CPA"}
                key="cpa"
                render={(value) => {
                  return value.cpa ?? "No CPA Assigned";
                }}
              />
              <Table.Column
                title={"View Tax Return"}
                render={(record) => {
                  return (
                    <Button
                      onClick={() =>
                        navigate(`/dashboard/tax-form-cpa/${record.tax_return_id}`)
                      }
                    >
                      View
                    </Button>
                  );
                }}
              />
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default CPATaxView;
