import React, { useState, useMemo, useEffect } from "react";
import SideBar from "./SideBar/SideBar";
import { Menu, Layout, message } from "antd";

import { useNavigate } from "react-router";
import ROUTES from "constants/routes";
import { Outlet } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

const topics = [
  {
    key: "0",
    title: "Cognito Form",
    path: ROUTES.DASHBOARD.COGNITO,
    roles: [],
  },
  // {
  //   key: "1",
  //   title: "Tax Returns",
  //   path: ROUTES.DASHBOARD.TAX_RETURN.MAIN,
  //   roles: ["User"],
  // },
  // {
  //   key: "2",
  //   title: "Connect CPA",
  //   path: ROUTES.DASHBOARD.CONNECT_CPA,
  //   roles: ["User"],
  // },
  // {
  //   key: "3",
  //   title: "Tasks",
  //   path: ROUTES.DASHBOARD.TASKS,
  //   roles: ["CPA"],
  // },
  // {
  //   key: "4",
  //   title: "Documents",
  //   path: ROUTES.DASHBOARD.DOCUMENTS,
  //   roles: ["CPA"],
  // },
  // {
  //   key: "5",
  //   title: "Blogs",
  //   path: ROUTES.DASHBOARD.BLOGS,
  //   roles: [],
  // },
  // {
  //   key: "6",
  //   title: "Assign CPA",
  //   path: ROUTES.DASHBOARD.ASSIGN_CPA,
  //   roles: [],
  // },
  // {
  //   key: "7",
  //   title: "Create CPA",
  //   path: ROUTES.DASHBOARD.CREATE_CPA,
  //   roles: [],
  // },
  // {
  //   key: "8",
  //   title: "CPA Tax View",
  //   path: ROUTES.DASHBOARD.TAX_VIEW_CPA,
  //   roles: ["CPA"],
  // },
];

const TopicMenu = ({ type, topics, selectedKey, setSelectedKey }) => {
  const navigate = useNavigate();

  const styledTopics = useMemo(
    () =>
      topics
        .map((topic) => {
          // if (topic.roles.includes(type) || type === "Admin") {
            return (
              <Menu.Item
                key={topic.key}
                onClick={(event) => {
                  const { key } = event;
                  setSelectedKey(key);
                  navigate(topic.path);
                }}
              >
                {topic?.title}
              </Menu.Item>
            );
          // }
        })
        .filter((topic) => !!topic),
    [type]
  );

  return (
    <Menu mode="inline" selectedKeys={[selectedKey]}>
      {styledTopics}
    </Menu>
  );
};

const Dashboard = () => {
  const [selectedKey, setSelectedKey] = useState();
  const { user, logout } = useAuth0();

  useEffect(() => {
    // if (user && !user.type) {
    //   logout({ logoutParams: { returnTo: `${process.env.REACT_APP_ORIGIN}?shouldLogin=true` } });
    //   return
    // }

    // if(user.type === 'User'){
      // setSelectedKey("0")
    // } else if(user.type === 'CPA'){
      // setSelectedKey("2")
    // } else if(user.type === 'Admin'){
      setSelectedKey("0")
    // }
  
    // Another validation if user is not created can be added here.
  }, []);

  return (
    <Layout>
      <SideBar
        menu={
          <TopicMenu
            type={user.type}
            topics={topics}
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
          />
        }
      />
      <Layout.Content className="content">
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default Dashboard;
