import React from "react";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { IoCall } from "react-icons/io5";
import { RiSendPlaneFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import {
  AboutUsDesc,
  AboutWebsite,
  AboutWebsiteFooterSections,
  ContactDetail,
  ContactDetailContent,
  ContactDetailIcon,
  ContactDetails,
  Container,
  Content,
  Copyright,
  CopyrightContent,
  FooterSection,
  FooterSectionContent,
  FooterSectionHeading,
  FooterSections,
  Links,
  Social,
  Socials,
  WebsiteLogo,
} from "./styles";
import ROUTES from "constants/routes";

const Footer = () => {
  const companyLinks = [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "About Us",
      to: "/about-us",
    },
    {
      text: "Blogs",
      to: ROUTES.BLOGS.MAIN,
    },
    {
      text: "Services",
      to: "/services",
    },
    {
      text: "Contact Us",
      to: "/contact-us",
    },
  ];
  const legalLinks = [
    {
      text: "Privacy Policy",
      to: "/privacy-policy",
    },
    {
      text: "Terms & Conditions",
      to: "/terms-and-conditions",
    },
  ];
  const socials = [
    {
      icon: <BsTwitter />,
      link: "https://twitter.com/",
    },
    {
      icon: <FaFacebookF />,
      link: "https://facebook.com/",
    },
    {
      icon: <FaLinkedinIn />,
      link: "https://www.linkedin.com/company/eazytaxes-com/",
    },
  ];
  const contactDetails = [
    {
      icon: <ImLocation />,
      heading: "Visit Us Daily",
      content: "3rd Avenue, Seattle 98121",
    },
    {
      icon: <IoCall />,
      heading: "Have Any Questions?",
      content: "+1 (206) 886 0475",
    },
    {
      icon: <RiSendPlaneFill />,
      heading: "Mail Us",
      content: "contact@eazytaxes.com",
    },
  ];
  return (
    <Container>
      <Content>
        <AboutWebsiteFooterSections>
          <AboutWebsite>
            <WebsiteLogo>
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo-white.svg`}
                alt="eazytaxes.com"
              />
            </WebsiteLogo>
            <AboutUsDesc>
              eazytaxes.com is the easiest way to file and save on taxes for salaried
              individuals, small business owners and freelancers in the US.
            </AboutUsDesc>
          </AboutWebsite>
          <FooterSections>
            <FooterSection>
              <FooterSectionHeading>company</FooterSectionHeading>
              <FooterSectionContent>
                <Links>
                  {companyLinks.map(({ to, text }, index) => (
                    <NavLink to={to} key={index}>
                      {text}
                    </NavLink>
                  ))}
                </Links>
              </FooterSectionContent>
            </FooterSection>
            <FooterSection>
              <FooterSectionHeading>legal</FooterSectionHeading>
              <FooterSectionContent>
                <Links>
                  {legalLinks.map(({ to, text }, index) => (
                    <NavLink to={to} key={index}>
                      {text}
                    </NavLink>
                  ))}
                </Links>
              </FooterSectionContent>
            </FooterSection>
            <FooterSection>
              <FooterSectionHeading>contact</FooterSectionHeading>
              <FooterSectionContent>
                <ContactDetails>
                  {contactDetails.map(({ icon, content }, index) => (
                    <ContactDetail key={index}>
                      <ContactDetailIcon>{icon}</ContactDetailIcon>
                      <ContactDetailContent>{content}</ContactDetailContent>
                    </ContactDetail>
                  ))}
                </ContactDetails>
              </FooterSectionContent>
            </FooterSection>
          </FooterSections>
        </AboutWebsiteFooterSections>
        <Copyright>
          <CopyrightContent>
            &copy; Copyright {new Date().getFullYear()} Eazytaxes. All Rights Reserved.
          </CopyrightContent>
          <Socials>
            {socials.map(({ icon, link }, index) => (
              <a href={link} key={index} target="_blank" rel="noreferrer">
                <Social>{icon}</Social>
              </a>
            ))}
          </Socials>
        </Copyright>
      </Content>
    </Container>
  );
};

export default Footer;
