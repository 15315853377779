import styled from "styled-components";

export const Container = styled.div`
  padding: 0 3rem;
  background: #061e2f;
`;

export const Content = styled.div`
  max-width: 120rem;
  margin: auto;
`;

export const AboutWebsiteFooterSections = styled.div`
  padding: 11rem 0;
  display: flex;
  gap: 3rem;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 700px) {
    padding: 6rem 0;
  }
`;

export const AboutWebsite = styled.div`
  max-width: 25rem;
  min-width: 25rem;
  width: 100%;
  @media screen and (max-width: 1000px) {
    max-width: fit-content;
  }
`;

export const WebsiteLogo = styled.div`
  margin-bottom: 2rem;
  & img {
    width: 12rem;
  }
`;

export const AboutUsDesc = styled.div`
  color: #afc1ce;
  margin-bottom: 2.8rem;
  font-weight: 400;
  line-height: 30px;
`;

export const FooterSections = styled.div`
  display: flex;
  flex: 1;
  gap: 4rem 2rem;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FooterSection = styled.div`
  color: #fff;
  flex: 1;
  min-width: 17rem;
`;

export const FooterSectionHeading = styled.div`
  margin-bottom: 2.8rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;
`;

export const FooterSectionContent = styled.div``;

export const Socials = styled.div`
  display: flex;
  gap: 1.4rem;
`;

export const Social = styled.div`
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 50%;
  background: #13364f;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
  font-size: 1.4rem;
  &:hover {
    background: #0d71ba;
  }
`;

export const HeadOfficeDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  gap: 2rem;
  color: #afc1ce;
  & a:hover {
    transition: color 0.5s;
    color: #fff;
  }
`;

export const Copyright = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #879dad;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.5rem 1rem;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`;

export const CopyrightContent = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 28px;
`;

export const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContactDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ContactDetailIcon = styled.div`
  color: #55bb53;
  font-size: 3rem;
  display: flex;
  align-items: center;
`;

export const ContactDetailContent = styled.div`
  word-break: break-all;
`;
