import { useFormByTaxReturnQuery, useTaxReturnQuery } from "Hooks/apiHooks";
import TaxReturnForm from "./TaxReturnForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "Components/uiComponents/LoadingSpinner";

const TaxReturn = () => {
  const { taxReturnId } = useParams();
  const { data, error, isLoading } = useTaxReturnQuery(taxReturnId);
  const {
    data: formData,
    error: formError,
    isLoading: isFormLoading,
    refetch: refetchForm
  } = useFormByTaxReturnQuery(taxReturnId);
  useEffect(() => {
    if (error) {
      throw new Error(error.message);
    }
    if (formError) {
      throw new Error(error.message);
    }
    if (formData && formData.length > 1) {
      throw new Error("Multiple forms not handled");
    }
  }, [error, formError, formData]);

  return isLoading || isFormLoading ? (
    <Loader message={"Loading Tax Return"} />
  ) : (
    <TaxReturnForm
      taxReturnId={taxReturnId}
      taxType={data.type}
      formId={formData[0]?.form_id}
      formData={formData[0]?.data ? JSON.parse(formData[0].data) : undefined}
      refetchForm={refetchForm}
    />
  );
};

export default TaxReturn;
