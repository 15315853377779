import React, { useEffect } from "react";
import DataSecurity from "Pages/Home/DataSecurity/DataSecurity";
import FAQs from "./FAQs/FAQs";
import Features from "./Features/Features";
import Hero from "./Hero/Hero";
import HowEazyTaxesDoIt from "./HowEazyTaxesDoIt/HowEazyTaxesDoIt";
import NumbersDontLie from "./NumbersDontLie/NumbersDontLie";
import ReadyToGetStarted from "./ReadyToGetStarted/ReadyToGetStarted";
import Reviews from "./Reviews/Reviews";
import ServicesForYou from "./ServicesForYou/ServicesForYou";
import OurClientsWorkAt from "./OurClientsWorkAt/OurClientsWorkAt";
import { Container } from "./styles";
import { Head } from "Components";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";

const Home = () => {
  const [searchParams, _] = useSearchParams();
  
  useEffect(() => {
    if(searchParams.get('shouldLogin') === 'true'){
      message.info("Please login to your account", 2000);
    }
  },[])

  return (
    <>
      <Head>
        <title>Home • Eazytaxes</title>
        <meta name="description" content="Home Page of Eazy Taxes"></meta>
      </Head>
      <Container>
        <Hero />
        <Features />
        <OurClientsWorkAt />
        <NumbersDontLie />
        <ServicesForYou />
        <HowEazyTaxesDoIt />
        <DataSecurity />
        <ReadyToGetStarted />
        <FAQs />
        <Reviews />
        {/* <ChatBot url="https://storage.googleapis.com/landbot.online/v3/H-1459341-DSUMBW1CVYI98FTQ/index.json" /> */}
      </Container>
    </>
  );
};

export default Home;
