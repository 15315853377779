import {
  useAllTaxReturnsQuery,
  useUpdateTaxReturnMutation,
  useUsersQuery,
} from "Hooks/apiHooks";
import { useState, useEffect } from "react";
import Loader from "Components/uiComponents/LoadingSpinner";
import { Table, Select, Button, message } from "antd";
import styled from "styled-components";
import { formatType } from "helpers";

const Heading = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2.4rem;
  font-weight: 600;
`;

const TableContainer = styled.div`
  width: 80%;
  @media screen and (max-width: 1300px) {
    width: 90%;
  }
  margin-top: 1rem;
`;

const AssignCPA = () => {
  const { data, error, isLoading } = useUsersQuery("User");
  const [isLoadingCpaIds, setIsLoadingCpaIds] = useState(false);
  const {
    data: dataCPA,
    error: errorCPA,
    isLoading: isLoadingCPA,
  } = useUsersQuery("CPA");

  const [userId, setUserId] = useState();
  const [taxYear, setTaxYear] = useState();

  const {
    data: dataTaxReturn,
    error: errorTaxReturn,
    isLoading: isLoadingTaxReturn,
    refetch: refetchTaxReturn,
  } = useAllTaxReturnsQuery(
    { year: taxYear, userId },
    Boolean(userId) || Boolean(taxYear)
  );

  const [cpaIds, setCpaIds] = useState([]);

  const updateTaxReturn = useUpdateTaxReturnMutation();

  const fetchUserList = (email) => {
    return data.filter((user) => user.email.includes(email));
  };
  const fetchCPAList = (email) => {
    return dataCPA.filter((user) => user.email.includes(email));
  };

  useEffect(() => {
    if (dataTaxReturn) {
      setCpaIds(Array(dataTaxReturn.length).fill(undefined));
      setIsLoadingCpaIds(false);
    }
  }, [dataTaxReturn]);

  useEffect(() => {
    if (isLoadingTaxReturn) {
      setIsLoadingCpaIds(true);
    }
  }, [isLoadingTaxReturn]);

  useEffect(() => {
    if (error) {
      throw error;
    }
    if (errorCPA) {
      throw errorCPA;
    }
    if (errorTaxReturn) {
      throw errorTaxReturn;
    }
  }, [error, errorCPA, errorTaxReturn]);

  return (
    <>
      <Heading>Assign CPA</Heading>
      {isLoading || isLoadingCPA || isLoadingTaxReturn || isLoadingCpaIds ? (
        <Loader message={"Loading"}></Loader>
      ) : (
        <>
          <div>By User</div>
          <Select
            style={{ width: "200px" }}
            showSearch
            filterOption={false}
            onSearch={fetchUserList}
            notFoundContent={null}
            value={userId}
            onChange={(value) => {
              setUserId(value);
              setTaxYear("");
            }}
            options={data.map((user) => ({ value: user.user_id, label: user.email }))}
          />
          <div>By Year</div>
          <Select
            placeholder="Select the financial year"
            style={{ width: "200px" }}
            value={taxYear}
            onChange={(value) => {
              setTaxYear(value);
              setUserId("");
            }}
          >
            <Select.Option value={"2021-2022"}>FY 2021-2022</Select.Option>
            <Select.Option value={"2022-2023"}>FY 2022-2023</Select.Option>
            <Select.Option value={"2023-2024"}>FY 2023-2024</Select.Option>
          </Select>
          <TableContainer>
            <Table dataSource={dataTaxReturn} pagination={false}>
              <Table.Column
                title={"Tax Type"}
                render={(record) => {
                  return formatType(record.type);
                }}
              />
              <Table.Column title={"Year"} dataIndex="year" />
              <Table.Column title={"User"} dataIndex="user_email" />
              <Table.Column
                title={"Assigned CPA"}
                key="cpa"
                render={(value) => {
                  return value.cpa ?? "No CPA Assigned";
                }}
              />
              <Table.Column
                title={"Choose CPA"}
                render={(record, _, index) => {
                  return (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Select
                        filterOption={false}
                        showSearch
                        style={{ width: "200px" }}
                        onSearch={fetchCPAList}
                        notFoundContent={null}
                        value={cpaIds[index]}
                        onChange={(value) => {
                          cpaIds[index] = value;
                          setCpaIds([...cpaIds]);
                        }}
                        options={dataCPA.map((user) => ({
                          value: user.user_id,
                          label: user.email,
                        }))}
                      />
                      {record.cpa ? (
                        <Button
                          onClick={async () => {
                            await updateTaxReturn.mutateAsync({
                              taxReturnId: record.tax_return_id,
                              taxReturnData: { cpa: null },
                            });
                            await refetchTaxReturn();
                            message.success("CPA Removed");
                          }}
                        >
                          Remove CPA
                        </Button>
                      ) : (
                        <></>
                      )}
                      <Button
                        onClick={async () => {
                          if (cpaIds[index]) {
                            await updateTaxReturn.mutateAsync({
                              taxReturnId: record.tax_return_id,
                              taxReturnData: { cpa: cpaIds[index] },
                            });
                            cpaIds[index] = undefined;
                            setCpaIds([...cpaIds]);
                            await refetchTaxReturn();
                            message.success("CPA Assigned");
                          } else {
                            message.info("No user chosen");
                          }
                        }}
                      >
                        Accept
                      </Button>
                    </div>
                  );
                }}
              />
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default AssignCPA;
