import React, { useState } from "react";
import { Button, Input, TextArea } from "Components";
import { Container, Content, Form, Heading, InputGroup } from "./styles";
// import { sendQuery } from "Api/contact.api.js";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import emailValidationSchema from "validations/emailValidationSchema";
import requiredValidationSchema from "validations/requiredValidationSchema";

const initialQueryDetailsState = {
  name: "",
  email: "",
  message: "",
};

const ContactForm = () => {
  const [queryDetails, setQueryDetails] = useState(initialQueryDetailsState);
  const [isEmailValid, setIsEmailValid] = useState(false);
  // const submitHandler = async () => {
  //   startApiCall();
  //   await sendQuery(queryDetails);
  //   successToast("Query submitted successfully! Our team would contact you soon!");
  //   setQueryDetails(initialQueryDetailsState);
  //   finishApiCall();
  // };
  return (
    <Container>
      <Content>
        <Heading>Have a Query? Leave a Message!</Heading>
        <Form>
          <InputGroup>
            <Input
              placeholder="Your Name"
              icon={<AiOutlineUser />}
              value={queryDetails.name}
              validationSchema={requiredValidationSchema("Name")}
              onValidation={(isValid) => setIsEmailValid(isValid)}
              onChange={({ target }) =>
                setQueryDetails({
                  ...queryDetails,
                  name: target.value,
                })
              }
            />
            <Input
              placeholder="Your Email"
              icon={<AiOutlineMail />}
              value={queryDetails.email}
              validationSchema={emailValidationSchema}
              onValidation={(isValid) => setIsEmailValid(isValid)}
              onChange={({ target }) =>
                setQueryDetails({
                  ...queryDetails,
                  email: target.value,
                })
              }
            />
          </InputGroup>
          <TextArea
            placeholder="Message..."
            style={{
              width: "100%",
              height: "12rem",
              resize: "vertical",
            }}
            value={queryDetails.message}
            validationSchema={requiredValidationSchema("Message")}
            onChange={({ target }) =>
              setQueryDetails({
                ...queryDetails,
                message: target.value,
              })
            }
          />
        </Form>
        <Button
          hoverBg="#f5f5f5"
          hoverColor="#0b2a41"
          padding="1.5rem 3rem"
          // onClick={submitHandler}
          isDisabled={
            !isEmailValid ||
            !queryDetails.email ||
            !queryDetails.message ||
            !queryDetails.name
          }
        >
          Send Message
        </Button>
      </Content>
    </Container>
  );
};

export default ContactForm;
