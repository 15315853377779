import { useEffect } from "react";
import Loader from "Components/uiComponents/LoadingSpinner";
import styled from "styled-components";
import { useLoginQuery } from "Hooks/apiHooks";
import ROUTES from "constants/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Auth0Callback = () => {
  const [searchParams, _] = useSearchParams();

  const { isFetched, error } = useLoginQuery(searchParams.get("error") !== undefined);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("error") === "access_denied") {
      navigate(ROUTES.ROOT);
      message.info("Please give this app access to your external auth provider");
      return;
    } else if (error || searchParams.get("error")) {
      if (error) throw new Error(error.message);
      if (searchParams.get("error")) throw new Error(searchParams.get("error"));
    } else if (isFetched) {
      navigate(ROUTES.DASHBOARD.HOME);
    }
  }, [searchParams.get("error"), error, isFetched]);
  return (
    <Container>
      <Loader message={"Loading Authentication"} />
    </Container>
  );
};

export default Auth0Callback;
