import React, { useEffect, useState } from "react";
import { Wrapper, Container, Content, Error, Icon, Label, Desc } from "./styles";

const Input = React.forwardRef(
  (
    {
      placeholder,
      type = "text",
      icon,
      button,
      value,
      onValidation,
      validationSchema,
      onChange,
      label,
      desc,
      style,
      ...props
    },
    ref
  ) => {
    const [inputFocused, setInputFocused] = useState(false);
    const [error, setError] = useState();
    const validate = async (value) => {
      if (validationSchema) {
        try {
          await validationSchema.validateAsync(value);
          onValidation && onValidation(true);
          setError(null);
        } catch (err) {
          onValidation && onValidation(false);
          setError(err.message);
        }
      }
    };
    const valueChanged = (e) => {
      onChange(e);
      validate(e.target.value);
    };
    useEffect(() => {
      if (value) {
        validate(value);
      }
    }, []);
    return (
      <Wrapper>
        {label && <Label>{label}</Label>}
        {desc && <Desc>{desc}</Desc>}
        <Container style={style} isValid={!Boolean(error)} inputFocused={inputFocused}>
          <Content
            ref={ref}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={valueChanged}
            onFocus={() => {
              props.onFocus && props.onFocus();
              setInputFocused(true);
            }}
            onBlur={() => {
              props.onBlur && props.onBlur();
              setInputFocused(false);
            }}
          />
          {button}
          {icon && <Icon>{icon}</Icon>}
        </Container>
        {error && <Error>{error}</Error>}
      </Wrapper>
    );
  }
);

export default Input;
