import React from "react";
import {
  MainBlogDateOpen,
  BlogContainer,
  BlogContent,
  BlogDate,
  BlogDesc,
  BlogImage,
  BlogAuthor,
  BlogTitle,
  BlogComment,
  BlogLoginMessage,
  BlogCommentLine,
  BlogCommentSmall,
} from "./styles";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { componentMap } from "../Content";
import { Head } from "Components";
import Loader from "Components/uiComponents/LoadingSpinner";
import { useBlogQuery } from "Hooks/apiHooks";

const Blog = () => {
  const { blogId } = useParams();
  const { data, isLoading, error } = useBlogQuery(blogId);

  const BlogComponent = componentMap[blogId];
  
  useEffect(() => {
    if(error){
      throw new Error(error.message)
    }
  },[error])

  return (  
    <>
      <Head>
        <title>Blog • Eazytaxes</title>
        <meta name="description" content="Blog page of Eazy Taxes"></meta>
      </Head>
      {!isLoading ? (
        <BlogContainer>
          <BlogImage src={data.image ?? '/assets/blog/jointorseparate_inf20137.jpg'} />
          <BlogContent>
            <BlogTitle>{data.title}</BlogTitle>
            <BlogDesc>{data.desc}</BlogDesc>
            <MainBlogDateOpen>
              <BlogAuthor>
                Written By <span style={{ fontWeight: 1000 }}>{data.author}</span>
              </BlogAuthor>
              <BlogDate>On {new Date(data.created_at).toLocaleDateString()}</BlogDate>
            </MainBlogDateOpen>
            {BlogComponent ? <BlogComponent /> : <div dangerouslySetInnerHTML={{__html: data.content }} />}
            <BlogComment>
              Comments <BlogCommentLine />
            </BlogComment>
            <BlogContent>No Comments Added...</BlogContent>
            <BlogCommentSmall>
              Leave a Comment <BlogCommentLine />
            </BlogCommentSmall>
            <BlogContent>
              <BlogLoginMessage>
                You must be logged in to comment.{" "}
                <Link to={"/login"} style={{ color: "green" }}>
                  Login
                </Link>
              </BlogLoginMessage>
            </BlogContent>
          </BlogContent>
        </BlogContainer>
      ) : (
        <Loader message={"Loading Blog"} />
      )}
    </>
  );
};

export default Blog;
