import React from "react";
import { AiOutlineAudit, AiOutlineCloudUpload } from "react-icons/ai";
import { FaFileInvoice } from "react-icons/fa";
import { ImLoop2 } from "react-icons/im";
import { IoChatbubbles } from "react-icons/io5";
import { RiRefund2Fill } from "react-icons/ri";
import {
  Card,
  CardDesc,
  CardHeading,
  CardIcon,
  Container,
  Content,
  Heading,
} from "./styles";

const HowEazyTaxesDoIt = () => {
  const howEazyTaxesDoItCards = [
    // {
    //     icon: "https://cdn-icons-png.flaticon.com/512/4308/4308094.png",
    //     heading: "Personalized service",
    //     desc: "At eazytaxes.com, we offer the personalized service of a CPA firm, with the convenience and affordability of a DIY software.",
    // },
    // {
    //     icon: "https://cdn-icons-png.flaticon.com/512/5625/5625642.png",
    //     heading: "Comprehensive coverage",
    //     desc: "Whether you're a salaried employee, a small business owner, or have multiple sources of income, we have got you covered.",
    // },
    {
      icon: "https://cdn-icons-png.flaticon.com/512/2593/2593668.png",
      heading: "real-time suggestions",
      desc: "Everytime you are filing your income tax return with eazytaxes.com, our team offers you real-time suggestions for the best possible outcomes.",
    },
    {
      icon: "https://cdn-icons-png.flaticon.com/512/487/487235.png",
      heading: "Easy to use",
      desc: "Our user-friendly interface and step-by-step guidance make filing online straightforward. We'll do the rest after you upload your documents.",
    },
    // {
    //     icon: "https://cdn-icons-png.flaticon.com/512/2057/2057791.png",
    //     heading: "always there to help",
    //     desc: "At eazytaxes.com you get 24/7 global customer support to answer any query that you may have.",
    // },
    {
      icon: "https://cdn-icons-png.flaticon.com/512/2617/2617880.png",
      heading: "satisfaction is our priority",
      desc: "We have a 60 days money back policy which you can use if you are not satisfied with our services for any reason.",
    },
  ];
  return (
    <Container>
      <Heading>
        How <span>eazytaxes.com</span> stands out from the rest?
      </Heading>
      <Content>
        {howEazyTaxesDoItCards.map(({ icon, heading, desc }, index) => (
          <Card key={index}>
            <CardIcon src={icon} />
            <CardHeading>{heading}</CardHeading>
            <CardDesc>{desc}</CardDesc>
          </Card>
        ))}
      </Content>
    </Container>
  );
};

export default HowEazyTaxesDoIt;
