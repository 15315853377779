import {
  ServiceBody,
  ColumnContent,
  ImageBanner,
  Container,
  Row,
  HeaderContainer,
  ColumnTabs,
  ServicesContainer,
} from "./styles";

import styled from "styled-components";
import OurClientsWorkAt from "Pages/Home/OurClientsWorkAt/OurClientsWorkAt";
import ServiceCard from "./Components/ServiceCard";
import CallBack from "./Components/CallBack";

import { useState } from "react";

const Header1 = styled.div`
  text-transform: capitalize;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 18px;
`;

const Span = styled.span`
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  display: block;
`;

const Service = ({ tabs, content, type }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  console.log(activeTabIndex)
  return (
    <ServiceBody>
      <ImageBanner>
        <Container>
          <Row>
            <HeaderContainer>
              <Header1>Our Services</Header1>
              <Span>We are over 20 years of experience</Span>
            </HeaderContainer>
          </Row>
        </Container>
      </ImageBanner>
      <ServicesContainer>
        <Container>
          <Row>
            <ColumnTabs>
              {tabs.map((tab, index) => (
                <ServiceCard
                  key={index}
                  text={tab.text}
                  link={tab.link}
                  isActive={index === activeTabIndex}
                  onClick={() => {
                    console.log(index)
                    setActiveTabIndex(index);
                  }}
                />
              ))}
            </ColumnTabs>
            <ColumnContent>{content[activeTabIndex]}</ColumnContent>
          </Row>
        </Container>
      </ServicesContainer>
      <CallBack />
      <OurClientsWorkAt hideReadWhatTheyHaveToSay />
    </ServiceBody>
  );
};

export default Service;
