import { YES_NO, MARITAL_STATUS_OPTIONS, USA_STATES_OPTIONS } from "./constants";

export const questions = {
  individual: {
    form: [
      {
        type: "radio",
        label: "Did you stay in more than one state in 2022?",
        name: "multipleStates",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "select",
        label: "Select your resident State",
        name: "residentState",
        options: [...USA_STATES_OPTIONS],
        rules: [{ required: true, message: "Please select your resident state" }],
      },
      {
        type: "conditional",
        condType: "select",
        label: "Select your second resident State",
        name: "secondResidentState",
        dependency: "multipleStates",
        values: ["yes"],
        options: [...USA_STATES_OPTIONS],
        rules: [{ message: "Please select your second resident state" }],
      },
      {
        type: "radio",
        label: "Marital Status",
        name: "maritalStatus",
        options: [...MARITAL_STATUS_OPTIONS],
        rules: [{ required: true, message: "Please select your marital status" }],
      },
      {
        type: "conditional",
        condType: "select",
        label: "How are you filing your income tax returns?",
        name: "incomeTaxFiling",
        dependency: "maritalStatus",
        values: ["married"],
        options: [
          { label: "Married Filing Singly", value: "filing-singly" },
          { label: "Married Filing Jointly", value: "filing-jointly" },
        ],
        rules: [{ message: "Please select your income tax filing option" }],
      },
      {
        type: "input",
        label: "Number of Dependents",
        name: "dependents",
        inputType: "number",
        inputProps: { max: 4, min: 0 },
        rules: [{ required: true, message: "Please enter the number of dependents" }],
      },
      {
        type: "radio",
        label: "Do you have Children?",
        name: "haveChildren",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "conditional",
        condType: "input",
        label: "Number of Children",
        name: "numberOfChildren",
        dependency: "haveChildren",
        values: ["yes"],
        inputType: "number",
        rules: [{ message: "Please enter the number of children" }],
      },
      {
        type: "radio",
        label: "Have you received Advance Child Tax Credit in 2022?",
        name: "advanceChildTaxCredit",
        dependency: "haveChildren",
        options: [...YES_NO],
        rules: [{ message: "Please select an option" }],
      },
      {
        type: "conditional",
        condType: "input",
        label: "Specify the amount of Advance Child Tax Credit received",
        name: "advanceChildTaxCreditAmount",
        dependency: "advanceChildTaxCredit",
        values: ["yes"],
        inputType: "number",
        rules: [{ message: "Please specify the amount" }],
      },
      {
        type: "radio",
        label: "Do you have Health Insurance Coverage for Tax Year 2022?",
        name: "healthInsuranceCoverage",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "attachment",
        label: "Please upload your passport",
        name: "passportAttachment",
        rules: [
          { required: true, message: "Please upload your passport", warningOnly: true },
        ],
      },
      {
        type: "attachment",
        label: "Please upload your I94 (Travel History)",
        name: "i94Attachment",
        rules: [
          {
            required: true,
            message: "Please upload your I94 (Travel History)",
            warningOnly: true,
          },
        ],
      },
      {
        type: "radio",
        label: "Did you file your ITR in the USA before?",
        name: "filedITRBefore",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "tableInput",
        label: "Individual Information",
        name: "individualInfo",
        columns: [{ title: "Tax Payer", name: "taxPayer" }],
        conditionalColumns: [
          {
            title: "Spouse",
            dependency: "maritalStatus",
            values: ["Married"],
            name: "spouse",
          },
        ],
        multiplierColumns: [
          { title: "Dependent", dependency: "dependents", name: "dependent" },
        ],
        rows: [
          {
            type: "input",
            label: "First Name",
            name: "firstName",
            rules: [{ required: true, message: "Please enter your first name" }],
          },
          {
            type: "input",
            label: "Last Name",
            name: "lastName",
            rules: [{ required: true, message: "Please enter your last name" }],
          },
          {
            type: "input",
            label: "Date of Birth",
            name: "dob",
            inputType: "date",
            rules: [{ required: true, message: "Please select your date of birth" }],
          },
          {
            type: "input",
            label: "Social Security Number (SSN)",
            name: "ssn",
            rules: [{ required: true, message: "Please enter your SSN" }],
          },
          {
            type: "select",
            label: "Relationship",
            name: "relationship",
            options: [
              { label: "Self", value: "self" },
              { label: "Spouse", value: "spouse" },
              { label: "Child", value: "child" },
              { label: "Other", value: "other" },
            ],
            rules: [{ required: true, message: "Please select your relationship" }],
          },
          {
            type: "select",
            label: "Visa Status",
            name: "visaStatus",
            options: [
              { label: "H1B", value: "h1b" },
              { label: "L1", value: "l1" },
              { label: "F1", value: "f1" },
              { label: "Other", value: "other" },
            ],
            rules: [{ required: true, message: "Please select your visa status" }],
          },
          {
            type: "input",
            label: "Address",
            name: "address",
            rules: [{ required: true, message: "Please enter your address" }],
          },
        ],
      },
      {
        type: "radio",
        label: "Have you resided in more than one state in the past three tax years? ",
        name: "threeYearsStateResidence",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "input",
        inputType: "number",
        label: "Amount of Rent Paid (Please enter 0 if no rent paid during the year)",
        name: "rentPaid",
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "radio",
        label: "Did you incur any medical expenses?",
        name: "medicalExpense",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "conditional",
        condType: "tableInput",
        label: "Medical Expenses",
        dependency: "medicalExpense",
        values: ["yes"],
        name: "individualInfo",
        columns: [{ title: "Amount Paid (in $)", name: "amountPaid" }],
        rows: [
          {
            type: "input",
            label: "Prescription Medications",
            name: "prescriptionMedications",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your expense" }],
          },
          {
            type: "input",
            label: "Health Insurance Premiums",
            name: "healthInsurancePremiums",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your expense" }],
          },
          {
            type: "input",
            label: "Doctors, Dentists, etc.",
            name: "doctorDentist",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your expense" }],
          },
          {
            type: "input",
            label: "Eyeglasses and Contact Lenses",
            name: "eyeglassesContactLenses",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your expense" }],
          },
          {
            type: "input",
            label: "Material Expenses, if any",
            name: "relmaterialExpense",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your expense" }],
          },
        ],
      },
      {
        type: "radio",
        label: "Did you pay any property taxes?",
        name: "propertyTaxes",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "conditional",
        condType: "tableInput",
        label: "Details of Taxes Paid",
        dependency: "propertyTaxes",
        values: ["yes"],
        name: "taxesPaid",
        columns: [{ title: "Amount Paid (in $)", name: "amountPaid" }],
        rows: [
          {
            type: "input",
            label: "Real Estate Taxes",
            name: "realEstateTax",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
          {
            type: "input",
            label: "State and Local Personal Property Taxes",
            name: "stateAndLocalTax",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
          {
            type: "input",
            label: "Other Taxes, if any",
            name: "otherTax",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
          {
            type: "input",
            label: "Additional State Taxes paid while filing last year tax",
            name: "lastYearTax",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
          {
            type: "input",
            label: "Material Expenses, if any",
            name: "materialExpense",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
        ],
      },
      {
        type: "radio",
        label: "Did you pay any Home Mortgage Interest?",
        name: "mortgageInterest",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "conditional",
        condType: "tableInput",
        label: "Home Mortgage Interest Paid",
        dependency: "mortgageInterest",
        values: ["yes"],
        name: "homeMortgageInterest",
        columns: [{ title: "Amount Paid (in $)", name: "amountPaid" }],
        rows: [
          {
            type: "input",
            label: "Home Mortgage Interest paid in USA - Upload Form 1098",
            name: "homeMortgageInterestForm1098",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
          {
            type: "input",
            label: "Points, if any",
            name: "points",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
          {
            type: "blank",
            label:
              "If Home Mortgage Interest paid in Resident Country (Eg.India) - Below details required",
          },
          {
            type: "input",
            label: "a. Bank Name (Foreign)",
            name: "bankName",
            rules: [{ required: true, message: "Please enter the bank name" }],
          },
          {
            type: "input",
            label: "b. Bank Address (Foreign)",
            name: "bankAddress",
            rules: [{ required: true, message: "Please enter the bank address" }],
          },
          {
            type: "input",
            label: "Mortgage Insurance Premiums paid, if any",
            name: "mortgageInsurancePremium",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
          {
            type: "input",
            label: "Investment Interest, attach Form 495",
            name: "mortgageInsurancePremium",
            inputType: "number",
            rules: [{ required: true, message: "Please enter your tax" }],
          },
        ],
      },
      {
        type: "radio",
        label: "Did you have any rental income?",
        name: "rentalIncome",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "radio",
        label: "Did you do any Charitable Contributions?",
        name: "charitableContrib",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "radio",
        label: "Did you sell any shares/securities/cryptocurrency?",
        name: "cryptoCurrency",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "tableInput",
        label:
          "Other Deductions Adjustments To Income. Please fill details wherever applicable",
        name: "otherDeductions",
        columns: [{ title: "Tax Payer", name: "taxPayer" }],
        conditionalColumns: [
          {
            title: "Spouse",
            dependency: "maritalStatus",
            values: ["Married"],
            name: "spouse",
          },
        ],
        multiplierColumns: [
          { title: "Dependent", dependency: "dependents", name: "dependent" },
        ],
        rows: [
          {
            type: "input",
            label: "Health savings account Contribution",
            name: "healthSavings",
            inputType: "number",
          },
          {
            type: "input",
            label: "Penalty on early withdrawal of saving",
            name: "earlyWithdrawal",
            inputType: "number",
          },
          {
            type: "input",
            label: "Contribution towards Traditional IRA for 2022",
            name: "traditialIRAContrib",
            inputType: "number",
          },
          {
            type: "input",
            label: "Student loan interest deduction - Provide Form 1098 E",
            name: "studentLoanInterestDeduction",
            inputType: "number",
          },
          {
            type: "input",
            label: "Tuition & Fees - Provide Form 1098 T",
            name: "tuitionFees",
            inputType: "number",
          },
          {
            type: "input",
            label: "Gambling losses",
            name: "gamblingLosses",
            inputType: "number",
          },
        ],
      },
      {
        type: "tableInput",
        label: "Please check if you have the below mentioned documents.",
        name: "belowDocuments",
        columns: [{ title: "Check here", name: "checkHere" }],
        rows: [
          {
            type: "check",
            label: "Paystub",
            name: "paystub",
          },
          {
            type: "check",
            label: "Wage Income - Form W2 / Corrected W2 (Mandatory)",
            name: "formW2",
          },
          {
            type: "check",
            label: "Interest Income - Form 1099-INT",
            name: "form1099Int",
          },
          {
            type: "check",
            label: "Dividend Income - Form 1099-DIV",
            name: "form1099Div",
          },
          {
            type: "check",
            label: "State Tax Refund - Form 1099-G",
            name: "form1099G",
          },
          {
            type: "check",
            label: "Self - Employment Income / Business Income - Form 1099-MISC",
            name: "form1099Misc",
          },
          {
            type: "check",
            label: "Purchase & Sale of Shares / Securities - Form 1099-B",
            name: "form1099B",
          },
          {
            type: "check",
            label: "Retirement Distributions - Form 1099-R",
            name: "form1099R",
          },
          {
            type: "check",
            label: "Income from S-Corp / LLP / LLC - Schedule K1",
            name: "scheduleK1",
          },
          {
            type: "check",
            label: "Rental Income from US Property",
            name: "rentalIncome",
          },
          {
            type: "check",
            label: "Foreign Tax Certificate - (Foreign Income)",
            name: "foreignTaxCertificate",
          },
          {
            type: "check",
            label: "Student Loan Interest - Form 1098-E",
            name: "form1098E",
          },
          {
            type: "check",
            label: "Home Mortgage Interest - Form 1098",
            name: "form1098",
          },
          {
            type: "check",
            label: "Prior Year Federal & State Tax Return (if New Client)(Mandatory)",
            name: "priorYearFederal",
          },
          {
            type: "check",
            label: "Form 1042's",
            name: "form1042",
          },
          {
            type: "check",
            label: "Form 1095 (A/B/C)",
            name: "form1095ABC",
          },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-paystub",
        values: [true],
        label: "Please upload document - Paystub",
        name: "paystubDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-formW2",
        values: [true],
        label: "Wage Income - Form W2 / Corrected W2 (Mandatory",
        name: "formW2Document",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1099Int",
        values: [true],
        label: "Interest Income - Form 1099-INT",
        name: "form1099IntDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1099Div",
        values: [true],
        label: "Dividend Income - Form 1099-DIV",
        name: "form1099DivDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1099G",
        values: [true],
        label: "State Tax Refund - Form 1099-G",
        name: "form1099GDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1099Misc",
        values: [true],
        label: "Self - Employment Income / Business Income - Form 1099-MISC",
        name: "form1099MiscDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1099B",
        values: [true],
        label: "Purchase & Sale of Shares / Securities - Form 1099-B",
        name: "form1099BDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1099R",
        values: [true],
        label: "Retirement Distributions - Form 1099-R",
        name: "form1099RDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-scheduleK1",
        values: [true],
        label: "Income from S-Corp / LLP / LLC - Schedule K1",
        name: "scheduleK1Document",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-rentalIncome",
        values: [true],
        label: "Rental Income from US Property",
        name: "rentalIncomeDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-foreignTaxCertificate",
        values: [true],
        label: "Foreign Tax Certificate - (Foreign Income)",
        name: "foreignTaxCertificate",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1098E",
        values: [true],
        label: "Student Loan Interest - Form 1098-E",
        name: "form1098E",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1098",
        values: [true],
        label: "Home Mortgage Interest - Form 1098",
        name: "form1098Document",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-priorYearFederal",
        values: [true],
        label: "Prior Year Federal & State Tax Return (if New Client)(Mandatory)",
        name: "priorYearFederalDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1042",
        values: [true],
        label: "Form 1042's",
        name: "form1042Document",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "conditional",
        condType: "attachment",
        dependency: "belowDocuments-checkHere-form1095ABC",
        values: [true],
        label: "Form 1095 (A/B/C)",
        name: "form1095ABCDocument",
        rules: [
          { required: true, message: "Please upload your document", warningOnly: true },
        ],
      },
      {
        type: "tableInput",
        label: "Bank Information (Needed For Direct Deposit of Refund)",
        name: "directDeposit",
        columns: [{ title: "Details", name: "details" }],
        rows: [
          {
            type: "input",
            label: "Bank Name",
            name: "bankName",
            rules: [{ required: true, message: "Please enter your bank name" }],
          },
          {
            type: "input",
            inputType: "number",
            label: "Routing Number (Should be Electronic)",
            name: "routingNumber",
            rules: [{ required: true, message: "Please enter your routing number" }],
          },
          {
            type: "input",
            inputType: "number",
            label: "Account Number",
            name: "accountNumber",
            rules: [{ required: true, message: "Please enter your account number" }],
          },
          {
            type: "input",
            label: "Account Type (Savings / Checking)",
            name: "lastYearTax",
            inputType: "select",
            options: [
              { label: "Checking", value: "checking" },
              { label: "Savings", value: "savings" },
            ],
            rules: [{ required: true, message: "Please enter your account type" }],
          },
          {
            type: "input",
            label: "Account Owner Name",
            name: "accountOwnerName",
            rules: [{ required: true, message: "Please enter your name" }],
          },
        ],
      },
      {
        type: "attachment",
        label: "Please upload account details",
        name: "accountInfo",
        rules: [{ required: true, message: "Please upload your account information" }],
      },
    ],
  },
  individualLite: {
    form: [
      {
        type: "radio",
        label: "Did you stay in more than one state in 2022?",
        name: "multipleStates",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "select",
        label: "Select your resident State",
        name: "residentState",
        options: [...USA_STATES_OPTIONS],
        rules: [{ required: true, message: "Please select your resident state" }],
      },
      {
        type: "attachment",
        label: "Please upload passport info",
        name: "passportInfo",
        rules: [{ required: true, message: "Please upload your passport details" }],
      },
      {
        type: "conditional",
        condType: "select",
        label: "Select your second resident State",
        name: "secondResidentState",
        dependency: "multipleStates",
        values: ["yes"],
        options: [...USA_STATES_OPTIONS],
        rules: [{ message: "Please select your second resident state" }],
      },
      {
        type: "radio",
        label: "Marital Status",
        name: "maritalStatus",
        options: [...MARITAL_STATUS_OPTIONS],
        rules: [{ required: true, message: "Please select your marital status" }],
      },
      {
        type: "attachment",
        label: "Please upload account details",
        name: "accountInfo",
        rules: [{ required: true, message: "Please upload your account information" }],
      },
      {
        type: "tableInput",
        label: "Bank Information (Needed For Direct Deposit of Refund)",
        name: "directDeposit",
        columns: [{ title: "Details", name: "details" }],
        rows: [
          {
            type: "input",
            label: "Bank Name",
            name: "bankName",
            rules: [{ required: true, message: "Please enter your bank name" }],
          },
          {
            type: "input",
            inputType: "number",
            label: "Routing Number (Should be Electronic)",
            name: "routingNumber",
            rules: [{ required: true, message: "Please enter your routing number" }],
          },
          {
            type: "input",
            inputType: "number",
            label: "Account Number",
            name: "accountNumber",
            rules: [{ required: true, message: "Please enter your account number" }],
          },
          {
            type: "input",
            label: "Account Type (Savings / Checking)",
            name: "lastYearTax",
            inputType: "select",
            options: [
              { label: "Checking", value: "checking" },
              { label: "Savings", value: "savings" },
            ],
            rules: [{ required: true, message: "Please enter your account type" }],
          },
          {
            type: "input",
            label: "Account Owner Name",
            name: "accountOwnerName",
            rules: [{ required: true, message: "Please enter your name" }],
          },
        ],
      },
    ],
  },
  business: [],
  sCorp: [],
  cCorp: [],
  form1040: {
    introText: "",
    form: [
      {
        type: "attachment",
        label: "Previously filed ITRs",
        name: "prevITRUpload",
        rules: [{ required: true, message: "Please upload your filed ITRs" }],
      },
      {
        type: "attachment",
        label: "Social Security numbers of everyone listed on the tax return",
        name: "ssnEveryoneUpload",
        rules: [{ required: true, message: "Please upload your ssn information" }],
      },
      {
        type: "attachment",
        label: "Bank account statement",
        name: "bankAccStatementUpload",
        rules: [{ required: true, message: "Please upload your bank account statement" }],
      },
      {
        type: "attachment",
        label: "Forms W-2 from each employer within the year",
        name: "formsW2Upload",
        rules: [{ required: true, message: "Please upload your forms w2 of the year" }],
      },
      {
        type: "attachment",
        label:
          "Forms 1099 from banks, issuing agencies and other payers including unemployment compensation, dividends, distributions from a pension, annuity, or retirement plan.",
        name: "forms1099Upload",
        rules: [{ required: true, message: "Please upload your Forms 1099" }],
      },
      {
        type: "attachment",
        label: "Form 1099-INT for interest received.",
        name: "form1099IntUpload",
        rules: [{ required: true, message: "Please upload your Forms 1099 INT" }],
      },
      {
        type: "attachment",
        label:
          "Form 1099-K, 1099-MISC, W-2 or other income statement for workers in the gig economy.",
        name: "form1099KUpload",
        rules: [
          {
            required: true,
            message: "Please upload your Forms 1099 related to the gig economy",
          },
        ],
      },
      {
        type: "attachment",
        label: "Form 1095-A, Health Insurance Marketplace Statement",
        name: "form1095AUpload",
        rules: [{ required: true, message: "Please upload your Form 1095-A" }],
      },
      {
        type: "radio",
        label: "Are you a US citizen?",
        name: "areYouAUSCitizen",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "conditional",
        condType: "input",
        label: "If no, mention the country of citizenship.",
        name: "citizenshipCountry",
        dependency: "areYouAUSCitizen",
        values: ["no"],
        inputType: "text",
        rules: [{ required: true, message: "Please specify citizenship" }],
      },
      {
        type: "radio",
        label: "Have you previously filed an ITR?",
        name: "prevFiledITR",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        type: "tableInput",
        label: "Individual Information",
        name: "individualInfo",
        columns: [{ title: "Tax Payer", name: "taxPayer" }],
        // conditionalColumns: [
        //   {
        //     title: "Spouse",
        //     dependency: "maritalStatus",
        //     values: ["Married"],
        //     name: "spouse",
        //   },
        // ],
        // multiplierColumns: [
        //   { title: "Dependent", dependency: "dependents", name: "dependent" },
        // ],
        rows: [
          {
            type: "input",
            label: "First Name",
            name: "firstName",
            rules: [{ required: true, message: "Please enter your first name" }],
          },
          {
            type: "input",
            label: "Last Name",
            name: "lastName",
            rules: [{ required: true, message: "Please enter your last name" }],
          },
          {
            type: "input",
            label: "Date of Birth",
            name: "dob",
            inputType: "date",
            rules: [{ required: true, message: "Please select your date of birth" }],
          },
          {
            type: "input",
            label: "Social Security Number (SSN)",
            name: "ssn",
            rules: [{ required: true, message: "Please enter your SSN" }],
          },
          {
            type: "select",
            label: "Relationship",
            name: "relationship",
            options: [
              { label: "Self", value: "self" },
              { label: "Spouse", value: "spouse" },
              { label: "Child", value: "child" },
              { label: "Other", value: "other" },
            ],
            rules: [{ required: true, message: "Please select your relationship" }],
          },
          {
            type: "select",
            label: "Visa Status",
            name: "visaStatus",
            options: [
              { label: "H1B", value: "h1b" },
              { label: "L1", value: "l1" },
              { label: "F1", value: "f1" },
              { label: "Other", value: "other" },
            ],
            rules: [{ required: true, message: "Please select your visa status" }],
          },
          {
            type: "input",
            label: "Address",
            name: "address",
            rules: [{ required: true, message: "Please enter your address" }],
          },
        ],
      },
      {
        type: "radio",
        label: "Are you a US Tax Resident?",
        name: "usTaxResident",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
        helpText: "",
      },
      {
        type: "conditional",
        condType: "radio",
        label: "If no, are you earning any income from sources originating within USA?",
        name: "incomeOriginUSA",
        dependency: "usTaxResident",
        values: ["no"],
        options: [...YES_NO],
        rules: [{ required: true, message: "Please specify citizenship" }],
        helpText: "",
      },
      {
        type: "radio",
        label: "Are you an OPT/CPT program participant?",
        name: "otpCpt",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please select an option" }],
        helpText: "",
      },
      {
        type: "radio",
        label:
          "As a US citizen/tax resident/green card holder, do you have sources of income from countries other than USA?",
        name: "otherCountries",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please specify yes or no" }],
        helpText: "",
      },
      {
        type: "conditional",
        condType: "tableInput",
        label: "List of Foreign Income",
        dependency: "otherCountries",
        values: ["yes"],
        name: "foriegnIncome",
        columns: [
          { title: "Income 1", name: "income1" },
          { title: "Income 2", name: "income2" },
          { title: "Income 3", name: "income3" },
        ], // Multiplier Columns
        rows: [
          {
            type: "input",
            label: "Nature of Income",
            name: "natureOfIncome",
            inputType: "text",
            rules: [{ required: true, message: "Please enter your income type" }],
          },
          {
            type: "input",
            label: "Country",
            name: "country",
            inputType: "text",
            rules: [{ required: true, message: "Please enter your country" }],
          },
          {
            type: "radio",
            label: "TDS Deducted",
            name: "tdsDeducted",
            options: [...YES_NO],
            rules: [{ required: true, message: "Please check if tds was deducted" }],
          },
        ],
      },
      {
        type: "radio",
        label: "Do you any interest in a foreign corporation?",
        name: "interestForeignCorp",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please specify yes or no" }],
        helpText: "",
      },
      {
        type: "conditional",
        condType: "input",
        inputType: "number",
        label: "Percentage of Ownership in Corporation",
        dependency: "interestForeignCorp",
        values: ["yes"],
        name: "percentForeignCorp",
        helpText: "",
      },
      {
        type: "conditional",
        condType: "input",
        inputType: "number",
        label: "Have you filled form 5471?",
        dependency: "interestForeignCorp",
        values: ["yes"],
        name: "filledForm5471",
        options: [...YES_NO],
        helpText: "",
      },
      {
        type: "conditional",
        condType: "attachment",
        label: "Upload form 5471",
        values: ["yes"],
        dependency: "filledForm5471",
        name: "upload5471",
        options: [...YES_NO],
        helpText: "",
      },
      {
        type: "conditional",
        condType: "attachment",
        label: "Income statement and balance sheet of the corporation",
        values: ["no"],
        dependency: "filledForm5471",
        name: "uploadIncomeBalanceSheet",
        options: [...YES_NO],
        helpText: "",
      },
      {
        type: "radio",
        label:
          "Do you want to make the first-year choice of to be treated as a US resident for part of the year of arrival ?",
        name: "firstYearUsResident",
        options: [...YES_NO],
        rules: [{ required: true, message: "Please specify yes or no" }],
        helpText: "",
      },
      {
        type: "tableInput",
        label:
          "Form 3520-Reporting Foreign Trusts, Inheritances, and Gifts for Americans Abroad",
        name: "form3520",
        columns: [{ title: "Answer", name: "answer" }],
        rows: [
          {
            type: "radio",
            label: "Are you a US person and an owner of a foreign trust ?",
            name: "areYouUSOwner",
            options: [...YES_NO],
            rules: [{ required: true, message: "Please select yes or no." }],
          },
          {
            type: "radio",
            label:
              "Have you received direct or indirect distributions from a foreign trust ?",
            name: "directOrIndirect",
            options: [...YES_NO],
            rules: [{ required: true, message: "Please select yes or no." }],
          },
          {
            type: "radio",
            label:
              "Have you transferred any assets (money and property, for example) to a foreign trust ?",
            name: "transferredAssets",
            options: [...YES_NO],
            rules: [{ required: true, message: "Please select yes or no." }],
          },
          // {
          //   condType: "radio",
          //   type: "conditional",
          //   dependency: "areYouUSOwner",
          //   label: "If yes to any of the questions above, have you filed form 3520 ?",
          //   name: "filedForm3520", // change this to accept an array with default some condition
          //   options: [...YES_NO],
          //   values: ["yes"],
          //   rules: [{ required: true, message: "Please select yes or no." }],
          // },
          {
            type: "select",
            label: "Please choose the filing status ?",
            name: "filingStatus", // change this to accept an array with default some condition
            options: [
              { label: "Single", value: "single" },
              { label: "Married filing jointly", value: "marriedFilingJointly" },
              { label: "Married filing separately", value: "marriedFilingSep" },
              { label: "Head of household", value: "headOfHousehold" },
              { label: "Qualifying widower", value: "qualifyingWidower" },
            ],
            rules: [{ required: true, message: "Please select your filing status" }],
          },
        ],
      },
      {
        mainHeadingText: "Wages or Salary",
        type: "select",
        label: "Where did you live when you earned your income?",
        name: "liveLocation",
        options: [
          { label: "Outside US", value: "outsideUS" },
          { label: "In US", value: "inUS" },
          { label: "Combination of Both", value: "combBoth" },
        ],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        type: "conditional",
        condType: "input",
        inputType: "number",
        label: "Time spent abroad",
        values: ["combBoth"],
        dependency: "liveLocation",
        name: "timeSpentAbroad",
        helpText: "",
      },
      {
        mainHeadingText: "Wages or Salary",
        type: "conditional",
        condType: "input",
        inputType: "text",
        label: "Name of foreign country",
        values: ["combBoth"],
        dependency: "liveLocation",
        name: "foreignCountry",
        helpText: "",
      },
      {
        mainHeadingText: "Wages or Salary",
        type: "select",
        label: "How was your income on your job reported?",
        name: "incomeJobReport",
        options: [
          { label: "On a form W-2", value: "formW2" },
          { label: "Cash", value: "cash" },
          { label: "Combination of Both", value: "combBoth" },
        ],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        type: "input",
        inputType: "text",
        label: "Name of your employer: Box C",
        name: "employerName",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        type: "input",
        inputType: "text",
        label: "Employer EIN: Box B",
        name: "employerEIN",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        headingText: "Employer’s address:  Box C",
        type: "input",
        inputType: "text",
        label: "House number and street address:",
        name: "houseNumberStreetAddress",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        headingText: "Employer’s address:  Box C",
        type: "input",
        inputType: "text",
        label: "City:",
        name: "city",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        headingText: "Employer’s address:  Box C",
        type: "input",
        inputType: "text",
        label: "State:",
        name: "state",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        headingText: "Employer’s address:  Box C",
        type: "input",
        inputType: "text",
        label: "Zip Code:",
        name: "zipCode",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Income earned from wages (In USD): (Box 1)",
        name: "incomeWages",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Wages or Salary",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Federal tax withheld by the employer (In USD): (Box 2)",
        name: "federalTaxWitheld",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Federal tax withheld by the employer (In USD): (Box 2)",
        name: "federalTaxWitheld",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "USA social security benefits",
        type: "input",
        inputType: "number",
        label: "Amount of social security benefits received (Box 3):",
        name: "socialSecurityBenefits",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "USA social security benefits",
        type: "radio",
        options: [...YES_NO],
        label: "Federal taxes withheld: (In box 7 of form SSA-1099)",
        name: "federaltaxesWitheld",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "USA social security benefits",
        type: "conditional",
        condType: "input",
        dependency: "federaltaxesWitheld",
        values: ["yes"],
        inputType: "number",
        label: "If yes, amount:",
        name: "socialSecurityBenefits",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "text",
        label: "Name of the country your social security program is located in:",
        name: "nameCountrySSP",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "text",
        label: "Currency :",
        name: "currency",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "number",
        label: "Amount of benefits received :",
        name: "benefits",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "text",
        label: "House number and street address:",
        name: "houseNumberStreetAddress",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "text",
        label: "City:",
        name: "city",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "text",
        label: "State:",
        name: "state",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "text",
        label: "Zip Code:",
        name: "zipCode",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText:
          "Social Security income: Form SSA-1099 issued by social security administration.",
        headingText: "Non-USA country social security benefits:",
        type: "input",
        inputType: "text",
        label: "Country:",
        name: "country",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "",
        type: "select",
        options: [
          { label: "US Pension income", value: "usPensionIncome" },
          { label: "Non-US Pension income", value: "nonUsPensionIncome" },
        ],
        label: "Do you receive US Pension income or Non - US Pension ?",
        name: "usNonUsPension",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "US Pension income:",
        type: "input",
        inputType: "text",
        label: "Name of the payer: First Box",
        name: "nameOfPayer",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "US Pension income:",
        type: "input",
        inputType: "text",
        label:
          "Tax identification number of payer: mentioned in form 1099-R under Payer’s TIN label",
        name: "tin1099R",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "US Pension income:",
        type: "input",
        inputType: "text",
        label: "Gross distribution from the payer: (Box-1 of the form 1099-R):",
        name: "grossDistribution",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "US Pension income:",
        type: "input",
        inputType: "text",
        label: "Taxable amount on total distribution: (Box 2a of form 1099-R):",
        name: "taxableTotalDistribution",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "US Pension income:",
        type: "input",
        inputType: "text",
        label: "Capital gain: (Box-3 of 1099-R):",
        name: "capitalGain",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "US Pension income:",
        type: "input",
        inputType: "text",
        label: "Federal income tax withheld: Box-4 of 1099-R",
        name: "federalIncomeTax",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Pension/IRA income: Form 1099-R Provided by the plan issuer.",
        headingText: "US Pension income:",
        type: "input",
        inputType: "text",
        label: "Distribution code reported in form 1099-R (Box 7):",
        name: "distCode1099R",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Unemployment income: Form 1099-G",
        headingText: "",
        type: "radio",
        options: [...YES_NO],
        label: "Do you have form 1099-G ?",
        name: "form1099Gexists",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Unemployment income: Form 1099-G",
        headingText: "",
        type: "radio",
        options: [...YES_NO],
        label: "Is the unemployment income from government plan ?",
        name: "unemploymentIncomeGovtPlan",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Unemployment income: Form 1099-G",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Employment income received: (Box 1)",
        name: "employmentIncomeRecv",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "select",
        label: "Where did you live when you earned your income?",
        name: "liveLocationSelfEmploy",
        options: [
          { label: "Outside US", value: "outsideUS" },
          { label: "In US", value: "inUS" },
          { label: "Combination of Both", value: "combBoth" },
        ],
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "conditional",
        condType: "input",
        inputType: "number",
        label: "Time spent abroad",
        values: ["combBoth"],
        dependency: "liveLocationSelfEmploy",
        name: "timeSpentAbroadSelfEmploy",
        helpText: "",
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        condType: "input",
        type: "conditional",
        inputType: "text",
        label: "Name of foreign country",
        values: ["combBoth"],
        dependency: "liveLocationSelfEmploy",
        name: "foreignCountrySelfEmploy",
        helpText: "",
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Name used for business (if separate from their name):",
        name: "nameBuisness",
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "select",
        options: [
          { label: "Apparel industry", value: "apparelIndustry" },
          { label: "Service industry", value: "serviceIndustry" },
          { label: "Trading goods industry", value: "tradingGoodsIndustry" },
          { label: "Tobacco and tobacco products", value: "tobaccoAndProducts" },
          { label: "Paper and paper products", value: "paperAndProducts" },
          { label: "Grocery and related products", value: "groceryProducts" },
          { label: "Petroleum products", value: "pertoleumProducts" },
          { label: "Paint and it’s supplies.", value: "paintSupplies" },
          { label: "Farm supplies", value: "farmSupplies" },
          { label: "Flower, nursery, stock", value: "flowerNurseryStock" },
          {
            label: "Beer, wine and distilled alcoholic beverage.",
            value: "beerWineAlcohol",
          },
          { label: "Books, periodicals, and newspapers", value: "booksNews" },
          { label: "Chemical and allied products", value: "chemicalProducts" },
          { label: "Druggist", value: "druggist" },
          { label: "Stock trading", value: "stockTrading" },
        ],
        label: "Principal activity of the business (choose from the following):",
        name: "principalActivity",
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "radio",
        options: [...YES_NO],
        label: "Was this business established in the current year?",
        name: "buisnessCurrentYear",
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Business EIN:",
        name: "buisnessEIN",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "Business address",
        type: "input",
        inputType: "text",
        label: "House number and street address :",
        name: "houseNumberStreetAddressSelfEmp",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "Business address",
        type: "input",
        inputType: "text",
        label: "City :",
        name: "citySelfEmp",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "Business address",
        type: "input",
        inputType: "text",
        label: "State :",
        name: "stateSelfEmp",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "Business address",
        type: "input",
        inputType: "text",
        label: "Zip Code :",
        name: "citySelfEmp",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "select",
        options: [
          { label: "Cash", value: "cash" },
          { label: "Accrual", value: "accural" },
          { label: "Other", value: "other" },
        ],
        label: "Accounting method used :",
        name: "accountingMethod",
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "select",
        options: [...YES_NO],
        label: "Are you required to file form 1099 ?",
        name: "requiredForm1099",
        rules: [{ required: true, message: "Please select an option" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Gross receipts in 2023 :",
        name: "grossReceipts2023",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Returns/allowances given to customers in 2023 :",
        name: "returnAllowances2023",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "select",
        options: [...YES_NO],
        label: "Did your business carry any physical inventory at the end of 2023 :",
        name: "buisnessPhysicalInventory",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "conditional",
        condType: "input",
        inputType: "text",
        dependency: "buisnessPhysicalInventory",
        values: ["yes"],
        label: "Returns/allowances given to customers in 2023 :",
        name: "returnAllowances2023",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "select",
        options: [
          { label: "Cost", value: "cost" },
          { label: "Lower of cost or market value", value: "lowerCost" },
          { label: "Other", value: "other" },
        ],
        label: "Method of valuing inventory :",
        name: "methodValuingInventory",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Total number of miles for which car was used for business purposes :",
        name: "totalNumberMiles",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "Deduction to be calculated at the rate of 65.5 cents per mile.",
      },
      {
        mainHeadingText: "Self-employment income",
        headingText: "Did you use your home for official use? Simplified method.",
        type: "input",
        inputType: "text",
        label: "Area used for official use: (Maximum deduction is 300sq ft) :",
        name: "areaOfficialUse",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "Deduction allowed = Area used for official use (in sq. ft) * $5",
      },
      {
        mainHeadingText: "Interest income: Form 1099- INT",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Name of the financial institution :",
        name: "financialInstitution",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Interest income: Form 1099 - INT",
        headingText: "",
        type: "select",
        options: [...YES_NO],
        label: "Is the financial institution headquartered in USA ?",
        name: "financialInstitutionHeadquarter",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Interest income: Form 1099 - INT",
        headingText: "",
        type: "conditional",
        condType: "input",
        inputType: "text",
        dependency: "financialInstitutionHeadquarter",
        values: ["yes"],
        label: "If yes, Interest earned in 2023: Box-1",
        name: "interestEarned2023",
      },
      {
        mainHeadingText: "Interest income: Form 1099 - INT",
        headingText: "",
        type: "conditional",
        condType: "input",
        inputType: "text",
        dependency: "financialInstitutionHeadquarter",
        values: ["no"],
        label: "If no, name the country where the institution is headquartered :",
        name: "nameCountryHeadquartered",
      },
      {
        mainHeadingText: "Interest income: Form 1099- INT",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Currency in which interest was earned :",
        name: "currencyInterestEarned",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Interest income: Form 1099- INT",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Interest earned in 2023 :",
        name: "interestEarned2",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Dividend income Form 1099-DIV",
        headingText: "",
        type: "radio",
        options: [...YES_NO],
        label: "Do you have form 1099-DIV ?",
        name: "form1099DIV",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Dividend income Form 1099-DIV",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Name of the financial institution paying dividend :",
        name: "financialInstitutionDividend",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Dividend income Form 1099-DIV",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Total dividend (Box 1a of form 1099-DIV)",
        name: "totalDividend",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Dividend income Form 1099-DIV",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Qualified dividend (Box 1b of form 1099-DIV)",
        name: "qualifiedDividend",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Dividend income Form 1099-DIV",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Total capital gains (Box 2a of form 1099-DIV)",
        name: "totalCapitalGains",
        rules: [{ required: true, message: "Please enter a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "",
        type: "radio",
        options: [
          { label: "In USA", value: "inUSA" },
          { label: "Outside USA", value: "outUSA" },
        ],
        label: "Where is the rental property located ?",
        name: "rentalPropertyLocation",
        rules: [{ required: true, message: "Please select a value" }],
        helpText: "",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "input",
        inputType: "text",
        dependency: "rentalPropertyLocation",
        values: ["yes"],
        label: "House number and street address :",
        name: "houseNumberStreetAddressRentalIncome",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "input",
        inputType: "text",
        dependency: "rentalPropertyLocation",
        values: ["yes"],
        label: "City :",
        name: "cityRentalIncome",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "input",
        inputType: "text",
        dependency: "rentalPropertyLocation",
        values: ["yes"],
        label: "State :",
        name: "stateRentalIncome",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "input",
        inputType: "text",
        dependency: "rentalPropertyLocation",
        values: ["yes"],
        label: "Postal Code :",
        name: "postalCodeRentalIncome",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "select",
        options: [
          { label: "Single Family Home", value: "singleFamilyHome" },
          { label: "Multiple Family Home", value: "multipleFamilyHome" },
          { label: "Commercial Property", value: "commercialProperty" },
          { label: "Vacation Home", value: "vacationHome" },
        ],
        dependency: "rentalPropertyLocation",
        values: ["yes"],
        label: "Type of rental property :",
        name: "rentalPropertyType",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "radio",
        options: [...YES_NO],
        dependency: "rentalPropertyLocation",
        values: ["yes"],
        label: "Was the property rented out for the entire year 2023 ?",
        name: "rentedOutDuration",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "input",
        inputType: "number",
        dependency: "rentedOutDuration",
        values: ["no"],
        label: "If no, for how many days was it rented out for :",
        name: "daysRentedOut",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "radio",
        options: [...YES_NO],
        dependency: "rentalPropertyLocation",
        values: ["yes"],
        label: "Did the taxpayer personally use the property ?",
        name: "taxpayerProperty",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "conditional",
        condType: "input",
        inputType: "number",
        dependency: "taxpayerProperty",
        values: ["yes"],
        label: "If yes, for how many days was the property used by the taxpayer :",
        name: "daysUserTaxpayer",
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "input",
        inputType: "number",
        label: "Rental income received during 2023 :",
        name: "rentalIncome2023",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "input",
        inputType: "text",
        label:
          "Mention the expenses incurred by the taxpayer in connection with the property during 2023 :",
        name: "expensesProperties",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "input",
        inputType: "text", // change to date type
        label: "Date on which rental property was rented out in 2023 :",
        name: "rentalPropertyDate",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Rental income",
        headingText: "If USA,",
        type: "input",
        inputType: "number",
        label: "Cost incurred to procure the rental property (with land) :",
        name: "costIncurred",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Royalty income: 1099-MISC",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Royalty payer :",
        name: "royaltyPayer",

        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Royalty income: 1099-MISC",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Place of residence :",
        name: "placeResidence",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Royalty income: 1099-MISC",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Royalty amount received :",
        name: "royaltyReceived",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Royalty income: 1099-MISC",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Federal income taxes withheld :",
        name: "federalIncome",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Royalty income: 1099-MISC",
        headingText: "",
        type: "input",
        inputType: "number",
        label:
          "Mention the expenses incurred in generating the royalty income for the year 2023 :",
        name: "expensesIncurredRoyalty2023",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Medical expenses",
        type: "input",
        inputType: "number",
        label: "Total allowable medical expenses incurred :",
        name: "medicalExpensesIncurred",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Medical expenses",
        type: "input",
        inputType: "number",
        label: "Adjusted gross income (Line 11 of form 1040) :",
        name: "adjustedGrossIncome",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Medical expenses",
        type: "input",
        inputType: "number",
        label: "Maximum deduction allowed (Point a*7.5%) :",
        name: "maxDeductionAllowed",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Medical expenses",
        type: "input",
        inputType: "number",
        label: "Deduction allowed = Lower of Point a or b :",
        name: "deductionAllowed",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Taxes paid: (State and local taxes paid)",
        type: "input",
        inputType: "number",
        label: "Income tax :",
        name: "incomeTax",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Taxes paid: (State and local taxes paid)",
        type: "input",
        inputType: "number",
        label: "General sales tax :",
        name: "generalSalesTax",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Taxes paid: (State and local taxes paid)",
        type: "input",
        inputType: "number",
        label: "State and local real estate taxes :",
        name: "stateLocalEstateTax",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Taxes paid: (State and local taxes paid)",
        type: "input",
        inputType: "number",
        label: "State and local personal property taxes :",
        name: "stateLocalPersonalTax",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Taxes paid: (State and local taxes paid)",
        type: "input",
        inputType: "number",
        label: "Total taxes paid: (Sum of all the above) :",
        name: "totalTaxesPaid",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Form 1040: Schedule-A",
        headingText: "Taxes paid: (State and local taxes paid)",
        type: "input",
        inputType: "text",
        label: "Other taxes: (List types and amount) :",
        name: "otherTaxes",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Home mortgage interest and points as reported on 1098: Box 6",
        name: "homeMortgageInterest",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Mortgage interest paid: Box 1",
        name: "mortgageInterestPaid",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Home mortgage interest and points as reported on 1098: Box 6",
        name: "homeMortgageInterest",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Points paid on purchase of principal residence",
        name: "pointsPaidPurchase",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Home mortgage interest and points not reported on 1098",
        name: "homeMortgageInterest",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "input",
        inputType: "text",
        label: "Points not reported on 1098.",
        name: "pointsNotReported",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "attachment",
        label: "Points not reported on 1098.",
        name: "pointsNotReported",
        rules: [{ required: false }],
      },
      {
        mainHeadingText: "Interest Paid: Form 1098",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Total of all the above",
        name: "totalOfAllTheAboveForm1098",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Gifts to Charity",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Gifts made by cash or check :",
        name: "giftsCashCheck",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Gifts to Charity",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Gifts other than by cash or check :",
        name: "giftsOtherThan",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Gifts to Charity",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Previous year carry over :",
        name: "previousYearCarryOver",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Gifts to Charity",
        headingText: "",
        type: "input",
        inputType: "number",
        label: "Total of all the above :",
        name: "totalOfAllTheAboveGiftCharity",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "",
        headingText: "",
        type: "input",
        inputType: "number",
        label:
          "Casualty and theft loss- Personal use property only (Form 4684-Line 18): ",
        name: "casualtyTheftLoss",
        rules: [{ required: true, message: "Please enter a value" }],
      },
      {
        mainHeadingText: "Taxes and Credits Forms required for Tax and credits :",
        headingText: "",
        type: "attachment",
        label: "Form 8814 - Parents’ Election to Report Child’s Interest and Dividends",
        name: "form8814",
        rules: [{ required: true, message: "Please attach a file" }],
      },
      {
        mainHeadingText: "Taxes and Credits Forms required for Tax and credits :",
        headingText: "",
        type: "attachment",
        label: "Form 4972 - Tax on Lump-Sum Distributions",
        name: "form4972",
        rules: [{ required: true, message: "Please attach a file" }],
      },
      {
        mainHeadingText: "Taxes and Credits Forms required for Tax and credits :",
        headingText: "",
        type: "attachment",
        label: "Form 8812 - Credits for Qualifying Children and Other Dependents",
        name: "form8812",
        rules: [{ required: true, message: "Please attach a file" }],
      },
      {
        mainHeadingText: "Taxes and Credits Forms required for Tax and credits :",
        headingText: "Alternative Minimum Tax",
        type: "attachment",
        label: "Alternative minimum tax (Attach form 6251) :",
        name: "form6251",
        rules: [{ required: true, message: "Please attach a file" }],
      },
      {
        mainHeadingText: "Taxes and Credits Forms required for Tax and credits :",
        headingText: "Alternative Minimum Tax",
        type: "attachment",
        label: "Excess advance premium tax credit repayment (Attach form 8642) :",
        name: "form8642",
        rules: [{ required: true, message: "Please attach a file" }],
      },
      {
        mainHeadingText: "Taxes and Credits Forms required for Tax and credits :",
        headingText: "Alternative Minimum Tax",
        type: "input",
        inputType: "number",
        label: "Total",
        name: "totalAltMinimumTax",
        rules: [{ required: true, message: "Please enter a value" }],
      },
    ],
  },
};
