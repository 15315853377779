import React, { useEffect, useState } from "react";

import { Container, Heading, Desc, Buttons } from "./styles";
import Reviews from "Pages/Home/Reviews/Reviews";
import { Button } from "Components";

const Salaried = () => {
  useEffect(() => {
    document.title = "Services • Eazytaxes";
  }, []);

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflow = isCalendlyOpen ? "hidden" : "auto";
  }, [isCalendlyOpen]);

  return (
    <Container>
      <Heading>
        Maximize Your <span>Tax Refund</span> with Our Comprehensive Tax Preparation
      </Heading>
      <Desc>
        We offer a simple and convenient way to file your taxes online, without the need
        to visit a physical location or hire a professional tax preparer.
        <br /> <br />
        Our service is designed to help you get the maximum refund possible. We do this by
        carefully reviewing your tax situation and identifying any deductions or credits
        that you may be eligible for. We will also ensure that all of your tax forms are
        filled out correctly, so you don't have to worry about making any mistakes that
        could delay your refund or result in an audit.
        <br /> <br />
        To use our service, simply create an account and follow the prompts to enter your
        personal and financial information. Our system will then guide you through the tax
        filing process, step by step.
        <br /> <br />
        If you have any questions or need assistance, our team of tax experts is available
        to help. So why wait? File your taxes with our online tax preparation service
        today and get the maximum refund you deserve!
      </Desc>
      <Buttons>
        <Button
          to="/get-my-taxes-filed"
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
        >
          Begin my tax filing process
        </Button>
        <Button
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
          bg="transparent"
          color="#55bb53"
          hoverBg="#55bb53"
          hoverColor="#fff"
          onClick={() => setIsCalendlyOpen(true)}
        >
          get free consultation
        </Button>
      </Buttons>
      <Reviews />
    </Container>
  );
};

export default Salaried;
