import { Suspense } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { ErrorFallback, Header, Footer } from "Components";
import ROUTES, { getSecondPath } from "constants/routes";
import {
  Dashboard,
  NotFound,
  AboutUs,
  Blogs,
  Blog,
  Home,
  Pricing,
  ServicesOld,
  ServicesNew,
  FBASellers,
  Salaried,
  SmallBusiness,
  Students,
  Auth0Callback,
  ContactUs,
  Testimonials,
} from "Pages";

import DashboardBlogs from "./Pages/Dashboard/Blog/Blogs";
import TaxReturnCreate from "./Pages/Dashboard/TaxReturns/TaxReturnCreate";
import TaxReturns from "./Pages/Dashboard/TaxReturns/TaxReturns";
import TaxReturn from "./Pages/Dashboard/TaxReturns/TaxReturn";
import ConnectCPA from "./Pages/Dashboard/ConnectCPA/ConnectCPA";
import PendingTasks from "./Pages/Dashboard/PendingTasks/PendingTasks";
import Documents from "./Pages/Dashboard/Documents/Documents";
import BlogEntry from "Pages/Dashboard/Blog/BlogEntry";
import AssignCPA from "Pages/Dashboard/AssignCPA/AssignCPA";
import CreateCPA from "Pages/Dashboard/CreateCPA/CreateCPA";
import Loader from "Components/uiComponents/LoadingSpinner";
import { LoaderContainer } from "Components/uiComponents/LoadingSpinner/styles";
import CPATaxView from "Pages/Dashboard/CPATaxView/CPATaxView";
import TaxReturnView from "Pages/Dashboard/CPATaxView/TaxReturnView";
import Cognito from "Pages/Dashboard/Cognito/Cognito";

const { Content } = Layout;

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader message={"Verifying Authentication"}></Loader>
      </LoaderContainer>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.ROOT} state={{ from: location }} />;
  }

  return children;
};

// https://www.npmjs.com/package/generate-robotstxt

export const RouterConfig = () => {
  const routes = [
    {
      path: ROUTES.ROOT,
      element: <Home />,
    },
    {
      path: ROUTES.ABOUT_US,
      element: <AboutUs />,
    },
    {
      path: ROUTES.SERVICES.MAIN,
      element: <ServicesOld />,
    },
    {
      path: ROUTES.SERVICES.TEMPLATE,
      element: <ServicesNew />,
    },
    {
      path: ROUTES.SERVICES.SALARIED,
      element: <Salaried />,
    },
    {
      path: ROUTES.SERVICES.EBAY,
      element: <FBASellers />,
    },
    {
      path: ROUTES.SERVICES.SMALL_BUISNESS,
      element: <SmallBusiness />,
    },
    {
      path: ROUTES.SERVICES.STUDENTS,
      element: <Students />,
    },
    {
      path: ROUTES.CONTACT_US,
      element: <ContactUs />,
    },
    {
      path: ROUTES.BLOGS.MAIN,
      element: <Blogs />,
    },
    {
      path: ROUTES.BLOGS.DETAIL,
      element: <Blog />,
    },
    {
      path: ROUTES.PRICING,
      element: <Pricing />,
    },
    {
      path: ROUTES.TESTIMONIAL,
      element: <Testimonials />,
    },
    {
      path: ROUTES.AUTH0_CALLBACK,
      element: <Auth0Callback />,
    },
    {
      path: ROUTES.DASHBOARD.MAIN,
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
      children: [
        { path: getSecondPath(ROUTES.DASHBOARD.BLOGS), element: <DashboardBlogs /> },
        {
          path: getSecondPath(ROUTES.DASHBOARD.TAX_RETURN.MAIN),
          element: <TaxReturns />,
        },
        {
          path: getSecondPath(ROUTES.DASHBOARD.TAX_RETURN.CREATE),
          element: <TaxReturnCreate />,
        },
        {
          path: getSecondPath(ROUTES.DASHBOARD.TAX_RETURN.DETAIL),
          element: <TaxReturn />,
        },
        { path: getSecondPath(ROUTES.DASHBOARD.CONNECT_CPA), element: <ConnectCPA /> },
        { path: getSecondPath(ROUTES.DASHBOARD.TASKS), element: <PendingTasks /> },
        { path: getSecondPath(ROUTES.DASHBOARD.DOCUMENTS), element: <Documents /> },
        { path: getSecondPath(ROUTES.DASHBOARD.CREATE_BLOG), element: <BlogEntry /> },
        { path: getSecondPath(ROUTES.DASHBOARD.EDIT_BLOG), element: <BlogEntry /> },
        { path: getSecondPath(ROUTES.DASHBOARD.ASSIGN_CPA), element: <AssignCPA /> },
        { path: getSecondPath(ROUTES.DASHBOARD.CREATE_CPA), element: <CreateCPA /> },
        { path: getSecondPath(ROUTES.DASHBOARD.TAX_VIEW_CPA), element: <CPATaxView /> },
        { path: getSecondPath(ROUTES.DASHBOARD.TAX_FORM_CPA), element: <TaxReturnView /> },
        { path: getSecondPath(ROUTES.DASHBOARD.COGNITO), element: <Cognito /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ];

  return useRoutes(routes);
};

const Main = () => (
  <Suspense fallback={<p>Loading...</p>}>
    <RouterConfig />
  </Suspense>
);

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <Layout>
          <Header />
          <Content className="main-content">
            <Main />
          </Content>
          <Footer />
        </Layout>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;
