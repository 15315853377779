import styled from "styled-components";

export const Container = styled.div`
  box-shadow: 8px 8px 30px 0 rgb(0 0 0 / 10%);
  border-radius: 0.5rem;
  width: 40rem;
`;

export const Image = styled.img`
  width: 100%;
  height: 27rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Content = styled.div``;

export const TitleDesc = styled.div`
  padding: 2.5rem;
`;

export const Title = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  transition: 0.3s all;
  &:hover {
    color: #55bb53;
  }
`;

export const Desc = styled.div`
  color: #5f5f5f;
  line-height: 30px;
`;

export const BlogDateOpen = styled.div`
  padding: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.15);
`;

export const Date = styled.div`
  color: #60626d;
  font-size: 1.4rem;
`;

export const Open = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  background: #ededed;
  padding: 0.8rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
  transition: 0.3s all;
  &:hover {
    border-color: #55bb53;
    color: #55bb53;
    background: rgba(85, 187, 83, 0.2);
  }
`;

// Main Blog Styles

export const LoadingContainer = styled.div``;

export const BlogContainer = styled.div`
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 5%);
  margin: auto;
  width: 70%;
`;

export const BlogImage = styled.img`
  width: 100%;
  aspect-ratio: 16 / 10;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const BlogContent = styled.div`
  padding: 2.5rem;
`;

export const BlogComment = styled.div`
  font-size: 2rem;
  display: flex;
  margin-top: 2rem;
`;

export const BlogCommentSmall = styled.div`
  font-size: 1.5rem;
  display: flex;
  white-space: nowrap; 
`;

export const BlogLoginMessage = styled.div`
`;

export const BlogCommentLine = styled.div`
  width: 100%;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.15);
  margin-top: 2rem;
`;

export const BlogAuthor = styled.div`
  color: #60626d;
  font-size: 1.4rem;
`;

export const BlogTitle = styled.div`
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const BlogDesc = styled.div`
  color: #5f5f5f;
  line-height: 30px;
`;

export const MainBlogDateOpen = styled.div`
  padding-top: 2.5rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.15);
`;

export const BlogDate = styled.div`
  color: #60626d;
  font-size: 1.4rem;
`;

export const BlogOpen = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  background: #ededed;
  padding: 0.8rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
  transition: 0.3s all;
  &:hover {
    border-color: #55bb53;
    color: #55bb53;
    background: rgba(85, 187, 83, 0.2);
  }
`;
