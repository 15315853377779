import React from "react";
import {
  Buttons,
  Container,
  Desc,
  Heading,
  SubDesc,
  Layout,
  Background,
  LayoutLeft,
  LayoutRight,
} from "./styles";
import { Button } from "Components";

const Hero = ({ setIsCalendlyOpen }) => {
  const dash_bg = `${process.env.PUBLIC_URL}/assets/dashboard/img2.png`;
  return (
    <Container src={dash_bg}>
      <Layout>
        <LayoutLeft>
          <Heading>
            <span> No. 1 </span> Choice for <span> US Tax </span>filing!
          </Heading>
          {/* <LayoutLeftList>
                        <List
                            items={[
                                "Accurate Compliance",
                                "Transparent Pricing",
                                "Maximum Refund",
                            ]}
                        />
                    </LayoutLeftList> */}
          <Desc>
            Accurate Compliance &nbsp;<span>|</span>&nbsp; Transparent Pricing &nbsp;
            <span>|</span>&nbsp; Maximum Refund
          </Desc>
          <SubDesc>We take care of your tax, so you can sit back and relax!</SubDesc>

          <Buttons>
            <Button
              to="/get-my-taxes-filed"
              style={{ border: "0.1px solid #55bb53" }}
              padding="1.7rem 3.5rem"
            >
              Begin my tax filing process
            </Button>
            <Button
              style={{ border: "0.1px solid #55bb53" }}
              padding="1.7rem 3.5rem"
              bg="transparent"
              color="#55bb53"
              hoverBg="#55bb53"
              hoverColor="#fff"
              onClick={() => setIsCalendlyOpen(true)}
            >
              Schedule a call today
            </Button>
          </Buttons>
        </LayoutLeft>
        <LayoutRight>
          <Background src={dash_bg}> </Background>
        </LayoutRight>
      </Layout>
    </Container>
  );
};

export default Hero;
