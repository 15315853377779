import { useLayoutEffect } from "react";
import styled from "styled-components";
import "./Loading.css";
import { HashLoader } from "react-spinners";

const Container = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

const Loading = () => {
  useLayoutEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);
  return (
    <Container>
      {/* <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div> */}
      {/* <HashLoader color="#55bb53" size={100} /> */}
    </Container>
  );
};

export default Loading;
