import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import ROUTES from "constants/routes";

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Button type="primary">
        <Link to={ROUTES.DASHBOARD.HOME}>Go To Dashboard</Link>
      </Button>
    }
  />
);

export default NotFound;
