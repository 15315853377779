import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 5rem;
  width: 70%;
  margin: auto;
  margin: 5rem auto;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  table,
  td,
  th {
    border: none;
  }
  border-radius: 0.5rem;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 12%);
  display: flex;
  gap: 2rem;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;

export const Logo = styled.img`
  width: 10rem;
  /* user-select: none;
    font-weight: bold !important;
    color: #0d71ba !important;
    background: linear-gradient(
        45deg,
        #55bb53 0%,
        #0d71ba 80.42%,
        #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    display: flex;
    align-items: center; */
`;

export const Desc = styled.div`
  line-height: 1.8;
  font-weight: 400;
  font-size: 2rem;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 2rem;
  width: 80%;
  padding: 0 1.5rem;
  text-align: justify;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Heading = styled.div`
  color: #0b2a41;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 3rem 0 2rem 0;
  text-align: center;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 700px) {
    font-size: 3rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin: 5rem 0 0 0;
  margin-bottom: 4rem;
`;
