import React, { useEffect } from "react";
import OurClientsWorkAt from "Pages/Home/OurClientsWorkAt/OurClientsWorkAt";
import Reviews from "Pages/Home/Reviews/Reviews";
import ShowedThemWay from "Pages/Home/Testimonials/ShowedThemWay/ShowedThemWay";
import { Container } from "./styles";

const Testimonials = () => {
  useEffect(() => {
    document.title = "Testimonials • Eazytaxes";
  }, []);
  return (
    <Container>
      <ShowedThemWay />
      <OurClientsWorkAt hideReadWhatTheyHaveToSay />
      <Reviews />
    </Container>
  );
};

export default Testimonials;
