import React from "react";
import {
  Container,
  Content,
  Desc,
  Details,
  Heading,
  Left,
  Right,
  TagLine,
  TagLineHeading,
} from "./styles";
import "@lottiefiles/lottie-player";
import { IoCall } from "react-icons/io5";
import { RiSendPlaneFill } from "react-icons/ri";
import ContactDetail from "../ContactDetail/ContactDetail";

const ContactDetails = () => {
  const contactDetails = [
    {
      icon: <IoCall />,
      heading: "Call Us 24/7",
      headingLink: "tel:+1",
      desc: "+1(206)-886-0475",
    },
    {
      icon: <RiSendPlaneFill />,
      heading: "Mail Us",
      headingLink: "mailto:contact@eazytaxes.com",
      desc: "contact@eazytaxes.com",
    },
  ];
  return (
    <Container>
      <Content>
        <Left>
          <TagLineHeading>
            <TagLine>contact details</TagLine>
            <Heading>our contacts</Heading>
          </TagLineHeading>
          <Desc>
            Give us a call or drop by anytime, we endeavour to answer all enquiries within
            24 hours on business days. We will be happy to answer your questions.
          </Desc>
          <Details>
            {contactDetails.map((contactDetail, index) => (
              <ContactDetail key={index} {...contactDetail} />
            ))}
          </Details>
        </Left>
        <Right>
          <lottie-player
            autoplay
            loop
            mode="normal"
            src="https://assets3.lottiefiles.com/packages/lf20_hlq6mcbz.json"
            style={{ width: "70%" }}
          ></lottie-player>
        </Right>
      </Content>
    </Container>
  );
};

export default ContactDetails;
