import React from "react";
import {
  Company,
  Container,
  Experience,
  ExperienceIndustry,
  Industry,
  Left,
  Name,
  Position,
  ProfilePic,
  QuoteIcon,
  Review,
  Right,
  WeHelpedWith,
  CompanyTitle,
} from "./styles";
import { RiDoubleQuotesL } from "react-icons/ri";

const data = [
  {
    name: "Prithvi Poddar",
    profilePic: "prithvi-poddar.jpeg",
    position: "Software Engineer",
    company: "NextEra Energy Resources",
    experience: "4+ Years",
    testimonial:
      "I have used eazytaxes to file my taxes and found it to be cheaper and easier than any in-person service we had ever used. I was so happy with how easy the website was to use, how well the customer service responded to my questions, and also how affordable it was! All my co-workers are using this program now too.",
  },
  {
    name: "Deval Vora",
    profilePic: "deval-vora.jpeg",
    position: "Senior Software Engineer",
    company: "Amazon Web Services",
    experience: "3+ Years",
    testimonial:
      "Eazytaxes.com really simplifies tax filing to a great extent and minimize the effort from my side. The team was super helpful; all I had to do is send the required documents from my end and rest all of things including e-filing was taken care by them and I could get the maximum tax return without having to pay single attention for tax-filing deadlines or any other documentation.",
  },
  {
    name: "Jenniffer Baker",
    profilePic: "jenniffer-baker.jpeg",
    position: "CTO",
    company: "Synovus",
    experience: "2+ Years",
    testimonial:
      "This site was great, I am not the best with computers and the idea of doing my own taxes was daunting but these guys made it so easy. All I had to do was upload the documents they asked me to and then just sign a few things, I didn't even have to meet with anyone in person. They were very polite and helpful during the whole process.",
  },
  {
    name: "Nikhil Bagmar",
    profilePic: "nikhil-bagmar.jpeg",
    position: "Senior Software Engineer",
    company: "Amazon Web Services",
    experience: "3+ Years",
    testimonial:
      "I have been using eazytaxes.com for the past few years and it has helped me get a maximum refund every time. As compared to when I used to take my tax returns to my regular CPA, I have noticed a significant increase in my refund amount.",
  },
  {
    name: "Shubham Pampattiwar",
    profilePic: "shubham-pampattiwar.jpeg",
    position: "Senior Software Engineer",
    company: "Red Hat",
    experience: "3+ Years",
    testimonial:
      "I always dreaded April deadline for taxes but this year I was able to handle it with the help of a tax firm. Their professional specialists helped me with everything from following up on my employer and insurance companies, getting all my financial files in order, dealing with my deductions, and even nice preparation. It was so easy and so seamless this year. I feel confident that I will be able to use their service next year as well.",
  },
  {
    name: "Ankit Sureka",
    profilePic: "ankit-sureka.jpeg",
    position: "Business Owner",
    company: "Braintree LLC",
    experience: "2+ Years",
    testimonial:
      "I recently used eazytaxes.com for my tax preparation and I couldn't be happier with the service I received. The process was seamless and straightforward from start to finish. The team at eazytaxes.com was professional, knowledgeable, and always available to answer any questions I had. Their online platform made it easy for me to upload all of my necessary documents and track the progress of my tax return. And when it came time to file, everything was done accurately and efficiently.",
  },
  {
    name: "Prakhar Gupta",
    profilePic: "prakhar-gupta.jpeg",
    position: "Consultant",
    company: "BCG",
    experience: "2+ Years",
    testimonial:
      "Considering I had changed my job during the year, I was really worried about filing my taxes this year, but I found eazytaxes.com and I was really happy with the results. I was just asked to submit some basic documents and they did the hassle to find every deduction for me and maximise the refund. They were able to make things easy for me so that I understood what was going on and they kept me in the loop every step of the way.",
  },
  {
    name: "Shivali S",
    profilePic: "shivali-s.jpeg",
    position: "AVP, Data Engineering",
    company: "GenStar Division",
    experience: "6+ Years",
    testimonial:
      "eazytaxes.com is the best company I've ever dealt with because of their simplicity, prices and genuine customer service. If you're looking for a place to prepare your taxes, use eazytaxes.com!",
  },
  {
    name: "Nirmal Kumar S ",
    profilePic: "nirmal-kumar-s.jpeg",
    position: "Business Owner",
    company: "Kudometrics LLC",
    experience: "7+ Years",
    testimonial:
      "As a small business owner I have had to spend many hours on complicated and complex tax software in order to file my taxes. eazytaxes was so much easier, faster and better for my small business. They provide top of the line customer service which is hard to find these days.",
  },
  {
    name: "Samyak Nahar",
    profilePic: "samyak-nahar.jpeg",
    position: "Senior Associate",
    company: "Transaction and Due Diligence, EY",
    experience: "2+ Years",
    testimonial:
      "I used eazytaxes.com to file my taxes and was blown away by the level of service I received. The process was quick and easy, and I was able to get the maximum refund possible. The team at eazytaxes.com was knowledgeable and professional, and they made sure to answer all of my questions along the way. I highly recommend eazytaxes.com to anyone looking to file their taxes with confidence and peace of mind.",
  },
  {
    name: "Renee Fangonilo",
    profilePic: "renee-fangonilo.jpeg",
    position: "Senior Finance Manager",
    company: "Apple Inc",
    experience: "8+ Years",
    testimonial:
      "eazytaxes.com is an amazing service. Normally, I get very stressed during filing time, but this year is much better thanks to them! They helped me file and showed me everything was done correctly. It was such a great experience.",
  },
];

const ShownWayTo = () => {
  return (
    <>
      {data.map((item, _index) => (
        <Container>
          <Left>
            <ProfilePic src={`/assets/testimonials/${item.profilePic}`} />
            <Name>
              <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                {item.name}
              </a>
            </Name>
            <Position>{item.position}</Position>
            <CompanyTitle>{item.company}</CompanyTitle>
            {/* <Company src="https://assets-global.website-files.com/610bff8bc7ae736ceea1fa8b/6242f86fa207cb28a54ea226_victorias-secret-logo%201.png" /> */}
          </Left>
          <Right>
            <QuoteIcon>
              <RiDoubleQuotesL />
            </QuoteIcon>
            <Review>{item.testimonial}</Review>

            <ExperienceIndustry>
              <Experience>{item.experience}</Experience>
              {/* <Industry>Sales</Industry> */}
            </ExperienceIndustry>
          </Right>
        </Container>
      ))}
    </>
  );
};

export default ShownWayTo;
