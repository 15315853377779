import { useBlogsQuery } from "Hooks/apiHooks";
import { Button } from "Components";
import { Heading, Container, TopRow, BlogContent } from "./styles";
import BlogCard from "./BlogCard";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/routes";
import Loader from "Components/uiComponents/LoadingSpinner";
import { useEffect } from "react";


const Blogs = () => {
  const { data, error, isLoading, refetch } = useBlogsQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      throw new Error(error.message);
    }
  }, [error]);

  return (
    <Container>
      <TopRow>
        <Heading>Blog List</Heading>
        <Button
          onClick={() => {
            navigate(ROUTES.DASHBOARD.CREATE_BLOG);
          }}
        >
          Create Blog
        </Button>
      </TopRow>
      {!isLoading ? (
        <BlogContent>
          {data?.map((blog, index) => {
            return (
              <BlogCard
                key={index}
                author={blog.author}
                image={blog.image}
                link={blog.link}
                title={blog.title}
                published={new Date(blog.created_at).toLocaleDateString()}
                desc={blog.desc}
                id={blog.id}
                refetchBlogs={refetch}
              />
            );
          }) ?? []}
        </BlogContent>
      ) : (
        <Loader message={"Loading Blogs"} />
      )}
    </Container>
  );
};

export default Blogs;
