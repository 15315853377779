import cx from "classnames";

import styles from "./spinner.module.scss";

const Loader = ({ message, className }) => (
  <div className={cx(styles.container, { [`${className}`]: className })}>
    <div className={styles.loader}>
      <div />
      <div />
      <div />
    </div>
    {message && <p className={styles.loadingFont}>{message}</p>}
  </div>
);

export default Loader;
