import { createContext, useContext, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Axios from "axios";
import { useEffect } from "react";

function createCtx() {
  const AxiosContext = createContext();
  function useAxiosContext() {
    const c = useContext(AxiosContext);
    if (c === undefined) {
      throw new Error("useAxiosContext must be inside a Provider with a value");
    }
    return c;
  }
  return [useAxiosContext, AxiosContext.Provider];
}

const [useAxios, AxiosDataProvider] = createCtx();

const AxiosProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState();
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);

  useEffect(() => {
    setIsTokenLoaded(false);
    getAccessTokenSilently()
      .then((accessToken) => {
        setAccessToken(accessToken);
        setIsTokenLoaded(true);
      })
      .catch((error) => {
        setIsTokenLoaded(true);
      });
  }, []);

  const axiosInstance = useMemo(() => {
    const axios = Axios.create({
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 50000,
    });
    axios.interceptors.request.use((config) => {
      if (accessToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }
      return config;
    });
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        throw new Error(
          err?.message ?? "Something went wrong, check internet connection",
        );
      },
    );
    return axios;
  }, [accessToken]);

  return (
    <AxiosDataProvider value={{ axios: axiosInstance, isTokenLoaded }}>
      {children}
    </AxiosDataProvider>
  );
};

export { AxiosProvider, useAxios };
