import { message } from "antd";

export const uploadImage =
  (attachementMutation, isUnique = false) =>
  async (image) => {
    if (!image) {
      message.error("No image added");
      return;
    }
    const isJpgOrPng = image.type === "image/jpeg" || image.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt2M = image.size / 1024 / 1024 < 10;

    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      return;
    }
    const attachmentFormData = new FormData();
    attachmentFormData.append(
      "s3_file",
      new File(
        [image],
        image.name.split(".")[0] + Date.now() + "." + image.name.split(".")[1],
        { type: image.type }
      )
    );
    attachmentFormData.append("type", "Cloud");
    const { data: attachementId } = await attachementMutation.mutateAsync(
      attachmentFormData
    );
    return attachementId;
  };

export const removeUndefined = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value !== undefined)
  );
};

export const formatStatus = (status) => {
  switch (status) {
    case "in-progress":
      return "In Progress";
    case "complete":
      return "Complete";
  }
};

export const formatType = (type) => {
  switch (type) {
    case "individual":
      return "Individual";
    case "individualLite":
      return "Individual (Test)";
    case "buisness":
      return "Buisness";
    case "form1040":
      return "Form 1040";
  }
};
