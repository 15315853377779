import { Button, Result } from "antd";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.log(error)
  return (<Result
    status="500"
    title={error?.name || "Error"}
    subTitle={error?.message || "Sorry, something went wrong."}
    extra={
      <Button type="primary" onClick={resetErrorBoundary}>
        Go to Dashboard
      </Button>
    }
  />)
  };

export default ErrorFallback;
