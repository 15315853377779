import styled from "styled-components";
import { Row, ColumnCallBack, ColumnForm, Container } from "../styles";

const CallBackContainer = styled.div`
  margin-top: 140px;
  border-top: 1px solid #eee;
  padding-top: 140px;
`;

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const FormContainer = styled.div`
  background-color: #a4c639;
  padding: 60px;
  border-radius: 5px;
  text-align: center;
`;

const Header2 = styled.h2`
  font-size: 36px;
  font-weight: 600;
  color: #1e1e1e;
`;

const EM = styled.em`
  font-style: normal;
  color: #a4c639;
`;

const Span = styled.span`
  display: block;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 15px;
  color: #666;
  letter-spacing: 1px;
`;

const StyledInput = styled.input`
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0px 15px;
  color: #6a6a6a;
  font-size: 13px;
  text-transform: none;
  box-shadow: none;
  border: none;
  margin-bottom: 35px;

  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const StyledTextArea = styled.textarea`
  border-radius: 20px;
  height: 120px;
  max-height: 200px;
  min-height: 120px;
  display: inline-block;
  padding: 15px;
  color: #6a6a6a;
  font-size: 13px;
  text-transform: none;
  box-shadow: none;
  border: none;
  margin-bottom: 35px;

  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 28px;
  border-radius: 30px;
  display: inline-block;
  transition: all 0.3s;
  outline: none;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;
`;

const CallBack = () => {
  return (
    <CallBackContainer>
      <Container>
        <Row>
          <ColumnCallBack>
            <HeadingContainer>
              <Header2>
                Request a <EM>call back</EM>
              </Header2>
              <Span>interdum nisl ac urna tempor mollis</Span>
            </HeadingContainer>
          </ColumnCallBack>
          <ColumnCallBack>
            <FormContainer>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Row>
                  <ColumnForm>
                    <StyledInput
                      name="name"
                      type="text"
                      placeholder="Full Name"
                      required=""
                    />
                  </ColumnForm>
                  <ColumnForm>
                    <StyledInput
                      name="email"
                      type="text"
                      pattern="[^ @]*@[^ @]*"
                      placeholder="E-Mail Address"
                      required=""
                    />
                  </ColumnForm>
                  <ColumnForm>
                    <StyledInput
                      name="subject"
                      type="text"
                      placeholder="Subject"
                      required=""
                    />
                  </ColumnForm>
                  <ColumnCallBack>
                    <StyledTextArea
                      name="message"
                      rows="6"
                      placeholder="Your Message"
                      required=""
                    ></StyledTextArea>
                  </ColumnCallBack>
                  <ColumnCallBack>
                    <StyledButton type="submit">Send Message</StyledButton>
                  </ColumnCallBack>
                </Row>
              </form>
            </FormContainer>
          </ColumnCallBack>
        </Row>
      </Container>
    </CallBackContainer>
  );
};

export default CallBack;
