import React, { useState, useMemo } from "react";
import { Form, Button, message, Popover } from "antd";
import TaxReturnFormItem from "./TaxReturnFormItem";
import {
  useAttachmentsByIdQuery,
  useCreateAttachmentMutation,
  useCreateFormMutation,
  useUpdateFormMutation,
  useUpdateTaxReturnMutation,
} from "Hooks/apiHooks";
import { uploadImage } from "helpers";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { questions as formQuestions } from "../../../constants/forms";
import ROUTES from "constants/routes";
import { useEffect } from "react";
import Loader from "Components/uiComponents/LoadingSpinner";
import { Progress } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const FormContainer = styled.div`
  padding: 20px;
  min-width: 40%;
  background-color: #fff;
  border-radius: 10px;
  width: fit-content;
  height: 500px;
`;

const InnerFormContainer = styled.div`
  display: flex;
  justify-content: center;
  ${(props) => (!props.isTable ? "align-items: center" : "")};
  height: 85%;
  overflow-y: scroll;
`;

const CondHide = styled.div`
  display: ${(props) => (props.toHide ? "none" : "block")};
`;

const TaxReturnForm = ({ taxType, taxReturnId, formId, formData, refetchForm }) => {
  const [form] = Form.useForm();
  const questions = formQuestions[taxType].form;
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const attachementQuestions = useMemo(
    () =>
      questions.filter(
        (question) => question.type === "attachment" || question.condType === "attachment"
      ),
    [questions]
  );

  const [triggerRender, setTriggerRender] = useState(false);
  const [attachementUrls, setAttachmentUrls] = useState({});

  const attachementIds = useMemo(() => {
    return formData
      ? Object.entries(formData)
          .filter(([name, _]) =>
            Boolean(attachementQuestions.find((question) => question.name === name))
          )
          .map(([_, id]) => id)
      : [];
  });

  const { data, isLoading, error } = useAttachmentsByIdQuery(
    attachementIds,
    Boolean(attachementIds.length)
  );

  useEffect(() => {
    if (error) {
      throw new Error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setAttachmentUrls(
        Object.fromEntries(
          Object.entries(formData)
            .map(([name, value]) => {
              if (attachementQuestions.find((question) => question.name === name)) {
                return [
                  name,
                  {
                    url: data.find((a) => a.attachment_id === Number(value)).s3_file,
                  },
                ];
              }
            })
            .filter((obj) => Boolean(obj))
        )
      );
    }
  }, [data]);

  const navigate = useNavigate();
  const createFormMutation = useCreateFormMutation();
  const updateFormMutation = useUpdateFormMutation();
  const updateTaxReturnMutation = useUpdateTaxReturnMutation();
  const createAttachmentMutation = useCreateAttachmentMutation();

  const handleSubmit = async (values, isComplete) => {
    try {
      await Promise.all(
        attachementQuestions.map(async (question) => {
          if (values[question.name] && typeof values[question.name] === "object") {
            values[question.name] = await uploadImage(
              createAttachmentMutation,
              true
            )(values[question.name].file);
          }
        })
      );
      if (formId) {
        await updateFormMutation.mutateAsync({
          formId,
          formData: {
            data: JSON.stringify(values),
            status: isComplete ? "complete" : "in-progress",
          },
        });
      } else {
        await createFormMutation.mutateAsync({
          data: JSON.stringify(values),
          tax_return: taxReturnId,
          status: isComplete ? "complete" : "in-progress",
        });
      }
      message.success("Form submitted successfully!");
      navigate(ROUTES.DASHBOARD.TAX_RETURN.MAIN);
    } catch (error) {
      message.error(error.message);
    }
  };

  const validateAttachements = (formValues) => {
    const errorFields = [];
    attachementQuestions.forEach((question) => {
      // validate conditional attachements too
      if (
        ((question.type === "attachment" &&
          question.rules.some((rule) => rule.required)) ||
          (question.condType === "attachment" &&
            question.rules.some((rule) => rule.required) &&
            form.getFieldValue(question.dependency) &&
            question.values.includes(form.getFieldValue(question.dependency)))) &&
        !formValues[question.name]
      ) {
        errorFields.push(`${question.name} not present.`);
      }
    });
    if (errorFields.length > 1) {
      throw new Error("Attachment Invalid");
    }
  };

  const handleSubmitClick = async () => {
    const formValues = form.getFieldsValue();
    try {
      await form.validateFields();
      validateAttachements(formValues);
      await handleSubmit(formValues, true);
      await updateTaxReturnMutation.mutateAsync({
        taxReturnId,
        taxReturnData: { status: "complete" },
      });
      message.success("Tax return complete.");
    } catch (error) {
      if ((error.values && error.errorFields) || error.message === "Attachment Invalid") {
        await handleSubmit(formValues, false);
        message.warning("Tax return not complete.");
      } else {
        throw error;
      }
    }
    if (refetchForm) await refetchForm();
  };

  const handleReset = () => {
    // add delete form is form exists
    form.resetFields();
    setActiveQuestionIndex(0);
    message.info("Form reset successfully!");
  };

  const handleChange = () => {
    setTriggerRender(!triggerRender);
  };

  return (
    <>
      {isLoading ? (
        <Loader message={"Loading Attachments"} />
      ) : (
        <>
          <FormContainer>
            <Progress
              percent={Math.floor(((activeQuestionIndex + 1) * 100) / questions.length)}
            />
            <InnerFormContainer
              isTable={
                questions[activeQuestionIndex].type === "tableInput" ||
                questions[activeQuestionIndex].condType === "tableInput"
              }
            >
              <Form
                form={form}
                layout="vertical"
                onValuesChange={handleChange}
                initialValues={formData}
                style={{ width: "80%" }}
              >
                {questions.map((question, index) => {
                  const { info } = question;
                  return (
                    <CondHide toHide={activeQuestionIndex !== index}>
                      <TaxReturnFormItem
                        question={question}
                        attachementUrls={attachementUrls}
                        index={index}
                        form={form}
                      />
                      {info && (
                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                          <Popover placement="bottom" title={"INFO"} content={info}>
                            <span style={{ fontSize: "15px" }}>
                              <InfoCircleOutlined />
                            </span>
                          </Popover>
                        </div>
                      )}
                    </CondHide>
                  );
                })}
              </Form>
            </InnerFormContainer>
            <Form.Item>
              <Button
                onClick={() => setActiveQuestionIndex(activeQuestionIndex - 1)}
                disabled={
                  createAttachmentMutation.isLoading ||
                  createFormMutation.isLoading ||
                  activeQuestionIndex === 0
                }
                loading={
                  createAttachmentMutation.isLoading || createFormMutation.isLoading
                }
              >
                Back
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={
                  activeQuestionIndex + 1 === questions.length
                    ? handleSubmitClick
                    : () => setActiveQuestionIndex(activeQuestionIndex + 1)
                }
                disabled={
                  createAttachmentMutation.isLoading || createFormMutation.isLoading
                }
              >
                {activeQuestionIndex + 1 === questions.length ? "Submit" : "Next"}
              </Button>
              {activeQuestionIndex + 1 === questions.length ? (
                <Button
                  type={"dashed"}
                  onClick={handleReset}
                  disabled={
                    createAttachmentMutation.isLoading || createFormMutation.isLoading
                  }
                  loading={
                    createAttachmentMutation.isLoading || createFormMutation.isLoading
                  }
                >
                  Reset
                </Button>
              ) : (
                <></>
              )}
            </Form.Item>
          </FormContainer>
        </>
      )}
    </>
  );
};

export default TaxReturnForm;
