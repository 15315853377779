import styled from "styled-components";

export const Container = styled.div`
  padding: 7rem 3rem;
  margin-bottom: 8rem;
  text-align: center;
  //   margin: 6rem auto;
  //   background-image: url(${({ src }) => src});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 800px) {
    background: none;
  }
`;

export const Background = styled.div`
  max-width: 100%;
  height: 100%;
  background: url("${({ src }) => src}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 1200px) {
    background: none;
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LayoutLeft = styled.div`
  flex: 3;
  padding-top: 8rem;
`;

export const LayoutLeftList = styled.div`
  margin: 2rem;
  display: flex;
  align-items: left;
  justify-content: left;
  padding-left: 5rem;
`;

export const LayoutRight = styled.div`
  flex: 2.5;
  @media screen and (max-width: 1200px) {
    flex: none;
  }
`;

export const Heading = styled.div`
  font-size: 5rem;
  font-weight: 700;
  color: #1b1d21;
  font-family: "Poppins", sans-serif;
  max-width: 80rem;
  margin: auto;
  line-height: 1.2;
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 1000px) {
    font-size: 4.2rem;
  }
  @media screen and (max-width: 870px) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 670px) {
    font-size: 3rem;
  }
`;

export const Desc = styled.div`
  max-width: 110rem;
  font-size: 2.2rem;
  font-weight: 600;
  margin: 3rem auto 3rem;
  line-height: 30px;
  @media screen and (max-width 670px) {
    font-size: 1.6rem;
    line-height: 20px;
    margin: 2rem auto;
  }
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  margin: 2rem;
`;

export const SubDesc = styled.div`
  max-width: 80rem;
  font-size: 2rem;
  font-weight: 500;
  margin: 0.5rem auto 4rem;
  line-height: 30px;
  @media screen and (max-width: 670px) {
    font-size: 1.6rem;
    line-height: 20px;
    margin: 2rem auto;
  }
  & span {
    background: linear-gradient(45deg, #55bb53 0%, #0d71ba 80.42%, #0d71ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
