import styled from "styled-components";

export const Container = styled.header`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 3;
  box-shadow: 15px 15px 38px 0px rgb(0 0 0 / 10%);
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 1200px) {
    padding: 0 1.2rem;
  }
`;

export const HeaderContent = styled.div`
  position: relative;
  display: flex;
  font-size: 2rem;
  font-weight: 500;
  font-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 6rem;
  width: 100%;
  font-family: "Poppins", sans-serif;
  & span {
    font-size: 2rem;
    font-weight: 700;
  }
  background-color: #55bb53;
  @media screen and (max-width: 1200px) {
    font-size: 1.1rem;
    font-weight: 700;
    & span {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 360px) {
    font-size: 0.8rem;
    font-weight: 700;
    & span {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
`;

export const Content = styled.div`
  max-width: 130rem;
  margin: auto;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 10rem;
  /* user-select: none;
    font-weight: bold !important;
    color: #0d71ba !important;
    background: linear-gradient(
        45deg,
        #55bb53 0%,
        #0d71ba 80.42%,
        #0d71ba 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    display: flex;
    align-items: center; */
`;

export const HeaderLinks = styled.div`
  display: flex;
  gap: 3rem;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const HeaderLink = styled.div`
  font-weight: 600;
  line-height: 35px;
  padding: 1rem 0;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #55bb53;
    border-color: #55bb53;
  }
`;

export const LoginSignupHamMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const LoginSignup = styled.div`
  display: flex;
  gap: 2rem;
  @media screen and (max-width: 670px) {
    display: none;
  }
`;

export const HamMenuButton = styled.div`
  color: #55bb53;
  font-size: 3rem;
  font-weight: 400;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
  }
`;

export const DropDownMenu = styled.div`
  &:hover {
    background-color: black;
  }
`;
