import React, { useState } from "react";
import BlogPreview from "Pages/Blogs/Blog/BlogPreview";
import { BlogsList, Container } from "./styles";
import Pagination from "react-responsive-pagination";
import { useEffect } from "react";
import { useBlogsQuery } from "Hooks/apiHooks";
import Loader from "Components/uiComponents/LoadingSpinner";

const PER_PAGE = 6;

const Blogs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isLoading } = useBlogsQuery();

  useEffect(() => {
    document.title = "Blogs • Eazytaxes";
  }, []);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  useEffect(() => {
    if(error){
      throw new Error(error.message)
    }
  },[error])

  return (
    <Container>
      {!isLoading ? (
        <>
          <BlogsList>
            {data
              .slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
              .map((blog, index) => (
                <BlogPreview key={index} {...blog} link={`/blog/${blog.id}`} />
              ))}
          </BlogsList>
          {data.length !== 1 && (
            <Pagination
              total={Math.ceil(data.length / PER_PAGE)}
              current={currentPage}
              onPageChange={(page) => handlePageChange(page)}
            />
          )}
        </>
      ) : (
        <Loader message={"Blogs Loading..."} />
      )}
    </Container>
  );
};

export default Blogs;

// API CALL CODE
// const [totalPages, setTotalPages] = useState(null);
// import axios from "axios";
// import { LoadingContext } from "Context/LoadingContext.js";
// const [blogs, setBlogs] = useState([]);
// const [, startApiCall, finishApiCall] = useContext(LoadingContext);
// const getBlogs = () => {
//   startApiCall();
//   axios
//     .get(
//       `https://blogs.eazytaxes.com/ghost/api/content/posts/?key=32fb490f17b744125aa2ec873d&limit=6&page=${currentPage}`
//     )
//     .then(({ data }) => {
//       const { meta, posts } = data;
//       setTotalPages(meta.pagination.pages);
//       setBlogs(
//         posts.map(({ url, published_at, title, feature_image, meta_description }) => ({
//           link: url,
//           published: published_at,
//           title,
//           image: feature_image,
//           desc: meta_description,
//         }))
//       );
//       finishApiCall();
//     });
// };
// useEffect(() => {
//   getBlogs();
// }, [currentPage]);
