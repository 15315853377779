import React, { useEffect } from "react";

const ConnectCPA = () => {
  useEffect(() => {
    document.title = "Connect CPA • Eazytaxes";
  }, []);

  return <div>{"Connect CPA"}</div>;
};

export default ConnectCPA;
