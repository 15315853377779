import styled from "styled-components";

export const ServiceBody = styled.div`
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ImageBanner = styled.div`
  text-align: center;
  background-image: url(${process.env.PUBLIC_URL}/assets/service/page-heading-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 230px 0px 150px 0px;
  color: #fff;
`;

export const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const ServicesContainer = styled.div`
  margin-top: 140px;
`;

const Column = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;

export const HeaderContainer = styled(Column)`
  @media screen and (min-width: 768px) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const ColumnTabs = styled(Column)`
  @media screen and (min-width: 768px) {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`;

export const ColumnContent = styled(Column)`
  @media screen and (min-width: 768px) {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`;

export const ColumnCallBack = styled(Column)`
  @media screen and (min-width: 768px) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const ColumnForm = styled(Column)`
  @media screen and (min-width: 576px) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media screen and (min-width: 992px) {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`;
