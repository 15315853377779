import React, { useEffect, useState } from "react";

import { Container, Heading, Desc, Buttons } from "./styles";
import Reviews from "Pages/Home/Reviews/Reviews";
import { Button, List } from "Components";

const Students = () => {
  useEffect(() => {
    document.title = "Services • Eazytaxes";
  }, []);

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflow = isCalendlyOpen ? "hidden" : "auto";
  }, [isCalendlyOpen]);

  return (
    <Container>
      <Heading>
        Cost-Effective <span>Tax Filing</span> especially designed for students in USA!
      </Heading>
      <Desc>
        Welcome to our online tax preparation platform for students! We understand that as
        a student, your financial situation may be tight and that is why we offer very
        cheap tax preparation services to help you save money and get your taxes done
        accurately and efficiently.
        <br /> <br />
        Our platform is designed specifically for students and is easy to use. All you
        need to do is upload your tax documents, answer a few questions, and we will
        handle the rest. Our team of experienced tax professionals will review your
        information and ensure that you receive all the credits and deductions you are
        entitled to.
        <br /> <br />
        In addition to preparing your taxes, we also offer a variety of other services to
        help you with your financial situation. These include:
        <br />
        <List
          items={[
            "Tax planning: We can help you plan for the upcoming tax year by analyzing your financial situation and providing recommendations on how to save money on your taxes.",
            "Tax resolution: If you have outstanding tax issues, we can help you resolve them and get back on track with the IRS.",
            "Tax debt relief: If you are struggling with tax debt, we can help you negotiate a payment plan or explore other options for relief.",
          ]}
        />
        <br />
        Our team is dedicated to providing you with exceptional service and we are here to
        answer any questions you may have. Contact us today to get started on your tax
        preparation!
      </Desc>
      <Buttons>
        <Button
          to="/get-my-taxes-filed"
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
        >
          Begin my tax filing process
        </Button>
        <Button
          style={{ border: "0.1px solid #55bb53" }}
          padding="1.7rem 3.5rem"
          bg="transparent"
          color="#55bb53"
          hoverBg="#55bb53"
          hoverColor="#fff"
          onClick={() => setIsCalendlyOpen(true)}
        >
          get free consultation
        </Button>
      </Buttons>
      <Reviews />
    </Container>
  );
};

export default Students;
