import React from "react";
// import { ImQuotesLeft } from "react-icons/im";
import {
  Container,
  Content,
  ReviewContent,
  // Icon,
  UserName,
  UserPic,
  // UserPicName,
} from "./styles";

const Review = ({ review }) => {
  const { name, profilePic, content } = review;
  return (
    <Container>
      {/* <UserPicName> */}
      <UserPic src={`${process.env.PUBLIC_URL}/assets/testimonials/${profilePic}.jpeg`}>
        {/* <Icon>
                    <ImQuotesLeft />
                </Icon> */}
      </UserPic>
      {/* </UserPicName> */}
      <Content>
        <ReviewContent>“{content}”</ReviewContent>
        <UserName>{name}</UserName>
      </Content>
    </Container>
  );
};

export default Review;
