import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Form, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useBlogQuery,
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useCreateAttachmentMutation,
} from "Hooks/apiHooks";
import { useParams } from "react-router-dom";
import { uploadImage } from "helpers";
import UploadAsterix from "Components/uiComponents/UploadAsterix";

const ButtonGroup = styled.div`
  margin-top: 30px;
`;

const FormContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 5px;
  width: 60%;
`;

const BlogEntry = () => {
  const [editor, setEditor] = useState();
  const blogCreateMutation = useCreateBlogMutation();
  const blogUpdateMutation = useUpdateBlogMutation();
  const attachementMutation = useCreateAttachmentMutation();

  const [form] = Form.useForm();
  const { blogId } = useParams();
  const { data, error, isLoading } = useBlogQuery(blogId, { enabled: Boolean(blogId) });
  const [initialData, setInitialData] = useState("Enter blog content here");

  const clickHandler = async () => {
    try {
      form.validateFields();
      if (editor.getData().length < 100) {
        message.error("Not enough content");
        return;
      }
      const formData = form.getFieldsValue();
      let attachmentId;
      if (blogId) {
        if (formData.image) {
          attachmentId = await uploadImage(attachementMutation)(formData.image.file);
        }
        await blogUpdateMutation.mutateAsync({
          blogId,
          blogData: {
            content: editor.getData(),
            title: formData.title,
            desc: formData.description,
            author: formData.author,
            ...(attachmentId ? { attachmentId } : {}),
          },
        });
        message.success("Blog Changes Saved");
      } else {
        if (!formData.image) {
          throw new Error("Image not added");
        }
        attachmentId = await uploadImage(attachementMutation)(formData.image.file);
        await blogCreateMutation.mutateAsync({
          content: editor.getData(),
          title: formData.title,
          desc: formData.description,
          author: formData.author,
          attachmentId,
        });
        message.success("Blog Saved");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (error) {
      throw new Error(error.message);
    }
    if (data && !isLoading) {
      setInitialData(data.content);
      form.setFields([
        { name: ["title"], value: data.title },
        { name: ["description"], value: data.desc },
        { name: ["author"], value: data.author },
      ]);
    }
  }, [data, error, isLoading]);

  return (
    <>
      <FormContainer>
        <Form name="basic" layout="vertical" labelCol={{ span: 4 }} form={form}>
          <Form.Item
            label={"Title"}
            name={"title"}
            rules={[{ required: true, message: "Please enter the title" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Description"}
            name={"description"}
            rules={[{ required: true, message: "Please enter the description" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label={"Author"}
            name={"author"}
            rules={[{ required: true, message: "Please enter the author" }]}
          >
            <Input />
          </Form.Item>
          <UploadAsterix />
          <Form.Item
            label={"Image"}
            name={"image"}
            listType="text"
            // rules={[{ required: true, message: "Please add the image" }]} custom validation needed if image exists
          >
            <Upload
              beforeUpload={(file) => {
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
        </Form>
      </FormContainer>
      <div style={{ minHeight: "60vh" }}>
        <CKEditor
          editor={ClassicEditor}
          data={initialData}
          onReady={(editor) => {
            setEditor(editor);
            editor.ui.view.editable.element.style.height = "50vh";
          }}
        />
      </div>
      <ButtonGroup>
        <Button
          type="primary"
          size="large"
          onClick={clickHandler}
          loading={blogCreateMutation.isLoading || blogUpdateMutation.isLoading}
          disabled={blogCreateMutation.isLoading || blogUpdateMutation.isLoading}
        >
          Save
        </Button>
      </ButtonGroup>
    </>
  );
};

export default BlogEntry;
