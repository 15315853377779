import React, { useEffect } from "react";
import ImageContent from "Components/AboutUsServices/ImageContent";
import { Head, List } from "Components";
// import PageBanner from "../../Components/Gobal/PageBanner/PageBanner";
import { Container } from "./styles";

const ServicesOld = () => {
  return (
    <>
      <Head>
        <meta title="Services • Eazytaxes" />
      </Head>
      <Container>
        {/* <PageBanner
              bg={`${process.env.PUBLIC_URL}/assets/about/banner.jpg`}
              navigation={{
                  previousItems: [
                      {
                          text: "home",
                          to: "/",
                      },
                  ],
                  currentItem: "about us",
              }}
          /> */}
        <ImageContent
          img="https://assets10.lottiefiles.com/private_files/lf30_0jnbucxz.json"
          heading="Easier Finance & Federal Tax Management For US Taxpayers!"
          desc={[
            "Looking for help with US tax returns? eazytaxes.com is the right place, and if you have all the documents & information you are going to get your federal tax returns filed within the next few minutes.",
          ]}
        />
        <ImageContent
          imgAtRight
          img="https://assets8.lottiefiles.com/packages/lf20_pivt1poq.json"
          heading="Get Rid Of The Confusions In Federal Tax Filing"
          desc={[
            "We'll help you navigate the often confusing world of tax law, and make sure you're getting all the deductions and credits you're entitled to. We know that dealing with taxes can be stressful, so we've designed our website to be as user-friendly and informative as possible.",
            <List
              items={[
                "Fill your federal tax returns",
                "Get custom support for tax filing",
                "Take advantage of bookkeeping services",
                "Available for salaried and business owners",
              ]}
            />,
          ]}
        />
        <ImageContent
          img="https://assets5.lottiefiles.com/packages/lf20_j0lfvkz7.json"
          heading="Being A Reliable Partner"
          desc={[
            "With years of experience with US tax filing, and an expert team eazytaxes.com is your reliable partner to help you out with US tax filing and other linked services. Want to start filing your federal tax with us? Make sure to create a profile on our platform.",
          ]}
        />
      </Container>
    </>
  );
};

export default ServicesOld;
