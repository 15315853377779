import {
  useUsersQuery,
  useCreateCpaMutation,
  useDeleteCpaMutation,
} from "Hooks/apiHooks";
import { Heading } from "./styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Table, Button, Select, Spin, message } from "antd";
import Loader from "Components/uiComponents/LoadingSpinner";
import styled from "styled-components";

const CreateCPAContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
`;

const ButtonGroup = styled.div`
  margin-left: 10px;
`;

const TableContainer = styled.div`
  width: 60%;
`;

const CreateCPA = () => {
  const { data, error, isLoading } = useUsersQuery("User");
  const {
    data: dataCPA,
    error: errorCPA,
    isLoading: isLoadingCPA,
  } = useUsersQuery("CPA");

  const createCPA = useCreateCpaMutation();
  const deleteCPA = useDeleteCpaMutation();

  const [isAdd, setIsAdd] = useState(false);
  const fetchUserList = (email) => {
    return data.filter((user) => user.email.includes(email));
  };
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    if (error) {
      throw error;
    }
    if (errorCPA) {
      throw errorCPA;
    }
  });

  return (
    <div>
      <Heading>Create CPA</Heading>
      {isLoading || isLoadingCPA ? (
        <Loader message={"Loading CPAs"}></Loader>
      ) : (
        <>
          <TableContainer>
            <Table dataSource={dataCPA} pagination={false}>
              <Table.Column title={"CPA Email"} dataIndex="email" />
              <Table.Column
                render={(record) => {
                  return (
                    <Button
                      onClick={async () => {
                        await deleteCPA.mutateAsync(record.user_id);
                        message.success("CPA removed!");
                      }}
                    >
                      Remove
                    </Button>
                  );
                }}
              />
            </Table>
          </TableContainer>
          <CreateCPAContainer>
            {!isAdd ? (
              <Button onClick={() => setIsAdd(true)}>Add CPA</Button>
            ) : (
              <>
                <Select
                  style={{ width: "200px" }}
                  filterOption={false}
                  showSearch
                  onSearch={fetchUserList}
                  notFoundContent={null}
                  options={data.map((user) => ({
                    value: user.user_id,
                    label: user.email,
                  }))}
                  onChange={(value) => {
                    setSelectedUser(value);
                  }}
                />
                <ButtonGroup>
                  <Button onClick={() => setIsAdd(false)}>Cancel</Button>
                  <Button
                    onClick={async () => {
                      if (selectedUser !== "") {
                        await createCPA.mutateAsync(selectedUser);
                        message.success("CPA Added");
                      } else {
                        message.info("Please select a User");
                      }
                    }}
                  >
                    Submit
                  </Button>
                </ButtonGroup>
              </>
            )}
          </CreateCPAContainer>
        </>
      )}
    </div>
  );
};

export default CreateCPA;
