import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "../Button/Button";
import {
  Container,
  Content,
  HamMenuButton,
  HeaderLink,
  HeaderLinks,
  LoginSignup,
  LoginSignupHamMenu,
  Logo,
  DropDownMenu,
} from "./styles";
import { CgMenu } from "react-icons/cg";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ROUTES from "constants/routes";
import HamMenu from "../HamMenu/HamMenu";
import { HeaderContent } from "./styles";
import StagingBanner from "../StagingBanner/StagingBanner";

const Header = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [isHamMenuVisible, setIsHamMenuVisible] = useState(false);
  const { logout } = useAuth0();
  const headerLinks = [
    {
      to: "/",
      text: "Home",
    },
    {
      to: "/about-us",
      text: "About Us",
    },
    {
      text: "Blogs",
      to: ROUTES.BLOGS.MAIN,
    },
    {
      to: "/services",
      text: "Services",
    },
    {
      to: "/contact-us",
      text: "Contact Us",
    },
    {
      to: "/pricing",
      text: "Pricing",
    },
  ];

  const items = [
    {
      label: "Salaried Professional",
      key: "1",
      link: "/services/salaried-professional",
    },
    {
      label: "Ebay, Shopify, Amazon Sellers",
      key: "2",
      link: "/services/ebay-shopify-amazon-sellers",
    },
    {
      label: "Small Business Owners",
      key: "3",
      link: "/services/small-business-owners",
    },
    {
      label: "Students",
      key: "4",
      link: "/services/students",
    },
    {
      label: "Template",
      key: "5",
      link: "/services/template",
    },
  ];
  const onClick = ({ key }) => {
    const obj = items.find((item) => item.key === key);
    navigate(obj.link);
  };
  return (
    <>
      {/* <HeaderContent>
        <span>We are open and accepting returns! </span> &nbsp; Get a head start and
        &nbsp;
        {
          <NavLink to={"/get-my-taxes-filed"}>
            <u>file early</u>
          </NavLink>
        }
        &nbsp;&nbsp;
      </HeaderContent> */}
      <StagingBanner />
      <Container>
        <Content>
          <NavLink to="/">
            <Logo alt="eazytaxes.com" src={`${process.env.PUBLIC_URL}/assets/logo.svg`} />
          </NavLink>
          <HeaderLinks>
            {headerLinks.map(({ to, text }, index) => {
              if (text === "Services") {
                return (
                  <Dropdown
                    menu={{
                      rootClassName: DropDownMenu,
                      items,
                      onClick,
                    }}
                    key={index}
                  >
                    <HeaderLink>{text}</HeaderLink>
                  </Dropdown>
                );
              } else {
                return (
                  <NavLink key={index} to={to}>
                    <HeaderLink>{text}</HeaderLink>
                  </NavLink>
                );
              }
            })}
          </HeaderLinks>
          <LoginSignupHamMenu>
            <LoginSignup>
              {isAuthenticated ? (
                <>
                  <Button
                    to={ROUTES.DASHBOARD.HOME}
                    hoverBg="#f5f5f5"
                    hoverColor="#0b2a41"
                  >
                    Go To Dashboard
                  </Button>
                  <Button
                    onClick={() =>
                      logout({ logoutParams: { returnTo: window.location.origin } })
                    }
                    hoverBg="#f5f5f5"
                    hoverColor="#0b2a41"
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => loginWithRedirect()}
                    hoverBg="#f5f5f5"
                    hoverColor="#0b2a41"
                  >
                    Login / Signup
                  </Button>
                </>
              )}
            </LoginSignup>
            <HamMenuButton
              onClick={() => {
                setIsHamMenuVisible(true);
              }}
            >
              <CgMenu />
            </HamMenuButton>
            <HamMenu
              isHamMenuVisible={isHamMenuVisible}
              closeHamMenu={() => setIsHamMenuVisible(false)}
            />
          </LoginSignupHamMenu>
        </Content>
      </Container>
    </>
  );
};

export default Header;
