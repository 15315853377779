const A1 = () => {
  return (
    <>
      <div style={{ fontSize: "2rem" }}>What are the advantages of filing jointly?</div>
      <div style={{ marginTop: "1rem" }}>
        There are many advantages to filing a joint tax return with your spouse. Joint
        filers receive one of the largest standard deductions each year, allowing them to
        deduct a significant amount of income when calculating taxable income.
      </div>
      <div style={{ marginTop: "1rem" }}>
        Couples who file together can usually qualify for multiple tax credits, such as
        the:
      </div>
      <div style={{ marginTop: "1rem" }}>
        <ul>
          <li>Earned Income Tax Credit</li>
          <li>American Opportunity and Lifetime Learning Education Tax Credits</li>
          <li>Exclusion or credit for adoption expenses</li>
          <li>Child and Dependent Care Credit</li>
          <li>
            Joint filers mostly receive higher income thresholds for certain taxes and
            deductions—this means they can often earn a larger amount of income and still
            potentially qualify for certain tax breaks.
          </li>
        </ul>
      </div>
      <div style={{ fontSize: "2rem" }}>
        What are the potential cons of filing your tax returns separately?
      </div>
      <div style={{ marginTop: "1rem" }}>
        On the other hand, couples who file separately typically receive fewer tax
        benefits. Separate tax returns may result in more tax.
      </div>
      <div style={{ marginTop: "1rem" }}>
        <ul>
          <li>
            In 2022, Married Filing Separately taxpayers only receive a Standard Deduction
            of $12,950 compared to the $25,900 offered to those who filed jointly.
          </li>
          <li>
            If you file a separate return from your spouse, you are often automatically
            disqualified from several of the tax deductions and credits mentioned earlier.
          </li>
          <li>
            Additionally, separate filers are usually limited to a smaller IRA
            contribution deduction.
          </li>
          <li>They also can't take the deduction for student loan interest.</li>
          <li>
            The capital loss deduction limit is $1,500 each when filing separately,
            instead of $3,000 on a joint return.
          </li>
        </ul>
      </div>
      <div
        style={{
          borderTop: "0.1rem solid rgba(0, 0, 0, 0.15)",
          borderBottom: "0.1rem solid rgba(0, 0, 0, 0.15)",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <span
          style={{
            fontWeight: "800",
          }}
        >
          EazyTax Tip:
        </span>{" "}
        The best way to find out if you should file jointly or separately with your spouse
        is to prepare the tax return both ways. Double-check your calculations and then
        look at the net refund or balance due from each method.
      </div>
    </>
  );
};

export default A1;
