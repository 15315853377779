import styled from "styled-components";

export const Container = styled.div`
  background: #f5f5f5;
  border-radius: 1.6rem;
  display: flex;
  padding: 2.4rem;
  width: 50rem;
  gap: 2rem;
  @media screen and (max-width: 700px) {
    width: 40rem;
    flex-direction: column;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    width: 30rem;
    flex-direction: column;
    text-align: center;
  }
`;

// export const UserPicName = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 3rem;
//     padding: 3rem 4rem 1.8rem;
// `;

export const UserPic = styled.div`
  width: 9rem;
  height: 9rem;
  background: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  @media screen and (max-width: 700px) {
    margin: 0 auto;
  }
`;

// export const Icon = styled.div`
//     width: 4.5rem;
//     height: 4.5rem;
//     border-radius: 50%;
//     background: #55bb53;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 2.5rem;
//     position: absolute;
//     top: -1.5rem;
//     right: -1.5rem;
// `;

export const Content = styled.div`
  /* color: #c5d1da; */
  color: #000;
  font-size: 1.7rem;
  line-height: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ReviewContent = styled.div`
  flex-grow: 1;
`;

export const UserName = styled.div`
  /* font-size: 2.2rem; */
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  /* color: #fff; */
  color: #000;
`;
