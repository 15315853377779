import React, { useState } from "react";
import { Container, Desc, Heading, HeadingDesc, Icon } from "./styles";

const ContactDetail = ({ icon, headingLink, heading, desc }) => {
  const [containerHovered, setContainerHovered] = useState(false);
  return (
    <Container
      onMouseOver={() => setContainerHovered(true)}
      onMouseOut={() => setContainerHovered(false)}
    >
      <Icon containerHovered={containerHovered}>{icon}</Icon>
      <HeadingDesc>
        <a href={headingLink}>
          <Heading>{heading}</Heading>
        </a>
        <Desc>{desc}</Desc>
      </HeadingDesc>
    </Container>
  );
};

export default ContactDetail;
