import styled from "styled-components";

export const Container = styled.div`
  padding: 0 2.5rem;
`;

export const Content = styled.div`
  max-width: 120rem;
  margin: auto;
  padding: 6rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 12%);
  @media screen and (max-width: 600px) {
    padding: 4rem;
  }
  @media screen and (max-width: 400px) {
    padding: 3rem;
  }
`;

export const Heading = styled.div`
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #1b1d21;
  @media screen and (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

export const Form = styled.div`
  margin: 2rem 0;
  @media screen and (max-width: 600px) {
    margin: 3rem 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  & > div {
    flex: 1;
  }
  @media screen and (max-width: 780px) {
    flex-direction: column;
  }
`;
