import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const TopRow = styled.div`
  margin: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
`;

export const TaxReturnContent = styled.div``;

export const Content = styled.div`
  text-align: center;
  max-width: 45rem;
  margin: auto;
`;
