import Loader from "Components/uiComponents/LoadingSpinner";
import { questions as formQuestions } from "../../../constants/oldForms";
import {
  useAttachmentsByIdQuery,
  useFormByTaxReturnQuery,
  useTaxReturnQuery,
} from "Hooks/apiHooks";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { formatStatus, formatType } from "helpers";
import { Table } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const TaxFormCard = styled.div`
  background-color: white;
  box-shadow: 15px 15px 38px 0px rgb(0 0 0 / 10%);
  width: fit-content;
  border-radius: 10px;
  padding: 2rem;
`;

const Header = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 2rem;
`;

const TaxReturnView = () => {
  const { taxReturnId } = useParams();

  const {
    data: taxReturnData,
    error: taxReturnError,
    isLoading: isTaxReturnLoading,
  } = useTaxReturnQuery(taxReturnId);

  const {
    data: taxFormData,
    error: formError,
    isLoading: isFormLoading,
  } = useFormByTaxReturnQuery(taxReturnId);

  const attachementQuestions = useMemo(() => {
    if (taxReturnData) {
      return formQuestions[taxReturnData.type].form.filter(
        (question) => question.type === "attachment" || question.condType === "attachment"
      );
    }
  }, [taxReturnData]);

  const tableQuestions = useMemo(() => {
    if (taxReturnData) {
      return formQuestions[taxReturnData.type].form.filter(
        (question) => question.type === "tableInput" || question.condType === "tableInput"
      );
    }
  }, [taxReturnData]);

  const formData = useMemo(() => {
    if (taxFormData) {
      return JSON.parse(taxFormData[0].data);
    }
  }, [taxFormData]);

  const attachementIds = useMemo(() => {
    return formData && attachementQuestions
      ? Object.entries(formData)
          .filter(([name, _]) =>
            Boolean(attachementQuestions.find((question) => question.name === name))
          )
          .map(([_, id]) => id)
      : [];
  }, [formData, attachementQuestions]);

  const { data, isLoading, error } = useAttachmentsByIdQuery(
    attachementIds,
    Boolean(attachementIds.length)
  );

  useEffect(() => {
    if (error) {
      throw error;
    }
    if (formError) {
      throw formError;
    }
    if (taxReturnError) {
      throw taxReturnError;
    }
  }, [error, formError, taxReturnError]);

  const renderRow = (keyFn) => (record, _, index) => {
    if (record.type === "attachment" || record.condType === "attachment") {
      const url = data?.find(
        (attachement) => attachement.attachment_id === formData[keyFn(record, index)]
      )?.s3_file;
      return url ? (
        <a href={url} target="_blank" style={{ fontWeight: 700 }}>
          Preview/Download File
        </a>
      ) : (
        "No File Uploaded"
      );
    }
    if (
      ["radio", "select"].includes(record.type) ||
      ["radio", "select"].includes(record.condType)
    ) {
      return (
        record.options.find((option) => option.value === formData[keyFn(record, index)])
          ?.label ?? "Not Entered"
      );
    }
    if (record.type === "check" || record.condType === "check") {
      return formData[keyFn(record, index)] ? (
        <CheckCircleOutlined />
      ) : (
        <CloseCircleOutlined />
      );
    }
    return formData[keyFn(record, index)] ?? "Not Entered";
  };

  return (
    <>
      {isLoading || isFormLoading || isTaxReturnLoading ? (
        <Loader message={"Loading Tax Return and Attachments"} />
      ) : (
        <div>
          <Header>
            {formatType(taxReturnData.type)}
            {" - "}
            {taxReturnData.year}
            {" - "}
            {formatStatus(taxReturnData.status)}
          </Header>
          <Header>
            {" For "}
            <span style={{ fontWeight: 700 }}>{taxReturnData.user_email}</span>
          </Header>
          <TaxFormCard>
            <Table
              dataSource={formQuestions[taxReturnData.type].form.filter(
                (question) => question.type !== "tableInput"
              )}
            >
              <Table.Column
                title={"Question"}
                render={(record) => {
                  return record.label;
                }}
              />
              <Table.Column
                title={"Answer"}
                render={renderRow((record) => record.name)}
              />
            </Table>
            {tableQuestions.map((question) => {
              return (
                <>
                  {question.label}
                  <Table dataSource={question.rows}>
                    <Table.Column
                      title={"Question"}
                      render={(record) => {
                        return record.label;
                      }}
                    />
                    {question.columns.map((column) => {
                      return (
                        <Table.Column
                          title={column.title}
                          render={renderRow(
                            (record) => `${question.name}-${column.name}-${record.name}`
                          )}
                        />
                      );
                    })}
                    {question?.conditionalColumns?.map((column) => {
                      return (
                        <Table.Column
                          title={column.title}
                          render={renderRow(
                            (record) => `${question.name}-${column.name}-${record.name}`
                          )}
                        />
                      );
                    }) ?? []}
                    {question?.multiplierColumns?.map((column) => {
                      if (formData[column.dependency]) {
                        return Array(formData[column.dependency])
                          .fill(undefined)
                          .map((_, outerIndex) => (
                            <Table.Column
                              title={`${column.title} ${outerIndex + 1}`}
                              render={renderRow(
                                (record, index) =>
                                  `${question.name}-${column.name}-${outerIndex + 1}-${
                                    record.name
                                  }`
                              )}
                            />
                          ));
                      } else {
                        return <></>;
                      }
                    }) ?? []}
                  </Table>
                </>
              );
            })}
          </TaxFormCard>
        </div>
      )}
    </>
  );
};

export default TaxReturnView;
