import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatStatus, formatType } from "helpers";
const Container = styled.div`
  box-shadow: 8px 8px 30px 0 rgb(0 0 0 / 10%);
  border-radius: 0.5rem;
  width: 80rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 40px;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Meta = styled.div`
  margin-top: 10px;
  font-weight: 600;
`;

const TaxReturnCard = ({ id, year, status, type }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <div style={{ marginRight: "20px" }}>
        <Title>{year}</Title>
        <Meta>
          <span style={{ fontSize: "20px" }}>Status</span> - {formatStatus(status)}
        </Meta>
        <Meta>{formatType(type)}</Meta>
      </div>
      <div>
        <div
          onClick={() => {
            navigate(`/dashboard/tax-return/${id}`);
          }}
        >
          <AiFillEdit />
        </div>
      </div>
    </Container>
  );
};

export default TaxReturnCard;
